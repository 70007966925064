<template>
  <div class="container pt-5 mb-5">
    <loading-dialog :show="isLoading" />

    <div class="py-5 text-center hide-print">
      <template v-if="!isLoading">
        <h2>{{ selectedVessel.name }} : {{ engine !== null ? engine.engine_label : '' }}</h2>
        <router-link
          v-if="engine !== null"
          :to="'/vessel-engines/details/' + selectedVessel.id + '/' + engine.id"
          class="text-decoration-none float-left"
        >
          <i class="fa fa-arrow-left mr-2" />Back to Engine Details
        </router-link>
      </template>
    </div>
    
    <div class="border p-3">
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Vessel Name</strong>
            <div class="text-muted">
              {{ selectedVessel.name }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Vessel Type</strong>
            <div class="text-muted">
              {{ selectedVessel.vessel_type }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Country</strong>
            <div class="text-muted">
              {{ selectedVessel.country }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Load Capacity</strong>
            <div class="text-muted">
              {{ selectedVessel.load_capacity }} MT
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="border p-3 mt-3" v-if="engine !== null">
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Engine Label</strong>
            <div class="text-muted">
              {{ engine.engine_label }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Manufacturer</strong>
            <div class="text-muted">
              {{ engine.manufacturer }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Model Number</strong>
            <div class="text-muted">
              {{ engine.model_number }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Engine Year</strong>
            <div class="text-muted">
              {{ engine.engine_year }}
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="mt-3">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-6">
          <h3>Report Data</h3>
        </div>
        <div class="col-sm-12 col-md-6 text-right hide-print">
          <button class="btn btn-primary" @click="print()"><i class="fa fa-print mr-2"></i> Print Report</button>
          <button class="btn btn-primary" @click="showGraphModal = true">View Graph</button>
        </div>
      </div>
      
      <div v-if="!loadingReport">
        <div style="display:flex">
          <div style="padding-right:0px;margin-top:0px;display:flex">
            <div class="report_data_content header-collumn">
              <template v-for="(data, index) in form">
                <div :key="index" :style="'min-height:' + 60 * data.inputs.length+'px;line-height:' + 42 * data.inputs.length + 'px'">
                  {{ data.category }}
                </div>
              </template>
            </div>
            <div class="report_data_content header-collumn">
              <template v-for="(data, index) in form">
                <template v-for="(input, childIndex) in data.inputs" >
                  <div :key="index+'_'+childIndex">
                    {{ input.label }}
                  </div>
                </template>
              </template>
            </div>
          </div>
          <div class="report_scrollable">
            <template v-if="!loadingReport">
              <div :style="'width:auto;display:flex'">
                <div class="report_data_content data-collumn" v-for="(data, index) in reports" :key="index">
                  <div :class="getStatusColor(data.report_data.report_status)" v-html="renderStatus(data.report_data.report_status)"></div>
                  <div>{{ data.report_data.sample_id }}</div>
                  <div>{{ data.report_data.tested_lubricant }}</div>
                  <div>{{ ddmmyyyyStripe(data.report_data.sampled) }}</div>
                  <div>{{ ddmmyyyyStripe(data.report_data.reported) }}</div>
                  <div>{{ data.report_data.equipment_age }}</div>
                  <div>{{ data.report_data.equipment_uom }}</div>
                  <div>{{ data.report_data.cr }}</div>
                  <div>{{ data.report_data.pb }}</div>
                  <div>{{ data.report_data.fe }}</div>
                  <div>{{ data.report_data.cu }}</div>
                  <div>{{ data.report_data.sn }}</div>
                  <div>{{ data.report_data.mo }}</div>
                  <div>{{ data.report_data.ni }}</div>
                  <div>{{ data.report_data.al }}</div>
                  <div>{{ data.report_data.k }}</div>
                  <div>{{ data.report_data.na }}</div>
                  <div>{{ data.report_data.si }}</div>
                  <div>{{ data.report_data.b }}</div>
                  <div>{{ data.report_data.ca }}</div>
                  <div>{{ data.report_data.p }}</div>
                  <div>{{ data.report_data.zn }}</div>
                  <div>{{ data.report_data.mg }}</div>
                  <div>{{ data.report_data.pq_index }}</div>
                  <div>{{ data.report_data.st }}</div>
                  <div>{{ data.report_data.oxi }}</div>
                  <div>{{ data.report_data.nit }}</div>
                  <div>{{ data.report_data.sul }}</div>
                  <div>{{ data.report_data.fuel_content }}</div>
                  <div>{{ data.report_data.v100 }}</div>
                  <div>{{ data.report_data.tbn }}</div>
                  <div>{{ data.report_data.water }}</div>
                  <div class="button-comments">
                    <b-button size="sm" v-if="typeof data.report_data.comments !== 'undefined' && data.report_data.comments !== null && data.report_data.comments !== ''" @click="viewComment(data.report_data.comments)">
                      Comments
                    </b-button>   
                  </div>
                  <div>
                    <a class="pdf_btn" v-if="typeof data.report_data.pdf_document !== 'undefined' && data.report_data.pdf_document !== null && data.report_data.pdf_document !== ''" :href="data.report_data.pdf_document" target="_blank">
                      <i class="fa fa-file-alt"></i>
                    </a>   
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center md:w-1/3">
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <!-- loading spinner -->
         <b-spinner
          type="grow"
          variant="primary"
          class="mb-3"
        />
      </div>

      <b-modal
        v-if="engine !== null"
        v-model="showGraphModal"
        hide-footer
        scrollable
        size="xl"
        :title="'Graph of ' + engine.engine_label+ ' Health Report'"
      >
        <div v-if="!loadingReport">
          <!-- graph -->
          <div class="row mb-4">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
              <div class="text-center">Wear</div>
              <LineChartGenerator
                :chart-data="wearChartData"
                chart-id="wearChartData"
                dataset-id-key="wear"
                :height="300"
                :chart-options="plugins"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
              <div class="text-center">Contamination</div>
              <LineChartGenerator
                :chart-options="plugins"
                :chart-data="contaminationChartData"
                chart-id="contaminationChartData"
                dataset-id-key="contamination"
                :height="300"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
              <div class="text-center">Oil Condition</div>
              <LineChartGenerator
                :chart-options="plugins"
                :chart-data="oilConditionChartData"
                chart-id="oilConditionChartData"
                dataset-id-key="oil"
                :height="300"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
              <div class="text-center">Viscosity at 100&deg;C</div>
              <LineChartGenerator
                :chart-options="plugins"
                :chart-data="viscosityChartData"
                chart-id="viscosityChartData"
                dataset-id-key="viscosity"
                :height="300"
              />
            </div>
          </div>
        
          <!-- graph -->
        </div>
        <div v-else class="text-center">
          <!-- loading spinner -->
          <b-spinner
            type="grow"
            variant="primary"
            class="mb-3"
          />
        </div>
      </b-modal>


      <b-modal
      v-model="showCommentModal"
      hide-footer
      scrollable
      size="md"
      title="Comment"
    >
      <pre class="comment-text">
{{ selectedComment }}
      </pre>
    </b-modal>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex'
import LoadingDialog from "@/components/dialogs/LoadingDialog"
import { SwalSuccess, SwalWarning, Swal, SwalConfirm } from "../../../plugins/sweetalert2";

import formatDate from '../../../mixins/formatDate'
import formatValidation from '../../../mixins/formatValidation'
import API from "../../../plugins/axios";
import formatChar from "@/mixins/formatChar";

import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  components: {
    'loading-dialog': LoadingDialog,
    LineChartGenerator
  },
  props: {
    data: {
      type: String,
      default: String
    },
    plugins : {
      type: Object,
      default: () => {
        return {
          plugins : {
            legend: {
              position:'bottom',
              labels: {
                boxWidth: 10,
                boxHeight: 10
              }
            }
          }
        }
      }
    }
  },
  mixins: [formatDate, formatValidation, formatChar],
  data () {
    return {
      engine : null,
      showCommentModal : false,
      showGraphModal : false,
      selectedComment : '',
      reports : [],
      loadingReport: false,

      form : [
        {
          category : "Sample Info",
          inputs : [
            {
              label : "Report Status",
            },
            {
              label : "Sample ID",
            },
            {
              label : "Tested Lubricant",
            },
            {
              label : "Sampled",
            },
            {
              label : "Reported",
            },
            {
              label : "Equipment Age",
            },
            {
              label : "Equipment UOM",
            },
          ]
        },
        {
          category : "Wear (ppm)",
          inputs : [
            {
              label : "Cr (Chromium)",
            },
            {
              label : "Pb (Lead)",
            },
            {
              label : "Fe (Iron)",
            },
            {
              label : "Cu (Copper)",
            },
            {
              label : "Sn (Tin)",
            },
            {
              label : "Mo (Molybdenum)",
            },
            {
              label : "Ni (Nickel)",
            },
            {
              label : "Al (Aluminum)",
            }
          ]
        },
        {
          category : "Contamination (ppm)",
          inputs : [
            {
              label : "K (Potassium)",
            },
            {
              label : "Na (Sodium)",
            },
            {
              label : "Si (Silicon)",
            }
          ]
        },
        {
          category : "Additive (ppm)",
          inputs : [
            {
              label : "B (Boron)",
            },
            {
              label : "Ca (Calcium)",
            },
            {
              label : "P (Phosphorus)",
            },
            {
              label : "Zn (Zinc)",
            },
            {
              label : "Mg (Magnesium)",
            }
          ]
        },
        {
          category : "Oil Condition",
          inputs : [
            {
              label : "PQ Index",
            },
            {
              label : "ST (Soot)",
            },
            {
              label : "OXI (Oxidation)",
            },
            {
              label : "NIT (Nitration)",
            },
            {
              label : "SUL (Sulfur Products)",
            },
            {
              label : "Fuel Content (Vol.%)",
            },
            {
              label : "V100 (Viscosity at 100 C)",
            },
            {
              label : "TBN (Total Base Number)",
            },
            {
              label : "Water (Vol.%)",
            }
          ]
        },
        {
          category : "",
          inputs : [
            {
              label : "Comments",
            },
            {
              label : "Lab Report",
            },
          ]
        }
      ],

      chartOptions : {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          position: 'bottom'
        },
      },

      wearChartData: {
        labels : [],
        datasets : [],
      },

      contaminationChartData: {
        labels : [],
        datasets : [],
        chartOptions : {
          responsive: true,
          maintainAspectRatio: true
        }
      },

      oilConditionChartData: {
        labels : [],
        datasets : [],
        chartOptions : {
          responsive: true,
          maintainAspectRatio: true
        }
      },

      viscosityChartData: {
        labels : [],
        datasets : [],
        chartOptions : {
          responsive: true,
          maintainAspectRatio: true
        }
      }
    }
  },
  computed: {
    ...mapState('moduleVessel', [
      'selectedVessel',
      'selectedVesselType',
      'userCreate',
      'license',
      'buildYear',
      'isLoading',
    ]),
  },
  created () {
    this.loadSelectedVessel()
  },
  watch: {
    selectedVessel () {
      if(this.selectedVessel.id !== "" && this.selectedVessel.id !== null) {
        this.getEngine()
        this.loadData()
      }
    },
  },
  methods: {
    print(){
      window.print()
    },
    getEngine(){
      const vm = this

      const params = {
        'start': 0,
        'length': 999,

        "columns[0][data]": "0",
        "columns[0][name]": "id",
        "columns[0][searchable]": "true",
        "columns[0][orderable]": "true",
        "columns[0][search][value]": this.$route.params.vesselEngineId,
        "columns[0][search][regex]": "false",
      }

      API.get("/master/vessel/vessel-engine/read", {
        params: params
      })
      .then((res) => {
        const body = res.data;
        vm.listLoading = false

        if(res.status === 200){
          vm.engine = body.data[0]
        } else {
          SwalWarning(body.message)
        }
      })
      .catch((error) => {
        vm.listLoading = false

        const data = error.response
        SwalWarning (data.data.message)
      });
    },
    loadSelectedVessel () {
      const payload = this.$route.params.vesselId
      this.$store.dispatch('moduleVessel/LOAD_SELECTED', payload)
    },
    viewEngineReport(){
      this.$store.commit("SET_SHOW_ENGINE_HEALTH_REPORT", {
        vesselEngineId : this.$route.params.vesselEngineId,
        openDate : new Date().getTime(),
      });
    },
    renderDate(date){
      return moment(date).format('DD-MMM-YYYY')
    },
    viewComment(comment){
      this.selectedComment = comment
      this.showCommentModal = true
    },
    getStatusColor(status){
      if(status === 'Caution'){
        return 'bg-warning'
      } else if(status === 'Normal'){
        return ''
      } else if(status === 'Alert'){
        return 'bg-danger'
      }
    },
    renderStatus(status){
      return status
    },

    loadData () {
      this.loadingReport = true

      const params = {
        'start': 0,
        'length': 999,
        
        'columns[0][data]': '0',
        'columns[0][name]': 'engine.id',
        'columns[0][searchable]': 'true',
        'columns[0][orderable]': 'true',
        'columns[0][search][value]': this.$route.params.vesselEngineId,
        'columns[0][search][regex]': 'false',
      }
      API.get('master/vessel/vessel-engine-checkup-report/read', { params })
        .then(res => {
          const data = res.data.data
          this.reports = data

          this.populateChart(data)

          this.loadingReport = false
        })
        .catch(err => {
          console.log(err)

          this.loadingReport = false
        })
      .then(()=> {
        this.loadingReport = false
      })
    },

    normalizeReportData(input){
      //check if input is integer
      if(!isNaN(input)){
        return parseInt(input)
      }else{
        return 0
      }
    },

    populateChart(data){
      // wear
      let crArr = []
      let pbArr = []
      let feArr = []
      let cuArr = []
      let snArr = []
      let moArr = []
      let niArr = []

      //contamination
      let kArr = []
      let naArr = []
      let siArr = []

      //oil condition
      let oxiArr = []
      let sulArr = []
      let nitArr = []
      let tbnArr = []

      //viscosity
      let v100Arr = []

      let wearChartDataValue = []
      let contaminationChartDataValue = []
      let oilConditionChartDataValue = []
      let viscosityChartDataValue = []
      let chartLabels = []

      if(data !== null && data.length > 0){
        data.reverse().map(perData => {
          chartLabels.push(this.ddmmyyyyStripe(perData.checked_date))

          crArr.push(this.normalizeReportData(perData.report_data.cr))
          pbArr.push(this.normalizeReportData(perData.report_data.pb))
          feArr.push(this.normalizeReportData(perData.report_data.fe))
          cuArr.push(this.normalizeReportData(perData.report_data.cu))
          snArr.push(this.normalizeReportData(perData.report_data.sn))
          moArr.push(this.normalizeReportData(perData.report_data.mo))
          niArr.push(this.normalizeReportData(perData.report_data.ni))

          kArr.push(this.normalizeReportData(perData.report_data.k))
          naArr.push(this.normalizeReportData(perData.report_data.na))
          siArr.push(this.normalizeReportData(perData.report_data.si))

          oxiArr.push(this.normalizeReportData(perData.report_data.oxi))
          sulArr.push(this.normalizeReportData(perData.report_data.sul))
          nitArr.push(this.normalizeReportData(perData.report_data.nit))
          tbnArr.push(this.normalizeReportData(perData.report_data.tbn))

          v100Arr.push(this.normalizeReportData(perData.report_data.v100))
        })
      }

      wearChartDataValue = [
        {
            label : 'Cr (Chromium)',
            backgroundColor: '#4472c4',
            borderColor: '#4472c4',
            data: crArr
        },
        {
            label : 'Pb (Lead)',
            backgroundColor: '#ed7d31',
            borderColor: '#ed7d31',
            data: pbArr
        },
        {
            label : 'Fe (Iron)',
            backgroundColor: '#a5a5a5',
            borderColor: '#a5a5a5',
            data: feArr
        },
        {
            label : 'Cu (Copper)',
            backgroundColor: '#ffc000',
            borderColor: '#ffc000',
            data: cuArr
        },
        {
            label : 'Sn (Tin)',
            backgroundColor: '#5b9bd5',
            borderColor: '#5b9bd5',
            data: snArr
        },
        {
            label : 'Mo (Molybdenum)',
            backgroundColor: '#70ad47',
            borderColor: '#70ad47',
            data: moArr
        },
        {
            label : 'Ni (Nickel)',
            backgroundColor: '#264478',
            borderColor: '#264478',
            data: niArr
        }
      ]
      
      contaminationChartDataValue = [
        {
          label : 'K (Potassium)',
          backgroundColor: '#4472c4',
          borderColor: '#4472c4',
          data: kArr
        },
        {
          label : 'Na (Sodium)',
          backgroundColor: '#ed7d31',
          borderColor: '#ed7d31',
          data: naArr
        },
        {
          label : 'Si (Silicon)',
          backgroundColor: '#a5a5a5',
          borderColor: '#a5a5a5',
          data: siArr
        },
      ]

      oilConditionChartDataValue = [
        {
          label : 'OXI (Oxidation)',
          backgroundColor: '#4472c4',
          borderColor: '#4472c4',
          data: oxiArr
        },
        {
          label : 'NIT (Nitration)',
          backgroundColor: '#ed7d31',
          borderColor: '#ed7d31',
          data: nitArr
        },
        {
          label : 'SUL (Sulfur Products)',
          backgroundColor: '#a5a5a5',
          borderColor: '#a5a5a5',
          data: sulArr
        },
        {
          label : 'TBN (Total Base Number)',
          backgroundColor: '#ffc000',
          borderColor: '#ffc000',
          data: tbnArr
        },
      ]

       viscosityChartDataValue = [
        {
          label : 'V100',
          backgroundColor: '#4472c4',
          borderColor: '#4472c4',
          data: v100Arr
        },
      ]

      this.wearChartData.labels = chartLabels
      this.contaminationChartData.labels = chartLabels
      this.oilConditionChartData.labels = chartLabels
      this.viscosityChartData.labels = chartLabels

      this.wearChartData.datasets = wearChartDataValue
      this.contaminationChartData.datasets = contaminationChartDataValue
      this.oilConditionChartData.datasets = oilConditionChartDataValue
      this.viscosityChartData.datasets = viscosityChartDataValue
    }
  }
}
</script>

<style lang="scss" scoped>
.report_table{
  width: 100%;
}

.report_table tr {
  height:42px;
}

.report_table tr td {
  padding:5px;
  border: 1px solid #bdbdbd;
}

.input-report{
  height:25px;
  border:none;
}

.form-input{
  width:100%;
}

.labelx{
  margin-bottom:10px;
}

.submit-btn{
  padding: 0.75rem 2rem;
  border:none;
  cursor: pointer;
  background: #1074bf;
  color:#fff;
  border-radius: 5px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

.submit-btn:hover{
  box-shadow: 0px 0px 10px #bdbdbd;
}

.report_data_content{
  width: auto;
  font-size: 10pt;
}

.report_data_content div{
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top:5px;    
  padding-bottom:5px;    
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #e6e6e6;
}
.header-collumn {
  border:1px solid #e6e6e6;
}
.data-collumn {
  border:1px solid #e6e6e6;
}
.data-collumn:hover {
  border:1px solid #212529;
}

.comment-text{
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  white-space: pre-line ;
}

.report_scrollable{
  padding-left:0px;
  margin-top:1px;
  width:800px;
  margin:0;
  overflow-x:auto;
}

.report_data_content div:nth-child(odd){
  background-color:#f2f2f2;
}

.pdf_btn{
  background:#4CAFE4;
  color:#f2f2f2;
  padding-top:5px;
  border-radius:50%;
  font-size:14pt;
  width:40px;
  height:40px;
  text-align: center;
  transition: ease-in;
  transition-duration: 0.2s;

  &:hover{
    background:#5db8e9;
    color:#fff;
    font-size: 15pt;
    width:42px;
    height:42px;
    box-shadow: #3987b1 0px 5px 5px;
  }
}

@media print {
  .container {
    margin: 0;  
  }
  .hide-print{
    display: none;
  }

  .report_scrollable{
    width: 100%;
    overflow: visible;
  }

  .report_data_content div {
    min-width: auto;
  }

  .data-collumn:last-child{
    border-right: 1px solid #e6e6e6;
  }

  .button-comments {
    visibility: hidden;
  }

}


</style>