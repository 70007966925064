<template>
  <div id="shipowner">
    <!-- <c-header /> -->
    <div id="maps">
      <l-map
        :padding-top-left="paddingTopLeft"
        :padding-bottom-right="paddingBottomRight"
        :options="mapOptions"
        :zoom="currentZoom"
        :center="currentCenter"
        :bounds="currentBounds"
        @click="clickMap"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
      >
        <l-control-scale
          position="bottomright"
          :imperial="true"
          :metric="false"
        />
        <l-control-layers position="topright" />
        <l-control-zoom position="bottomright" />

        <l-tile-layer
          v-for="tile in tileProviders"
          :key="tile.name"
          :name="tile.name"
          :visible="tile.visible"
          :url="tile.url"
          :attribution="tile.attribution"
          layer-type="base"
        />

        <l-polyline
          v-if="polyline.show"
          :lat-lngs="polyline.latlngs"
          :color="polyline.color"
          :dash-array="polyline.dash"
        />

        <l-marker
          v-for="(cargo, index) in cargoLoc"
          :key="index"
          :lat-lng="[
            cargo.port.from.location.latitude,
            cargo.port.from.location.longitude,
          ]"
          :icon="IconCargo"
          @click="showCargoList(cargo)"
        />

        <l-marker
          v-if="fromMarker.show"
          id="from-marker"
          ref="fromMarker"
          :lat-lng="fromMarker.latlngs"
          :icon="IconCargo"
        >
          <l-popup>
            <p class="font-weight-bold">
              {{ fromMarker.data.cargo.name }},
              {{ thausandSparator(fromMarker.data.qty.amount) }}
              {{ fromMarker.data.qty.type }}
            </p>
            <p>
              {{ ddmmyyySlash(fromMarker.data.laycan.from) }} -
              {{ ddmmyyySlash(fromMarker.data.laycan.to) }}
            </p>
            <p>
              {{ hide_pelabuhan(fromMarker.data.port.from.name) }} &#10141;
              {{ hide_pelabuhan(fromMarker.data.port.destination.name) }}
            </p>
            <b-button
              block
              size="sm"
              variant="primary"
              class="mx-0"
              v-if="!fromMarker.data.hasOwnProperty('only_view')"
              @click="toggleModalDetailCargo"
            >
              View
            </b-button>
          </l-popup>
        </l-marker>

        <l-marker
          v-if="destinationMarker.show"
          :lat-lng="destinationMarker.latlngs"
          :icon="IconCargo"
        >
          <l-tooltip :options="{ permanent: false, interactive: true }">
            <strong>
              {{ destinationMarker.name }}
            </strong>
          </l-tooltip>
        </l-marker>
        <v-marker-cluster
          :options="clusterOptions"
          @clusterclick="click"
          @ready="ready"
        >
          <AdsMarker :ads-company="adsCompany" @send="viewProductsAds" />
        </v-marker-cluster>

        <!-- position history from view history button -->
        <div v-if="selectedTrailHistory !== null">
          <l-polyline
            :lat-lngs="selectedTrailHistory"
            color="#00a2e8"
            :dash-array="polyline.dash"
          />

          <p
            v-for="(position, indexPosition) in selectedTrailHistory"
            :key="'th' + indexPosition"
          >
            <v-rotated-marker
              v-if="position.icon != null"
              :lat-lng="[position.lat, position.lng]"
              :icon="position.icon"
              :rotation-angle="position.rotation"
              :options="{
                zIndexOffset: 500,
              }"
            >
              <l-popup>
                <p class="font-weight-bold m-0">
                  {{ position.name }}
                </p>
                <p class="m-0">
                  <small>
                    {{ position.vessel_type }}
                  </small>
                  ,

                  <small>
                    {{ position.load_capacity }}
                  </small>
                </p>

                <hr class="mt-2 mb-2" />

                <p class="m-0">
                  <strong>Provider :</strong>
                  {{ position.provider === "Raztel" ? "VTS" : "AIS" }}
                </p>

                <p v-show="position.heading" class="m-0">
                  <strong>Heading :</strong> {{ position.heading }}&#176;
                </p>

                <p v-show="position.speed && position.course" class="m-0">
                  <strong>Speed / Course :</strong> {{ position.speed }} kn /
                  {{ position.course }}&#176;
                </p>

                <p v-show="position.windspeedKmph" class="m-0">
                  <strong>Wind Speed :</strong>
                  {{ position.windspeedKmph }} km/h
                </p>

                <p v-show="position.winddirDegree" class="m-0">
                  <strong>Wind Direction :</strong>
                  {{ position.winddirDegree }}&#176;
                </p>

                <p v-show="position.swellHeight_m" class="m-0">
                  <strong>Wave Height :</strong> {{ position.swellHeight_m }} m
                </p>

                <p v-show="position.swellDir" class="m-0">
                  <strong>Wave Direction :</strong>
                  {{ position.swellDir }}&#176;
                </p>

                <p v-show="position.tides" class="m-0">
                  <strong>Tides :</strong> {{ position.tides }} m
                </p>

                <p class="m-0">
                  <strong>Latitude / Longitude :</strong>
                  {{ position.lat || "" }}&#176; /
                  {{ position.lng || "" }}&#176;
                </p>

                <p class="m-0">
                  <strong>Last Update Position :</strong>
                  {{ formattedDate(position.timestamp) || "-" }}
                </p>

                <p v-show="position.address" class="m-0">
                  <strong>Proximity :</strong> {{ position.address }}
                </p>
              </l-popup>
            </v-rotated-marker>
          </p>
        </div>
        <!-- position history from view history button -->

        <!-- position history polyline -->
        <div
          v-for="(itemVessel, indexVessel) in vesselEntries"
          :key="'v' + itemVessel.id + '_' + indexVessel"
        >
          <div
            v-if="
              itemVessel.show_history && itemVessel.position_history.length > 0
            "
          >
            <l-polyline
              :lat-lngs="itemVessel.position_history"
              color="#00a2e8"
              :dash-array="polyline.dash"
            />

            <p
              v-for="(position, indexPosition) in itemVessel.position_history_markers"
              :key="'p' + itemVessel.id + '_' + indexPosition"
            >
              <v-rotated-marker
                v-if="position.icon != null"
                :lat-lng="[position.lat, position.lng]"
                :icon="position.icon"
                :rotation-angle="position.rotation"
                :options="{
                  zIndexOffset: 500,
                }"
              >
                <l-popup>
                  <p class="font-weight-bold m-0">
                    {{ position.name }}
                  </p>
                  <p class="m-0">
                    <small>
                      {{ position.vessel_type }}
                    </small>
                    ,

                    <small>
                      {{ position.load_capacity }}
                    </small>
                  </p>

                  <hr class="mt-2 mb-2" />

                  <p class="m-0">
                    <strong>Provider :</strong>
                    {{ position.provider === "Raztel" ? "VTS" : "AIS" }}
                  </p>

                  <p v-show="position.heading" class="m-0">
                    <strong>Heading :</strong> {{ position.heading }}&#176;
                  </p>

                  <p v-show="position.speed && position.course" class="m-0">
                    <strong>Speed / Course :</strong> {{ position.speed }} kn /
                    {{ position.course }}&#176;
                  </p>

                  <p v-show="position.windspeedKmph" class="m-0">
                    <strong>Wind Speed :</strong>
                    {{ position.windspeedKmph }} km/h
                  </p>

                  <p v-show="position.winddirDegree" class="m-0">
                    <strong>Wind Direction :</strong>
                    {{ position.winddirDegree }}&#176;
                  </p>

                  <p v-show="position.swellHeight_m" class="m-0">
                    <strong>Wave Height :</strong>
                    {{ position.swellHeight_m }} m
                  </p>

                  <p v-show="position.swellDir" class="m-0">
                    <strong>Wave Direction :</strong>
                    {{ position.swellDir }}&#176;
                  </p>

                  <p v-show="position.tides" class="m-0">
                    <strong>Tides :</strong> {{ position.tides }} m
                  </p>

                  <p class="m-0">
                    <strong>Latitude / Longitude :</strong>
                    {{ position.lat || "" }}&#176; /
                    {{ position.lng || "" }}&#176;
                  </p>

                  <p class="m-0">
                    <strong>Last Update Position :</strong>
                    {{ formattedDate(position.timestamp) || "-" }}
                  </p>

                  <p v-show="position.address" class="m-0">
                    <strong>Proximity :</strong> {{ position.address }}
                  </p>
                </l-popup>
              </v-rotated-marker>
            </p>
          </div>
        </div>
        <!-- position history polyline -->

        <v-marker-cluster :options="clusterOptions">
          <template v-if="vesselEntries.length > 0">
            <div
              v-for="(itemVessel, indexVessel) in vesselEntries"
              :key="indexVessel"
            >
              <l-marker
                v-if="
                  itemVessel.latitude != null && itemVessel.longitude != null
                "
                :lat-lng="[itemVessel.latitude, itemVessel.longitude]"
                :icon="IconVessel"
                :options="{
                  zIndexOffset: 1000,
                }"
                @click="clickVessel(itemVessel)"
              >
                <l-popup>
                  <p class="font-weight-bold m-0">
                    {{ itemVessel.name }}
                  </p>
                  <p class="m-0">
                    <small>
                      {{ itemVessel.vessel_type }}
                    </small>
                    ,

                    <small>
                      {{ itemVessel.load_capacity }}
                    </small>
                  </p>

                  <hr class="mt-2 mb-2" />

                  <p class="m-0">
                    <strong>Provider :</strong>
                    {{ itemVessel.provider === "Raztel" ? "VTS" : "AIS" }}
                  </p>

                  <p v-show="itemVessel.heading" class="m-0">
                    <strong>Heading :</strong> {{ itemVessel.heading }}&#176;
                  </p>

                  <p v-show="itemVessel.speed && itemVessel.course" class="m-0">
                    <strong>Speed / Course :</strong> {{ itemVessel.speed }} kn
                    / {{ itemVessel.course }}&#176;
                  </p>

                  <p v-show="itemVessel.windspeedKmph" class="m-0">
                    <strong>Wind Speed :</strong>
                    {{ itemVessel.windspeedKmph }} km/h
                  </p>

                  <p v-show="itemVessel.winddirDegree" class="m-0">
                    <strong>Wind Direction :</strong>
                    {{ itemVessel.winddirDegree }}&#176;
                  </p>

                  <p v-show="itemVessel.swellHeight_m" class="m-0">
                    <strong>Wave Height :</strong>
                    {{ itemVessel.swellHeight_m }} m
                  </p>

                  <p v-show="itemVessel.swellDir" class="m-0">
                    <strong>Wave Direction :</strong>
                    {{ itemVessel.swellDir }}&#176;
                  </p>

                  <p v-show="itemVessel.tides" class="m-0">
                    <strong>Tides :</strong> {{ itemVessel.tides }} m
                  </p>

                  <p class="m-0">
                    <strong>Latitude / Longitude :</strong>
                    {{ itemVessel.latitude || "" }}&#176; /
                    {{ itemVessel.longitude || "" }}&#176;
                  </p>

                  <p class="m-0">
                    <strong>Last Update Position :</strong>
                    {{ formattedDate(itemVessel.timestamp) || "-" }}
                  </p>

                  <p v-show="itemVessel.address" class="m-0">
                    <strong>Proximity :</strong> {{ itemVessel.address }}
                  </p>
                </l-popup>
              </l-marker>
            </div>
          </template>
        </v-marker-cluster>

        <!-- find route markers -->
        <l-marker
          v-for="(marker, index) in findRouteMarkers"
          :key="index"
          :lat-lng="[marker.lat, marker.lng]"
          :icon="marker.icon"
          @click="showRouteMarker(index, marker)"
        >
          <l-popup>
            <div>Pin Point {{ index + 1 }}</div>
            <div>lat: {{ marker.lat }}</div>
            <div>lng: {{ marker.lng }}</div>
          </l-popup>
        </l-marker>
        <!-- find route markers -->

        <!-- find route polyline -->
        <l-polyline :lat-lngs="findRouteMarkers" color="#00a2e8" />
        <!-- find route polyline -->
      </l-map>
    </div>

    <LoadingPage :show="isLoading" />
    <PanelCargoList :title="titlePanelCargoList" @find="findRoute" />
    <PanelChat />
    <UnverifCompany :show="!statusCompany" />
    <ModalAdvertisement :data="dataAds" />
    <OfferingDocumentsModal ref="offering-documents-modal"/>
    <OfferingStatusModal ref="offering-status-modal"/>
    <ManifestModal ref="manifest-modal"/>
    <ManifestLogsModal ref="manifest-logs-modal"/>
    <EngineHealthReportModal ref="engine-health-report-modal"/>

    <!-- Start code sidenav from here -->
    <div id="sidenav">
      <!-- Start toggle sidenav -->
      <div
        id="open-btn"
        class="c-pointer animated fadeIn delay-3"
        :class="{ active: showLeftbar }"
        @click="TOOGLE_NAV"
      />

      <!-- Start tab Sidenav -->
      <div
        id="menu-tab"
        class="rows menu-tab animated bounceInLeft"
        :class="{ active: showLeftbar }"
      >
        <router-link
          v-for="(menu, index) in menus"
          :key="index"
          v-slot="{ navigate, isActive }"
          :to="menu.link"
        >
          <div
            :id="menu.id"
            class="col-5th-2 col-5th-2 p-0"
            :class="[{ active: isActive }, menu.style]"
            @click="navigate"
          >
            <i
              v-if="menu.hasNotification && notifInbox.unread"
              class="notif-chat fas fa-circle fa-lg text-warning"
            >
              <span class="num">{{ notifInbox.unread }}</span>
            </i>
            <span class="text-menu">{{ menu.title }}</span>
          </div>
        </router-link>
      </div>
      <!-- End Tab Sidenav -->

      <!-- End toogle sidenav -->
      <div
        id="toggle-nav"
        class="sidenav animated bounceInLeft"
        :class="{ active: showLeftbar }"
      >
        <router-view />
      </div>
    </div>
    <!-- End code sidenav until here -->

    <FindThings @input="setInputFindThings" />

    <!-- find route trigger buttons -->
    <button
      class="btn btn-findroute animated bounceInDown delay-2"
      :class="{ 'btn-findroute-active': isMeasure, 'show-menu': showLeftbar }"
      data-toggle="tooltip"
      data-placement="right"
      title="Measure distances"
      @click="toggleMeasure"
    >
      <img src="/images/ruler.png" />
    </button>
    <!-- find route trigger buttons -->

    <!-- find route action buttons -->
    <div class="findroute-action-buttons">
      <div v-show="findRouteMarkers.length > 1" class="findroute-calculate">
        <div class="findroute-box">
          <label>Speed (Knot):</label>
          <input
            v-model="speed"
            type="number"
            class="form-control"
            placeholder="Speed"
            @keyup="calculateDistance"
            @change="calculateDistance"
          />
          <label class="mt-2 findroute-key">Distance (km):</label>
          <div class="findroute-value">{{ distance | formatNumber }} km</div>
          <label class="mt-2 findroute-key">Distance (Nautical Miles):</label>
          <div class="findroute-value">{{ NMDistance | formatNumber }} NM</div>
          <label class="mt-2 findroute-key">Estimated Time:</label>
          <div class="findroute-estimated-time findroute-value">
            {{ estimatedTime }}
          </div>
        </div>
      </div>
      <button
        v-show="findRouteMarkers.length > 0"
        class="btn btn-secondary btn-sm findroute-reset-btn"
        @click="resetFindRouteMarkers"
      >
        Reset
      </button>
    </div>
    <!-- find route action buttons -->

    <button
      v-if="selectedTrailHistory !== null"
      class="btn btn-secondary btn-sm btn-reset-trail"
      @click="resetTrailHistory"
    >
      Reset Trail History
      <br />
      {{ trailHistoryLabel }}
    </button>

    <!-- End code maps until here -->
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LPolyline,
  LControlZoom,
  LControlLayers,
  LControlScale,
} from "vue2-leaflet";
import formatDate from "../mixins/formatDate";
import formatChar from "../mixins/formatChar";
import { mapMutations, mapState } from "vuex";
import LoadingPage from "../components/dialogs/LoadingDialog";
import PanelCargoList from "../components/PanelCargoList";
import UnverifCompany from "../components/dialogs/UnverificatedCompany";
import FindThings from "../components/FindThings";
import AdsMarker from "../components/AdsMarker";
import API from "../plugins/axios";
import PanelChat from "../components/PanelChat";
import ModalAdvertisement from "@/components/modals/ModalAdvertisement";
import OfferingDocumentsModal from "@/components/modals/OfferingDocumentsModal";
import OfferingStatusModal from "@/components/modals/OfferingStatusModal";
import ManifestModal from "@/components/modals/ManifestModal";
import ManifestLogsModal from "@/components/modals/ManifestLogsModal";
import EngineHealthReportModal from "@/components/modals/EngineHealthReportModal";
import Vue2LeafletMarkercluster from "vue2-leaflet-markercluster";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import { SwalWarning } from "../plugins/sweetalert2";
import moment from "moment";
import { icon } from "leaflet";
var numeral = require("numeral");

export default {
  components: {
    LoadingPage,
    PanelCargoList,
    PanelChat,
    UnverifCompany,
    FindThings,

    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LPolyline,
    LControlZoom,
    LControlLayers,
    LControlScale,
    "v-marker-cluster": Vue2LeafletMarkercluster,
    "v-rotated-marker": Vue2LeafletRotatedMarker,

    AdsMarker,
    ModalAdvertisement,
    OfferingDocumentsModal,
    OfferingStatusModal,
    ManifestModal,
    ManifestLogsModal,
    EngineHealthReportModal
  },
  filters: {
    formatNumber: function (value) {
      return numeral(value).format("0,0.00"); // displaying other groupings/separators is possible, look at the docs
    },
  },
  mixins: [formatDate, formatChar],
  data() {
    return {
      clusterOptions: {
        disableClusteringAtZoom: 18,
        chunkedLoading: true,
      },
      dataAds: {},
      menus: [
        {
          id: "menu-tab-vessel",
          title: "Vessel List",
          link: "/app/vessel-list",
          style: "menu-tab-vessel",
          hasNotification: false,
        },
        {
          id: "menu-tab-planner",
          title: "License",
          link: "/app/payment-license",
          style: "menu-tab-planner",
          hasNotification: false,
        },
        {
          id: "menu-tab-cargo",
          title: "Cargo Finder",
          link: "/app/cargo-finder",
          style: "menu-tab-cargo",
          hasNotification: false,
        },
        {
          id: "menu-tab-booking",
          title: "Booking List",
          link: "/app/booking-list",
          style: "menu-tab-booking",
          hasNotification: false,
        },
        {
          id: "menu-tab-inbox",
          title: "Inbox",
          link: "/app/inbox",
          style: "menu-tab-inbox",
          hasNotification: true,
        },
      ],
      titlePanelCargoList: "",
      statusCompany: true,
      adsCompany: "",
      isMeasure: false,
      findRouteMarkers: [],
      distance: 0,
      NMDistance: 0,
      speed: 0,
      estimatedTime: "",
      selectedTrailHistory: null,
    };
  },
  computed: {
    ...mapState([
      "activePanelCargoList",
      "cargoLoc",
      "polyline",
      "destinationMarker",
      "fromMarker",
      "isLoading",
      "showLeftbar",
      "currentZoom",
      "currentCenter",
      "currentBounds",
      "paddingTopLeft",
      "paddingBottomRight",
      "mapOptions",
      "tileProviders",
      "icon",
      "IconVessel",
      "IconCargo",
      "currentCargo",
      "trailHistory",
      "trailHistoryLabel",
      "showOfferingListDocuments",
      "showOfferingStatus",
      "showEngineHealthReport",
      "showManifest",
      "showManifestLogs",
    ]),
    ...mapState("moduleVessel", {
      vesselEntries: (state) => state.listData,
    }),
    notifInbox() {
      return this.$store.state.moduleInbox.notifInbox;
    },
  },
  created() {
    console.log(this.showEngineHealthReport)
    API.get("user/profile")
      .then((res) => {
        const data = res.data.data;
        this.statusCompany = !!(data.company.phone && data.company.address);
      })
      .catch((err) => {
        //show unauthenticated dialog
        SwalWarning("Unauthenticated!", () => {
          this.$store.dispatch("moduleAuth/ERASE_LOCAL_STORAGE");
        });
      });

    //check if should show offering document
    if(typeof this.$route.query.offering_list_id !== 'undefined') {
      this.$store.commit("SET_SHOW_OFFERING_LIST_DOCUMENTS", {
        offerId : this.$route.query.offering_list_id,
        editable: false,
        openDate : new Date(),
      });
    }
  },
  watch: {
    currentZoom: {
      handler: function (newVal, oldVal) {
        const vm = this
        //let tolerance = (newVal * 100) - ((newVal - 9) * 200); 
        //console.log(newVal);
        let tolerance = 0;
        if(newVal < 6){
          tolerance = 10000;
        }
        else if(newVal >= 6 && newVal < 7){
          tolerance = 9000;
        }
        else if(newVal >= 7 && newVal < 8){
          tolerance = 8000;
        }
        else if(newVal >= 8 && newVal < 9){
          tolerance = 7000;
        }
        else if(newVal >= 9 && newVal < 10){
          tolerance = 6000;
        }
        else if(newVal >= 10 && newVal < 11){
          tolerance = 5000;
        }
        else if(newVal >= 11 && newVal < 12){
          tolerance = 3000;
        }
        else if(newVal >= 12 && newVal < 13){
          tolerance = 2000;
        }
        else if(newVal >= 13 && newVal < 14){
          tolerance = 1000;
        }
        else if(newVal >= 14){
          tolerance = 0;
        }

        let counter = 0
        vm.vesselEntries.map(vessel => {
          let visibleMarkers = [];
      
          for(var i = 0; i < vessel.position_history.length; i++) {
            if(i === 0 || i === vessel.position_history.length - 1) {
              visibleMarkers.push(vessel.position_history[i]);
              counter++
            } else {
              //get distance
              const distance = this.getDistance(visibleMarkers[counter-1], vessel.position_history[i]);
              if(distance > tolerance) {
                visibleMarkers.push(vessel.position_history[i]);
                counter++
              }
            }
          }

          vessel.position_history_markers = visibleMarkers
        })
      },
      deep: true,
    },
    showEngineHealthReport: {
      handler: function (newVal, oldVal) {
        console.log("test")
        // if(newVal.vesselEngineId !== null){
        //   this.$refs["engine-health-report-modal"].show(newVal.vesselEngineId);
        // }
      },
      deep: true,
    },
    showManifest: {
      handler: function (newVal, oldVal) {
        if(newVal.shipmentId !== null){
          this.$refs["manifest-modal"].show(newVal.shipmentId, newVal.editable);
        }
      },
      deep: true,
    },
    showManifestLogs: {
      handler: function (newVal, oldVal) {
        if(newVal.shipmentId !== null){
          this.$refs["manifest-logs-modal"].show(newVal.shipmentId);
        }
      },
      deep: true,
    },
    showOfferingListDocuments: {
      handler: function (newVal, oldVal) {
        if(newVal.offerId !== null){
          this.$refs["offering-documents-modal"].show(newVal.offerId, newVal.editable);
        }
      },
      deep: true,
    },
    showOfferingStatus: {
      handler: function (newVal, oldVal) {
        if(newVal.offerId !== null){
          this.$refs["offering-status-modal"].show(newVal.offerId, newVal.editable);
        }
      },
      deep: true,
    },
    currentCargo(data) {
      if (data) {
        this.$store.dispatch("FIND_ROUTE", data).then(() => {
          this.$nextTick(() => {
            this.$refs.fromMarker.mapObject.openPopup(); // markert port start to open popup
            document
              .querySelectorAll("a.leaflet-popup-close-button")[0]
              .addEventListener("click", () =>
                this.$store.dispatch("RESET_ROUTE")
              ); // make leaflet pop up close button clickable
          });
        });
      }
    },
    trailHistory(data) {
      if (data) {
        //get position history
        let positionData = [];
        let positions = data;
        positions.forEach((position, index) => {
          //normalize lat & lng
          let lat = position.latitude;
          let lng = position.longitude;

          //get rotation
          var rotation = 0;
          if (index > 0) {
            let positionAfter = positions[index - 1];

            var lat1 = lat;
            var lat2 = positionAfter.latitude;
            var long1 = lng;
            var long2 = positionAfter.longitude;

            var dLon = long2 - long1;
            var y = Math.sin(dLon) * Math.cos(lat2);
            var x =
              Math.cos(lat1) * Math.sin(lat2) -
              Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
            var brng = Math.atan2(y, x);

            brng = brng * (180 / Math.PI);
            brng = (brng + 360) % 360;
            rotation = brng; // count degrees counter-clockwise - remove to make clockwise
          }

          //set icon
          let iconData = null;
          let label = "";

          iconData = icon({
            iconUrl:
              position.speed <= 0
                ? "/images/icon-no-speed.png"
                : "/images/icon-wind-direction.png",
            iconSize: [16, 16],
            iconAnchor: [8, 7],
          });

          positionData.push({
            name: position.name,
            vessel_type: position.vessel_type,
            provider: position.provider,
            load_capacity: position.load_capacity,
            timestamp: position.datetime,
            course: position.course,
            speed: position.speed,
            lat: lat,
            lng: lng,
            icon: iconData,
            rotation: rotation,
            label: label,
            windspeedKmph: position.windspeedKmph,
            winddirDegree: position.winddirDegree,
            swellHeight_m: position.swellHeight_m,
            swellDir: position.swellDir,
            tides: position.tides,
            address: position.address,
          });
        });

        this.selectedTrailHistory = positionData;

        //fit bound
        let mapBounds = [];
        positionData.forEach((position) => {
          mapBounds.push([position.lat, position.lng]);
        });
        this.$store.commit("SET_BOUND", mapBounds);
      } else {
        this.selectedTrailHistory = null;
      }
    },
  },
  methods: {
    ...mapMutations(["TOOGLE_NAV", "RESET_BOUND"]),
    formattedDate(value) {
      if (!value) return;

      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
    normalizePosition(value) {
      if (!value) return;

      if (!Number.isInteger(value)) {
        value = value.replace(",", ".");
        value = parseFloat(value);
      }

      return value;
    },
    viewProductsAds(data) {
      this.dataAds = data;
    },
    setInputFindThings(value) {
      this.adsCompany = value;
    },
    click: (e) => console.log("clusterclick", e),
    ready: (e) => console.log("ready", e),
    zoomUpdated(zoom) {
      this.$store.commit("ZOOM_UPDATED", zoom);
    },
    centerUpdated(center) {
      this.$store.commit("CENTER_UPDATED", center);
    },
    showCargoList(data) {
      this.titlePanelCargoList = data.port.from.name;
      this.$store.dispatch("SHOW_CARGO_LIST", data);
    },
    toggleModalDetailCargo() {
      this.$store.commit("SET_MODAL_DETAIL_CARGO", true);
    },
    findRoute(data) {
      this.$store.dispatch("FIND_ROUTE", data).then(() => {
        this.$nextTick(() => {
          this.$refs.fromMarker.mapObject.openPopup(); // markert port start to open popup
          document
            .querySelectorAll("a.leaflet-popup-close-button")[0]
            .addEventListener("click", () =>
              this.$store.dispatch("RESET_ROUTE")
            ); // make leaflet pop up close button clickable
        });
      });
    },
    findThings(val) {
      console.log(val);
    },
    toggleMeasure() {
      this.isMeasure = !this.isMeasure;
    },
    clickMap(e) {
      if (this.isMeasure) {
        //add marker to clicked data
        this.findRouteMarkers.push({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
          icon: icon({
            iconUrl: "/images/findroute-icon.png",
            iconSize: [8, 8],
            iconAnchor: [4, 5],
          }),
        });

        if (this.findRouteMarkers.length > 1) {
          this.calculateDistance();
        }
      } else {
        //hide all history
        this.vesselEntries.forEach((vessel) => {
          vessel.show_history = false;
        });

        //set store
        this.$store.commit("SET_CURRENT_VESSEL_TRAIL", null);
      }
    },
    showRouteMarker(index, marker) {
      console.log(index);
      console.log(marker);
    },
    resetFindRouteMarkers() {
      this.findRouteMarkers = [];
      this.distance = 0;
      this.speed = 0;
      this.estimatedTime = "";
    },
    calculateDistance() {
      //reset
      this.distance = 0;

      //get all marker
      let index = 0;
      while (index < this.findRouteMarkers.length - 1) {
        //increment index
        index++;

        //get origin & destination
        let origin = this.findRouteMarkers[index - 1];
        let destination = this.findRouteMarkers[index];

        //increment distance
        let distanceInMeters = this.getDistance(origin, destination);
        let distanceInKm = distanceInMeters / 1000;
        this.distance += distanceInKm;
      }

      this.distance = this.distance.toFixed(2);
      this.NMDistance = (this.distance * 0.539957).toFixed(2);

      this.getEstimatedTime();
    },
    getEstimatedTime() {
      //get speed
      const speed = this.speed;
      const distance = this.NMDistance;

      if (speed > 0 && distance > 0) {
        //get estimated time
        const timeHours = distance / speed;
        const timeSeconds = timeHours * 3600;
        this.estimatedTime = this.convertSecondsToDaysHoursMinutes(timeSeconds);
      }

      if (speed == 0) {
        this.estimatedTime = "input speed";
      }

      if (distance == 0) {
        this.estimatedTime = "select destination";
      }
    },
    convertSecondsToDaysHoursMinutes(timeSeconds) {
      let days = Math.floor(timeSeconds / 86400);
      let hours = Math.floor((timeSeconds % 86400) / 3600);
      let minutes = Math.floor(((timeSeconds % 86400) % 3600) / 60);
      let seconds = ((timeSeconds % 86400) % 3600) % 60;

      let time = "";
      if (days > 0) {
        time += days; //+ " days ";
        if (days == 1) {
          time += " day ";
        } else {
          time += " days ";
        }
      }
      if (hours > 0) {
        time += hours; //+ " hours ";
        if (hours == 1) {
          time += " hour ";
        } else {
          time += " hours ";
        }
      }
      if (days <= 0) {
        if (minutes > 0) {
          time += minutes; // + " minutes ";
          if (minutes == 1) {
            time += " minute ";
          } else {
            time += " minutes ";
          }
        }
      }

      return time;
    },
    getDistance(origin, destination) {
      // return distance in meters
      var lon1 = this.toRadian(origin.lng),
        lat1 = this.toRadian(origin.lat),
        lon2 = this.toRadian(destination.lng),
        lat2 = this.toRadian(destination.lat);

      var deltaLat = lat2 - lat1;
      var deltaLon = lon2 - lon1;

      var a =
        Math.pow(Math.sin(deltaLat / 2), 2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
      var c = 2 * Math.asin(Math.sqrt(a));
      var EARTH_RADIUS = 6371;
      return c * EARTH_RADIUS * 1000;
    },
    toRadian(degree) {
      return (degree * Math.PI) / 180;
    },
    clickVessel(vessel) {
      //set store
      this.$store.commit("SET_CURRENT_VESSEL_TRAIL", vessel);

      this.vesselEntries.forEach((vessel) => {
        vessel.show_history = false;
      });

      vessel.show_history = true;

      let mapBounds = [];

      vessel.position_history.map((history) => {
        mapBounds.push([history.lat, history.lng]);
      });

      //fit bound
      this.$store.commit("SET_BOUND", mapBounds);
    },
    resetTrailHistory() {
      this.$store.commit("SET_TRAIL_HISTORY", null);
      this.$store.commit("SET_TRAIL_HISTORY_LABEL", null);
    },
  },
};
</script>

<style scoped></style>
