<template>
  <div>
    &nbsp;
  </div>
</template>

<script>
import API from './../../plugins/axios'
import {
  mapGetters,
  mapMutations
} from 'vuex'
import {
  SwalWarning
} from './../../plugins/sweetalert2'

export default {
  data () {
    return {
      // 
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'moduleAuth/loggedIn'
    }),

    token () {
      return this.$route.params.token
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    ...mapMutations({
      setUserData: 'moduleAuth/SET_USER_DATA'
    }),

    init () {
      if (!this.isAuthenticated) {
        this.setUserData({
          token: {
            access_token: this.token
          },
          data: {}
        })

        window.location.reload()

        return
      }

      API.get('/auth/validate')
        .then(res => {
          const { data } = res.data

          if (!data.roles.id) {
            this.$router.push(`/auth/wizard/company/${this.token}`)

            return
          }
          
          this.setUserData({
            token: {
              access_token: this.token
            },
            data
          })

          window.location = '/app'
        })
        .catch(err => {
          console.info('err :: ', err)
        })
    }
  }
}
</script>