<template>
  <div
    class="findThings animated bounceInDown delay-2"
    :class="{ full: showLeftbar }"
    :style="{ left: toggleStyle }"
  >
    <h5>Hi, check out our vendors!</h5>

    <!-- <div class="category">
      <h5 v-for="(badge, index) in badgeSelect" :key="index">
        <b-badge :variant="badge.style" class="c-pointer font-weight-light m-1 shadow-sm" @click="selected(badge)">{{ badge.name }}</b-badge>
      </h5>
    </div> -->

    <transition name="fade">
      <div v-show="showTips" class="tips-ads">
        <div />
        <div class="d-flex align-items-center">
          <i class="far fa-lightbulb text-success" />
          <div class="mx-2 small">Find vendors here.</div>
        </div>
        <i
          class="fas fa-times fa-sm text-muted c-pointer"
          @click="showTips = false"
        />
      </div>
    </transition>

    <div
      class="toggle d-flex justify-content-center align-items-center"
      @click="actionToggle"
    >
      <i class="fa fa-x text-secondary" :class="toggleIcon" />
    </div>

    <div class="w-100 mt-2">
      <v-select
        id="inputThings"
        class="inputThings"
        :options="optionsSelect"
        placeholder="Select Category"
        label="name"
        @input="selected"
      >
        <template #option="{ name }">
          {{ name }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import API from "@/plugins/axios";
import { mapState } from "vuex";
import { SwalWarning } from "../plugins/sweetalert2";
export default {
  name: "FindThings",
  data() {
    return {
      showTips: false,
      optionsSelect: [
        { id: 1, name: "Fuel" },
        { id: 2, name: "Oil" },
        { id: 3, name: "Gear" },
        { id: 4, name: "Cat" },
        { id: 5, name: "Sands" },
        { id: 6, name: "Rocks" },
      ],
      badgeSelect: [],
      isToggle: false,
    };
  },
  computed: {
    ...mapState(["showLeftbar"]),
    toggleStyle() {
      let value = "";
      if (this.isToggle && this.showLeftbar) value = "415px";
      else if (this.isToggle && !this.showLeftbar) value = "10px";
      else if (!this.isToggle && this.showLeftbar) {
        if (window.screen.width >= 768) {
          value = "145px";
        }
      } else if (!this.isToggle && !this.showLeftbar) value = "-250px";
      return value;
    },
    toggleIcon() {
      return this.isToggle ? "fa-times" : "fa-search-location";
    },
  },
  mounted() {
    this.loadDropdown();
    setTimeout(() => (this.showTips = true), 3000);
  },
  methods: {
    loadRandomTag() {
      const length =
        this.optionsSelect.length > 5 ? 5 : this.optionsSelect.length;
      for (let i = 0; i < length; i++) {
        this.badgeSelect.push({
          id: this.optionsSelect[i].id,
          name: this.optionsSelect[i].name,
          style: "secondary",
        });
      }
    },
    loadDropdown() {
      API.get("publicity/category/dropdown")
        .then((res) => {
          this.optionsSelect = res.data.data;
          this.loadRandomTag();
        })
        .catch(() => {
          this.optionsSelect = [{ id: null, name: "No Category Found" }];
        });
    },
    selected(value) {
      const dataLayer = window.dataLayer || [];
      const { id } = value;

      dataLayer.push({
        event: "selectedVendorCategories",
        selectedVendorCategories: id,
      });

      this.activeBadge(value);
      this.$store.dispatch("LOAD_ADS", value).then((res) => {
        if (res.data.data.length < 1) {
          SwalWarning("Vendors tidak ditemukan");
        }
      });

      this.$emit("input", id);
    },
    actionToggle() {
      this.isToggle = !this.isToggle;
      this.showTips = false;
    },
    activeBadge(data) {
      this.badgeSelect.find((el) => {
        if (data)
          el.id === data.id ? (el.style = "primary") : (el.style = "secondary");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.findThings {
  z-index: 15;
  border-radius: 5px;
  position: fixed;
  padding: 15px;
  top: 60px;
  width: 250px;
  height: auto;
  box-shadow: 0 0 5px gray;
  background-color: white;
  transition: 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.full {
    @media (max-width: 678px) {
      left: 10px;
    }
  }

  .tips-ads {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 25px;
    right: -270px;
    left: 265px;
    margin: 0 50px;
    bottom: 90px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 10px #888;
    transition: opacity 0.1s ease-in-out;
  }

  .tips-ads::after {
    content: "";
    position: absolute;
    top: 15%;
    left: -13px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }

  .category {
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
  }

  .toggle {
    background-color: white;
    content: "";
    position: absolute;
    height: 50px;
    width: 50px;
    right: -55px;
    top: 10px;
    cursor: pointer;
    border-radius: 0 5px 5px 0;

    &:hover {
      background-color: #ededed;
    }
  }

  .inputThings {
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
  .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
