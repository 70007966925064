<template>
  <div>
    <h2 class="mt-2 text-uppercase">
      {{ myProfile.name }}
    </h2>
    <h5 class="text-muted ">
      Admin Ship Owner
    </h5>
    <br>
    <ul
      id="pills-tab"
      class="nav nav-tabs mb-2"
      role="tablist"
    >
      <li
        class="nav-item"
        role="presentation"
      >
        <a
          class="nav-link px-5 active"
          data-toggle="pill"
          href="#pills-profile"
        >Company & Profile</a>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <a
          class="nav-link px-5"
          data-toggle="pill"
          href="#pills-security"
        >Security</a>
      </li>
      <!-- <li class="nav-item" role="presentation">
          <a class="nav-link px-5" data-toggle="pill" href="#pills-setting">Setting</a>
      </li> -->
    </ul>
    <div
      id="pills-tabContent"
      class="tab-content"
    >
      <div
        id="pills-profile"
        class="tab-pane fade show active"
      >
        <div class="row">
          <div class="col-md 12">
            <p class="small text-muted">
              *Mohon periksa kembali semua data anda, dan pastikan semua data sudah terisi dengan benar.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 d-flex flex-column align-content-between">
            <div class="form-group">
              <h4>User</h4>
            </div>

            <div class="form-group">
              <label>Full Name</label>
              <input
                v-if="statusEditProfile"
                v-model.trim="$v.myProfile.name.$model"
                type="text"
                class="form-control"
                placeholder="type your name"
                :class="{
                  'is-invalid': $v.myProfile.name.$error,
                  'is-valid': !$v.myProfile.name.$invalid,
                }"
                required
              >
              <p
                v-else
                class="text-muted"
              >
                {{ myProfile.name }}
              </p>
              <div class="invalid-feedback">
                Harus di isi.
              </div>
            </div>

            <div class="form-group">
              <label>WhatsApp Number</label>
              <input
                v-if="statusEditProfile"
                v-model.trim="$v.myProfile.detail.phone.$model"
                type="text"
                class="form-control"
                placeholder="type your whatsapp number"
                :class="{
                  'is-invalid': $v.myProfile.detail.phone.$error,
                  'is-valid': !$v.myProfile.detail.phone.$invalid,
                }"
                maxlength="15"
              >
              <p
                v-else
                :class="{'text-danger': !myProfile.detail.phone, 'text-muted': myProfile.detail.phone }"
              >
                {{ myProfile.detail.phone || msgVerification.msg }}
              </p>
              <div
                v-if="myProfile.detail.phone !== null && myProfile.detail.phone.length < 8"
                class="invalid-feedback"
              >
                Minimal 8 angka.
              </div>
              <div
                v-else-if="myProfile.detail.phone !== null && myProfile.detail.phone.length >= 8"
                class="invalid-feedback"
              >
                Harus angka.
              </div>
            </div>

            <div class="form-group">
              <label>E-mail</label>
              <p class="text-muted">
                {{ myProfile.email || 'Not Set' }}
              </p>
            </div>

            <div class="form-group">
              <label>Joined At</label>
              <p class="text-muted">
                {{ myProfile.registered_at.raw }}
              </p>
            </div>

            <div class="form-group">
              <label>Account Status</label>
              <p class="text-muted">
                {{ myProfile.status.name || 'Not Verified' }}
              </p>
            </div>
          </div>
          <div class="col-md-6 d-flex flex-column align-content-between">
            <div class="form-group">
              <h4>Company</h4>
            </div>

            <div class="form-group">
              <label>Company Name</label>
              <p class="text-muted">
                {{ myProfile.company.name || 'Not Set' }}
              </p>
            </div>

            <div class="form-group">
              <label>Phone Office</label>
              <input
                v-if="statusEditCompany"
                v-model.trim="$v.myProfile.company.phone.$model"
                type="text"
                class="form-control"
                placeholder="type your phone number"
                :class="{
                  'is-invalid': $v.myProfile.company.phone.$error,
                  'is-valid': !$v.myProfile.company.phone.$invalid,
                }"
                maxlength="15"
              >
              <p
                v-else
                :class="{'text-danger': !myProfile.company.phone, 'text-muted': myProfile.company.phone }"
              >
                {{ myProfile.company.phone || msgVerification.msg }}
              </p>
              <div
                v-if="myProfile.company.phone !== null && myProfile.company.phone.length < 6"
                class="invalid-feedback"
              >
                Minimal 6 angka.
              </div>
              <div
                v-else-if="myProfile.company.phone !== null && myProfile.company.phone.length >= 6"
                class="invalid-feedback"
              >
                Harus angka.
              </div>
            </div>

            <div class="form-group">
              <label>Address</label>
              <textarea
                v-if="statusEditCompany"
                v-model.trim="$v.myProfile.company.address.$model"
                class="form-control"
                :class="{
                  'is-invalid': $v.myProfile.company.address.$error,
                  'is-valid': !$v.myProfile.company.address.$invalid,
                }"
                required
              />
              <p
                v-else
                :class="{'text-danger': !myProfile.company.address, 'text-muted': myProfile.company.address }"
              >
                {{ myProfile.company.address || msgVerification.msg }}
              </p>
              <div class="invalid-feedback">
                Harus di isi.
              </div>
            </div>

            <div class="form-group">
              <label>Company Status</label>
              <p class="text-muted">
                Aktif
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-end">
            <button
              class="btn btn-primary"
              @click="update_profile"
            >
              {{ statusEditCompany ? 'Submit' : 'Update Profile' }}
            </button>
            <button
              v-if="statusEditCompany"
              class="btn btn-secondary"
              @click="load_profile"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div
        id="pills-security"
        class="tab-pane fade"
      >
        <div class="row">
          <div class="col-md 12">
            <p class="small text-muted">
              *Demi kenyamanan anda menggunakan aplikasi ini, mohon perlu di ingat dengan baik password yang anda tentukan.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-8 col-sm-12 d-flex flex-column align-content-between">
            <div class="form-group">
              <h4>Password</h4>
            </div>

            <div class="form-group">
              <label>Old Password</label>
              <input
                v-model="changePassword.old"
                type="password"
                class="form-control"
                placeholder="type your old password"
              >
            </div>

            <div class="form-group">
              <label>New Password</label>
              <input
                v-model="changePassword.new"
                type="password"
                class="form-control"
                placeholder="type your new password"
              >
            </div>

            <div class="form-group">
              <label>Confirm New Password</label>
              <input
                v-model="changePassword.confirm"
                type="password"
                class="form-control"
                placeholder="type your confirm new password"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-8 col-sm-12 d-flex justify-content-end">
            <button
              class="btn btn-primary"
              @click="update_password()"
            >
              Update
            </button>
            <button
              class="btn btn-secondary"
              @click="reset()"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="tab-pane fade" id="pills-setting">
          <div class="alert alert-info" role="alert">
              Coming soon!
          </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {required, maxLength, minLength, numeric} from "vuelidate/lib/validators"
import API from "../../plugins/axios";
import { SwalWarning, SwalSuccess } from "../../plugins/sweetalert2";
export default {
    data() {
        return {
            file: '',
            msgVerification:{
                msg: 'Harus di isi',
                color: 'text-danger'
            },
            menu: 'profile',
            passwordFieldType: "password",
            iconShowPassword: "fa fa-fw fa-eye",
            changePassword: {
                old: "",
                new: "",
                confirm: ""
            },
            statusEditProfile: false,
            statusEditCompany: false,

            myProfile: {
                id:'',
                name:'',
                email:'',
                company:{
                    name:'',
                    address:'',
                    phone:'',
                    verfied:'',
                    notes:'',
                },
                registered_at: Object,
                detail: {
                    phone: ''
                },
                status: {
                    name:''
                }
            }
        }
    },
    created () {
        this.load_profile();
    },
    validations:{
        myProfile:{
            name:{
                required
            },
            company:{
                phone:{
                    required,
                    numeric,
                    minLength: minLength(6),
                    maxLength: maxLength(15)
                },
                address:{
                    required,
                    maxLength: maxLength(1000)
                }
            },
            detail:{
                phone:{
                    required,
                    numeric,
                    minLength: minLength(8),
                    maxLength: maxLength(15)
                }
            }
        }
    },
    methods: {
        changeMenu (menu){
            this.menu = menu
        },
        load_profile() {
            let vi = this
            vi.statusEditProfile = false
            vi.statusEditCompany = false
            let endpoint = `user/profile`
            API.get(endpoint)
                .then((response) => {
                    const meta = response.data
                    const data = meta.data
                    if (meta.code === 200) {
                        vi.myProfile = data
                        vi.isSetting = true
                    }
                })
        },
        update_profile() {
            let vi = this
            if (vi.statusEditProfile) {
                let endpoint = `user/profile/update`
                let prm = {
                    name: vi.myProfile.name,
                    username: vi.myProfile.username,
                    country: vi.myProfile.detail.location.country.id,
                    state: vi.myProfile.detail.location.state.id,
                    city: vi.myProfile.detail.location.city.id,
                    phone: vi.myProfile.detail.phone,
                    alamat_perusahaan: vi.myProfile.company.address,
                    phone_perusahaan: vi.myProfile.company.phone
                };
                this.$v.$touch()
                if (this.$v.$invalid) {
                  SwalWarning ('Periksa kembali format atau kolom yang masih kosong!')
                }else{
                    API.patch(endpoint, prm)
                    .then((response) => {
                        const meta = response.data
                        if (meta.code === 200) {
                            vi.load_profile()
                            SwalSuccess (meta.message)
                        }
                    })
                    .catch((err) => {
                      SwalWarning (err.response.data.message)
                    })
                    .finally(()=>{
                        vi.statusEditProfile = false
                        vi.statusEditCompany = false
                        this.$v.$reset();
                    })
                }
            } else {
                vi.statusEditProfile = true
                vi.statusEditCompany = true
            }


        },
        update_password() {
            let vi = this;
            let endpoint = `user/update-password`
            let prm = {
                old_password: vi.changePassword.old,
                new_password: vi.changePassword.new,
                confirm_new_password: vi.changePassword.confirm,
            }
            API.patch(endpoint, prm)
            .then((response) => {
                const meta = response.data
                if (meta.code === 200) {
                    SwalSuccess (meta.message, ()=> vi.reset())
                }
            })
            .catch((err) => {
                SwalWarning (err.response.data.message)
            })
        },


        reset(){
            this.changePassword.old = ''
            this.changePassword.new = ''
            this.changePassword.confirm = ''
        },

        show_password() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
            this.iconShowPassword = this.iconShowPassword === "fa fa-fw fa-eye"
                ? "fa fa-fw fa-eye-slash"
                : "fa fa-fw fa-eye"
        },
    },
}
</script>

<style lang="scss">

</style>
