<template>
  <div>
    <div class="scroll-list-chatlist">
      <div v-if="totalRecords > 0" id="accordion">
        <div v-for="(list, index) in paginateDataInbox" :key="index">
          <div class="booking-list c-pointer">
            <div
              class="card card-chatlist w-100"
              :class="{ active: list.offer_id === isActive }"
              @click="openMessage(list.shipment_id, list.offer_id)"
            >
              <div class="row no-gutters">
                <div class="col-12">
                  <div
                    class="card body p-2"
                    @click="openCollapse(list.offer_id)"
                  >
                    <div class="row">
                      <div class="col-3 col-md-2 d-flex align-items-center">
                        <img
                          src="\images\image_user.png"
                          style="height: 60px; width: 60px"
                          alt="Image User"
                        />
                        <i
                          v-if="list.unread"
                          class="
                            notif-per-chat
                            fas
                            fa-comment-alt fa-lg
                            text-warning
                          "
                        >
                          <span class="num-chat txt-sm">{{ list.unread }}</span>
                        </i>
                      </div>
                      <div
                        class="
                          col-9 col-md-10s
                          d-flex
                          flex-column
                          align-items-start
                        "
                      >
                        <!-- <h5>{{ list.user_name }}</h5> -->
                        <div class="txt-md my-0 font-weight-bold">
                          <span
                            v-if="list.status.code === '1'"
                            class="badge badge-info"
                            >Approved</span
                          >
                          <span
                            v-if="list.status.code === '2'"
                            class="badge badge-danger"
                            >Lost Bid</span
                          >
                          <span
                            v-if="list.status.code === '3'"
                            class="badge badge-danger"
                            >Cancelled</span
                          >
                          <span
                            v-if="list.status.code === '0'"
                            class="badge badge-primary"
                            >Bidding</span
                          >
                          {{ list.user_name }}
                        </div>
                        <div class="text-muted txt-sm my-0">
                          {{ list.from }} - {{ list.destination }}
                        </div>
                        <div class="text-muted txt-sm my-0">
                          {{ list.cargo.name }} {{ list.cargo.qty }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <div class="alert alert-warning" role="alert">
          <i class="fa fa-info mr-2" /> No inboxes available
        </div>
      </div>
    </div>
    <div id="pagination" class="pages-tab">
      <div class="row no-gutters count-record">
        <div class="col-12">
          <div class="text-center">Total Records: {{ totalRecords }}</div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-4 pl-4">
          <button
            class="btn btn-secondary btn-sm"
            :disabled="currentPage == 0"
            @click="previousPage"
          >
            Previous
          </button>
        </div>
        <div class="col-4 text-center">
          <div class="pages">
            {{ currentPage + 1 }} of {{ pageCountInbox || 1 }}
          </div>
        </div>
        <div class="col-4 pr-4">
          <button
            class="btn btn-secondary btn-sm float-right"
            :disabled="currentPage >= pageCountInbox - 1"
            @click="nextPage"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatDate from "../../mixins/formatDate";
import formatChar from "../../mixins/formatChar";
import API from "../../plugins/axios";

export default {
  name: "Inbox",
  mixins: [formatDate, formatChar],
  data() {
    return {
      collapse: false,
      isActive: false,
      startPage: 0,
      lengthPage: 10,
      currentPage: 0,
      perPage: null,

      listData: [],
    };
  },
  computed: {
    paginateDataInbox() {
      const start = this.currentPage * this.lengthPage;
      const end = start + this.lengthPage;

      return this.listData.slice(start, end);
    },

    totalRecords() {
      return this.listData.length;
    },

    pageCountInbox() {
      let data = this.listData.length;
      let limit = this.lengthPage;
      return Math.ceil(data / limit);
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    setLoading() {
      this.$store.commit("SET_LOADING");
    },

    openCollapse: function (prm) {
      this.collapse = true;
      this.isActive = prm;
    },

    resetCollapse: function () {
      this.collapse = false;
    },

    previousPage() {
      this.resetCollapse();
      this.currentPage--;
    },

    nextPage() {
      this.resetCollapse();
      this.currentPage++;
    },

    activeTag(filter) {
      this.tags.find((el) => {
        el.filter === filter ? (el.active = true) : (el.active = false);
      });
    },

    async loadData() {
      const vm = this;
      vm.setLoading();
      const params = {
        user_type: "VS",
      };
      let endpoint = "chat";
      await API.get(endpoint, { params })
        .then((response) => {
          const meta = response.data;
          const data = meta.data;
          if (meta.code === 200) {
            vm.listData = data;
          }
        })
        .catch((error) => {
          console.log("Inbox", error.response.data.message);
        })
        .finally(() => {
          vm.setLoading();
        });
    },

    async openMessage(shipment, offer) {
      const payload = {
        shipment,
        offer,
      };
      this.$store.dispatch("moduleInbox/OPEN_MESSAGE", payload);
    },
  },
};
</script>

<style scoped></style>
