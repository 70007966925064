import { render, staticRenderFns } from "./AdsMarker.vue?vue&type=template&id=19ca8f1b&scoped=true&"
import script from "./AdsMarker.vue?vue&type=script&lang=js&"
export * from "./AdsMarker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ca8f1b",
  null
  
)

export default component.exports