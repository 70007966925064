<template>
  <b-modal
    id="offering-documents-modal"
    v-model="modalShow"
    :title="title"
    centered
    size="lg"
    hide-footer
  >
    <template
      v-if="!loading"
    >
      <!-- document form -->
      <div id="form-add-vessel" v-if="isEditAble" style="padding:0 !important"> 
        <div class="group-form" style="margin:0 !important;box-shadow:none;padding:10px">
          <div class="form-group row">
            <div class="col-lg-12">
              <small>File yang di upload harus memiliki ekstensi <mark>JPG</mark>,
                <mark>PNG</mark> atau <mark>PDF</mark>
              </small>
            </div>
          </div>

          <br>
          <div class="large-12 medium-12 small-12 cell">
            <div
              v-cloak
              class="upload-room"
              :class="{ active: hoverDropFiles }"
              @click="addFiles()"
              @drop.prevent="handleDrop"
              @dragover.prevent
              @dragenter="hoverDropFiles = true"
              @dragleave="hoverDropFiles = false"
            >
              <input
                id="files"
                ref="files"
                class="files-upload"
                type="file"
                multiple
                :disabled="actionDisabled"
                @change="handleClick"
              >
              <span class="title-files-upload">Select or drag files here</span>
            </div>
          </div>
          <br>
          <div class="large-12 medium-12 small-12 cell">
            <button
              class="btn btn-primary btn-sm"
              :disabled="actionDisabled"
              @click="addFiles()"
            >
              Add Files
            </button>
            <button
              v-if="files.length > 0"
              class="btn btn-warning btn-sm"
              :disabled="actionDisabled"
              @click="clearFiles()"
            >
              Clear Files
            </button>
            <span class="float-right total-size">Total files size : {{ convertFileSize(sizeFiles) }}</span>
          </div>
          <br>
          <div class="large-12 medium-12 small-12 cell">
            <ul
              v-for="(file, index) in files"
              :key="index"
              class="list-group"
            >
              <li class="list-group-item">
                <span class="badge badge-primary mr-3">
                  {{ index + 1 }}
                </span>
                {{ file.name }}
                <span class="badge badge-secondary ml-2">{{ convertFileSize(file.size) }}</span>
                <span
                  v-if="file.status ==='pending'"
                  class="remove-file"
                  @click="removeFile(index, file.size)"
                >&times;</span>
                <span
                  v-if="file.status ==='uploading'"
                  class="text-end"
                  style="float:right"
                ><i class="fa fa-upload"></i>&nbsp;Uploading...</span>
                <span
                  v-if="file.status ==='uploaded'"
                  class="text-end"
                  style="float:right;color:green"
                ><i class="fa fa-check"></i>&nbsp;Uploaded</span>
                <span
                  v-if="file.status ==='error'"
                  class="text-end"
                  style="float:right;color:red"
                ><i class="fa fa-info-circle"></i>&nbsp;Error</span>
              </li>
            </ul>
          </div>
          <div v-if="files.length > 0">
            <div class="border p-3 mt-3">
              <b>Documents Tag</b>
              <input v-model="label" type="text" @keyup="detectKeywordChange" placeholder="Kosongkan kolom ini apabila tidak diperlukan" class="form-control"/>
              <div style="position:relative">
                <div class="suggestion" v-if="suggestions.length > 0">
                  <ul>
                    <li v-for="(suggestion, index) in suggestions" :key="index" @click="chooseSuggestion(suggestion)">{{ suggestion }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <button :disabled="actionDisabled" @click="submit()" class="btn btn-primary mt-3" style="width:100%">
              <span v-if="!actionDisabled">Upload</span>
              <span v-if="actionDisabled">Uploading {{ files.length }} Files...</span>
            </button>
          </div>
        </div>
      </div>
      <!-- End document form -->

      <template v-if="documents.length > 0">
        <div class="row p-3">
          <div class="p-1" v-for="(document, index) in documents" :key="index">
            <div class="thumb_box">
              <div class="thumb_img">
                <img :src="document.full_thumbnail_path" />
              </div>
              
              <div class="thumb_detail" @click.self="openDocument(document)">
                <a :href="document.full_file_path" target="_blank">{{ document.original_file_name }}</a>
                <div>
                  <b>{{ document.label }}</b>
                </div>
                <div>
                  <b>{{ document.user_name }}</b>
                </div>
                <div>
                  {{ dateTimeFromNow(document.created_at) }}
                </div>
                <div v-if="document.is_authorize && isEditAble" class="text-center mt-2">
                  <button class="btn btn-danger btn-sm" style="margin:0" v-on:click.prevent="deleteFile(document.id)">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center text-muted">
          Belum ada Dokumen yang di <i>upload</i>.
        </div>
      </template>
     
    </template>
    <template
      v-if="loading"
    >
      <b-card>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <div class="d-flex">
          <b-skeleton width="50px"></b-skeleton>
          <b-skeleton width="50px" class="ml-2"></b-skeleton>
        </div>
      </b-card>
      <b-card class="mt-3">
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <div class="d-flex">
          <b-skeleton width="50px"></b-skeleton>
          <b-skeleton width="50px" class="ml-2"></b-skeleton>
        </div>
      </b-card>
    </template>
  </b-modal>
</template>
<script>
import API from '@/plugins/axios'
import SW from 'sweetalert2'
import { SwalSuccess, SwalConfirm, SwalInfo, SwalWarning } from '@/plugins/sweetalert2'
import moment from 'moment'
import { SwalFailed } from '../../plugins/sweetalert2'
import axios from '../../plugins/axios'

export default {
  mixins: [
  ],

  data () {
    return {
      suggestions: [],
      manifest_suggestions: [],
      offeringListId: null,
      submitLoading: false,
      loading: false,
      modalShow: false,
      title: 'Documents',
      label: '',
      documents: [],
      selectedFile : null,
      selectedImage : null,
      selectedPdf : null,
      errors: [],
      maxFileSize: 2 * 1000 * 1000,
      editId : null,
      isEditAble: true,

      files: [],
      actionDisabled: false,
      hoverDropFiles: false,
      sizeFiles: 0,
      maxFiles: 10,
      maxSizeFiles: 2097152,
      maxTotalSizeFiles: 20971520,
      uploadPrecentage: 0
    }
  },

  watch: {
    files: {
      handler: function (files) {
        //check if all status is uploaded or error
        let totalCompleted = 0;
        let totalError = 0;
        let totalLoading = 0;
        files.map(file => {
          if (file.status === 'uploaded' || file.status === 'error') {
            totalCompleted++;
            if(file.status === 'error'){
              totalError++;
            }
          }
          else if(file.status === 'uploading'){
            totalLoading++;
          }
        })

        //check if any files
        if(files.length > 0){
          //check if any loading
          if(totalLoading > 0){
            this.actionDisabled = true;
          }
          else if(totalCompleted === files.length){
            this.actionDisabled = false;

            let message = '';

            if(totalError > 0){
              message += `${totalError} file gagal di upload. `;
            }

            message += `${(totalCompleted - totalError)} file berhasil di upload.`;

            //show success message
            if(totalError === 0){
               SwalSuccess({
                title: 'Success',
                text: message
              })
            }
            else{
              SwalWarning({
                title: 'Warning',
                text: message
              })
            }

            //reset form
            this.reset();
          }
        }
        else{
          this.actionDisabled = false;
        }
      },
      deep: true
    }
  },

  methods: {
    openDocument(document){
      //open document
      window.open(document.full_file_path, '_blank');
    },
    handleFileChange(e){
      this.errors = []
      this.selectedImage = null
      this.selectedPdf = null

      const files = e.target.files
      //check size more than 1Kb
      if(files[0].size > this.maxFileSize){
        this.errors.push('File size larger than '+(this.maxFileSize / 1000 / 1000)+'MB')
        return;
      }

      //check file type
      const fileType = files[0].type
      if(fileType.indexOf('image') === -1 && fileType.indexOf('pdf') === -1){
        this.errors.push('Only .pdf .png .jpg .jpeg file type allowed')
        return;
      }

      //check if image
      if(fileType.indexOf('image') !== -1){
        const reader = new FileReader()
        reader.onload = (e) => {
          this.selectedImage = e.target.result
        }
        reader.readAsDataURL(files[0])
      }
      else if (fileType.indexOf('pdf') !== -1){
        const reader = new FileReader()
        reader.onload = (e) => {
          this.selectedPdf = {
            name: files[0].name,
            string: e.target.result,
          }
        }
        reader.readAsDataURL(files[0])
      }

      //check if error
      if(this.errors.length > 0){
        return;
      }else{
        this.selectedFile = files[0]
      }
    },

    viewPdf(){
      let pdfWindow = window.open("")
      pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='"+encodeURI(this.selectedPdf.string) + "'></iframe>"
      )
    },

    dateTimeFromNow(datetime){
      return moment(datetime).fromNow()
    },

    reset(){
      this.editId = null
      this.loading = false
      this.errors = []
      this.selectedFile = null
      this.selectedImage = null
      this.selectedPdf = null
      this.files = []
      this.actionDisabled = false
      this.hoverDropFiles = false
      this.sizeFiles = 0
      this.label = ''
    },

    show(id_offering_list, isEditAble){
      this.reset()

      this.offeringListId = id_offering_list
      this.isEditAble = isEditAble
      this.modalShow = true;

      this.getSharedList(true)
    },

    async getSharedList(showLoading){
      const vm = this

      if(showLoading){
        vm.loading = true
      }

      const endpoint = `/transaction/shipment/offering-document/read?offering_list_id=${this.offeringListId}`

      try{
        const res = await API.get(endpoint);
        if(res.data.code === 200){
          const data = res.data.data
          vm.documents = data.reverse()

          //get manifest
          await vm.getManifestData(res.data.shipmentId);
        }
      }catch(err){
        SwalWarning({
          title: 'Warning',
          text: err.message
        })
      }

      if(showLoading){
        vm.loading = false
      }
    },

    async getManifestData(shipmentId){
      const vm = this

      const endpoint = `/transaction/shipment/shipment-manifest/read?shipment_id=${shipmentId}`

      try{
        const res = await API.get(endpoint);
        if(res.data.code === 200){
          const data = res.data.data;
          let suggestions = [];
          suggestions.push(data.reference_number);

          data.manifest.map(perData => {
            suggestions.push(perData.serial_number);
            suggestions.push(perData.item);
          });

          vm.manifest_suggestions = suggestions;
        }
      }catch(err){
        //console.log(err);
      }
    },

    submit(){
      const vm = this

      vm.files.map(file => {
        //change status
        file.status = 'uploading'
        vm.files = Object.assign([], vm.files)

        //start upload
        vm.add(file, () => {
          //change status
          file.status = 'uploaded'
          vm.files = Object.assign([], vm.files)
        }, () => {
          //change status
          file.status = 'error'
          vm.files = Object.assign([], vm.files)
        });
      })
    },

    add(file, successCallback, errorCallback){
      const vm = this

      const endpoint = `/transaction/shipment/offering-document/create`

      let formFiles = new FormData();
      formFiles.append("offering_list_id", vm.offeringListId);
      formFiles.append("document_file", file);
      formFiles.append("label", vm.label);

      //try{
      const token = localStorage.getItem('token') || null
      axios.post(
        (process.env.VUE_APP_API_URL || '') + endpoint,
        formFiles,{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
            "Content-Type": "multipart/form-data",
          }
        }
      ).then(response => {
        successCallback()
        vm.getSharedList()
      }).catch(err => {
        const dataRes = err.response.data
        errorCallback(dataRes.message)
      })
    },

    edit(){
      const vm = this
      vm.submitLoading = true

      const endpoint = `/transaction/shipment/offering-document/update`

      let formFiles = new FormData();
      formFiles.append("id", vm.editId);
      formFiles.append("document_file", vm.selectedFile);

      API.post(endpoint, formFiles, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function(progressEvent) {

        }.bind(this),
      })
        .then((response) => {
          const meta = response.data
          if (meta.code === 200) {
            const results = meta.data
            
            SwalSuccess("Berhasil Mengubah Dokumen.")

            vm.submitLoading = false

            vm.reset()

            vm.getSharedList()
          }    
        })
        .catch((error) => {
          vm.submitLoading = false

          SwalFailed(error.response.data.message)
        })
    },

    deleteFile(id){
      const vm = this

      SwalConfirm('Anda yakin ingin menghapus Dokumen ?', () => {
        const endpoint = `/transaction/shipment/offering-document/delete?id=${id}`

        API.delete(endpoint)
          .then((response) => {
            const meta = response.data
            if (meta.code === 200) {
              SwalSuccess("Berhasil Menghapus Dokumen")

              vm.getSharedList()
            }
          })
          .catch((error) => {
            if(error.response.status === 400 || error.response.status === 404){
              SwalInfo(error.response.data.message)
            }
          })
      })
    },

    cancelEdit(){
      this.reset()
      var elem = document.getElementById("document-file");
      elem.value = null;
    },

    editFile(id){
      this.editId = id;

      var elem = document.getElementById("document-file");
      if(elem && document.createEvent) {
          var evt = document.createEvent("MouseEvents");
          evt.initEvent("click", true, false);
          elem.dispatchEvent(evt);
      }
    },

    // Convert file size
    convertFileSize(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      var k = 1024,
          dm = decimals <= 0 ? 0 : decimals || 2,
          sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    // Adds a file
    addFiles() {
      this.$refs["files"].click();
      // console.log(this.files);
    },

    handleDrop (e) {
      const uploadedFiles = e.target.files || e.dataTransfer.files
      this.handleUpload(uploadedFiles)
    },

    handleClick () {
      const uploadedFiles = this.$refs.files.files
      this.handleUpload(uploadedFiles)
    },

    handleUpload (event) {
      const uploadedFiles = event
      const vm = this

      let tempSize = vm.sizeFiles

      //Count total file size and last size of the file
      for (let i = 0; i < uploadedFiles.length; i++) {

        if (uploadedFiles[i].size <= vm.maxSizeFiles) {
          // check the file type
          if (vm.fileTypeValidation(uploadedFiles[i].type)) {
            tempSize = tempSize + uploadedFiles[i].size
          }
        }
      }

      if (uploadedFiles.length + vm.files.length > vm.maxFiles) {
        // Total files rejected if more than 10
        SwalWarning ({ title: 'File rejected!', text: 'File tidak boleh lebih dari 10' }, () => vm.$refs.files.value = '')

      } else if (vm.sizeFiles > vm.maxTotalSizeFiles) {
        // Total file size rejected if more than 20mb
        SwalWarning ({ title: 'File rejected!', text: 'Maksimal keseluruhan files maksimal \'20MB\'' }, () => vm.$refs.files.value = '')
      } else {
        let x = Boolean
        //Adds the uploaded file to the files array
        let errorMessage = '';
        for (let i = 0; i < uploadedFiles.length; i++) {
          const fileNameStr = uploadedFiles[i].name
          // check the file type
          if (vm.fileTypeValidation(uploadedFiles[i].type)) {
            if (uploadedFiles[i].size <= vm.maxSizeFiles) {
              x = true
            } else {
              errorMessage += '<div class="alert alert-warning">File <b>'+fileNameStr+'</b> lebih dari \'2MB\'</div>';
              //SwalWarning ({ title: 'File rejected!', text: 'File size lebih dari \'2MB\'' })
              //vm.$refs.files.value = '' // reset file input
              x = false
              //break
            }
          } else {
            errorMessage += '<div class="alert alert-warning">File <b>'+fileNameStr+'</b> harus memiliki extensi \'pdf/jpg/png\'</div>';
            // Files rejected if the files formated not equal PNG/JPG/PDF
            //SwalWarning ({title: 'File rejected!', text:'Semua file harus memiliki extensi \'PDF/JPG/PNG\''})
            //vm.$refs.files.value = '' // reset file input
            //break
          }
        }

        if(errorMessage !== ''){
          //there is an error
          SW.fire({
            title: 'File rejected!',
            icon: 'info',
            html: errorMessage,
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText:
              'Tutup'
          })

          vm.$refs.files.value = '' // reset file input
        }

        if (x) {
          // finall
          vm.sizeFiles = tempSize
          for (let j = 0; j < uploadedFiles.length; j++) {
            uploadedFiles[j].status = 'pending'
            vm.files.push(uploadedFiles[j])
          }
        }
      }
    },

    // Removes a select file the user has uploaded
    removeFile(key, size) {
      this.$refs.files.value = '' // reset file input
      Array.prototype.slice.call(this.$refs.files, key, 1)
      this.files.splice(key, 1)
      this.sizeFiles = this.sizeFiles - size
    },
    // removeFile(file) {
    //   this.files = this.files.filter(f => {
    //     return f != file;
    //   });
    // },

    clearFiles() {
      this.$refs.files.value = ''
      this.files = []
      this.sizeFiles = 0
      this.uploadPrecentage = 0
      this.label = ''
    },

    fileTypeValidation (val) {
      if (val === 'image/jpeg' || val === 'image/png' || val === 'application/pdf') return true
      else return false
    },

    chooseSuggestion(keyword){
      this.label = keyword;
      this.suggestions = [];
    },

    detectKeywordChange(){
      if(this.label.length > 1){
        this.suggestions = this.manifest_suggestions.filter(item => {
          return item.toLowerCase().indexOf(this.label.toLowerCase()) > -1;
        });
      }else{
        this.suggestions = [];
      }
    }
  }
}
</script>
<style lang="scss">
.img-small{
  height: 100px !important;
}
.suggestion{
  position: absolute;
  width:100%;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 1;
}

.suggestion ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestion ul li{
  padding: 5px;
  cursor: pointer;
}

.suggestion ul li:hover{
  background: #f2f2f2;
}
</style>
