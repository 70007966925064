<template>
  <div>
    <b-modal
      id="modal-advertisement"
      title="List Products"
      centered
      size="lg"
      hide-footer
    >
      <template #default>
        <div
          v-for="(item, a) in listData"
          :key="a"
          class="company-tab"
        >
          <b-card
            class="border-0"
            :title="`#${item.name}`"
            :sub-title="`Category ${item.category.name}`"
          >
            <b-tabs
              content-class="mt-3"
              fill
            >
              <b-tab
                title="Photo"
                active
              >
                <div class="text-center">
                  <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="4000"
                    controls
                    no-animation
                    indicators
                    background="#1c1c1c"
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                  >
                    <b-carousel-slide
                      v-for="(photo, b) in item.product_photo"
                      :key="b"
                    >
                      <template #img>
                        <a
                          :href="photo.url"
                          target="_blank"
                        >
                          <img
                            class="img-brochures"
                            width="1024"
                            height="480"
                            :src="photo.url"
                            alt="image slot"
                          >
                        </a>
                      </template>
                    </b-carousel-slide>
                  </b-carousel>
                </div>
              </b-tab>
              <b-tab title="Brochures">
                <div class="container-product">
                  <b-list-group>
                    <a
                      v-for="(docs, c) in item.product_docs"
                      :key="c"
                      :href="docs.url"
                      target="_blank"
                    >
                      <b-list-group-item
                        class="d-flex align-items-center justify-content-between"
                        button
                      >
                        <div>
                          <img
                            class="brochures mr-2"
                            src="../../assets/icons/pdf.png"
                            alt="PDF"
                          >
                          {{ docs.name }}
                        </div>
                        <i class="fa fa-download" />
                      </b-list-group-item>
                    </a>
                  </b-list-group>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import API from '@/plugins/axios'
export default {
    props: {
        data: {
            type: Object,
            default: Object
        }
    },
    data () {
        return {
            listData: [],
            mainProps: {
                center: true,
                fluidGrow: true,
                blank: true,
                blankColor: '#bbb',
                width: 600,
                height: 400,
                class: 'my-5'
            },
            slide: 0,
            sliding: null
        }
    },
    mounted () {

    },
    watch: {
        data () {
            this.loadData()
        }
    },
    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        loadData () {
            console.log(this.data)
            const params = {
                ads_company_branch: this.data.id_branch,
                ads_category: this.data.category.id
            }
            API.get('publicity/product/filter', {params})
                .then((res)=> {
                    const data = res.data.data
                    this.listData = data
                })
                .catch((error) => {
                    console.log("Vessel", error.response.data.message)
                })
        }
    },
}
</script>

<style scoped>
  .brochures {
    max-width: 25px;
  }

  .img-brochures {
    height: 520px;
    width: auto;
  }

  .company-tab {
    min-height: 520px;
    height: auto;
  }
</style>