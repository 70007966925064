<template>
  <b-modal
    v-model="show"
    :content-class="contentClass"
    :dialog-class="dialogClass"
    :footer-class="footerClass"
    :header-class="headerClass"
    cancel-disabled
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    ok-disabled
    size="sm"
  >
    <div class="loader" />
  </b-modal>
</template>

<script>
export default {
  name: "LoadingDialog",
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    // todo: component data
    return {
      headerClass: "header",
      contentClass: "content",
      footerClass: "footer",
      dialogClass: "loading-dialog",
    }
  }
}
</script>

<style lang="scss">
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loading-dialog {
  z-index: 2147483646;

  .header {
    //display: none !important;
  }

  .content {
    border-radius: 0;
    border: none;
    text-align: center;
    background-color: transparent;

    .loader {
      position: relative;
      text-align: center;
      margin: 15px auto 35px auto;
      z-index: 2147483647;
      display: block;
      width: 80px;
      height: 80px;
      border: 10px solid rgba(247, 247, 247, 0.1);
      border-radius: 50%;
      border-top-color: white;
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
    }

    .loader-txt {
      p {
        font-size: 13px;
        color: #666;

        small {
          font-size: 11.5px;
          color: #999;
        }
      }
    }
  }

  .footer {
    //display: none !important;
  }
}
</style>
