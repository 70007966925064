<template>
  <div>
    <div class="row no-gutters">
      <div class="col-2 tab-tag pl-4 pr-0">
        <p class="mt-2 mb-2">Filter :</p>
      </div>
      <div class="col-10 tab-tag pl-0">
        <button
          v-for="(tag, index) in tags"
          :key="index"
          class="btn btn-outline-primary btn-sm"
          :class="{ active: tag.active }"
          @click="loadData(tag.filter)"
        >
          {{ tag.name }}
        </button>
      </div>
    </div>

    <div v-if="listData.length > 0" id="accordion" class="scroll-list-booking">
      <div
        v-for="(list, index) in listData"
        :key="index"
        class="booking-list c-pointer"
      >
        <div
          class="card card-list w-100"
          :class="{ active: isActive === list.id }"
        >
          <div class="card-body p-2">
            <div class="row">
              <div
                class="col-7 col-md-9"
                data-toggle="collapse"
                :data-target="'#show' + index"
                aria-expanded="true"
                @click="openCollapse(list.id, list)"
              >
                <span class="badge badge-pill" :class="`badge-${list.color}`">{{
                  list.status_name
                }}</span>
                <span v-if="list.current_offering_status !== null" class="ml-2 badge badge-pill badge-light">
                  <img :src="list.current_offering_status.full_file_path" class="offering-status-icon"/>
                  {{ list.current_offering_status.name }}
                </span>
                <span
                  v-if="list.completed_at || list.confirmed_at"
                  class="badge badge-warning badge-pill ml-2"
                  >Waiting complete confirmation</span
                >
              </div>
              <div class="col-5 col-md-3">
                <div class="btn-group">
                  <button
                    class="btn btn-light btn-sm dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Action
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <button
                      v-if="list.status == 'Bidding'"
                      class="btn dropdown-item btn-sm text-danger"
                      @click="cancelBooking(list.shipment, list.id)"
                    >
                      Cancel
                    </button>
                    <button
                      v-if="list.status == 'On Progress'"
                      class="btn dropdown-item btn-sm text-success"
                      @click="completeBooking(list.shipment, list.id)"
                    >
                      <span v-if="!list.completed_at && list.confirmed_at"
                        >Confirm to complete</span
                      >
                      <span v-else-if="!list.completed_at && !list.confirmed_at"
                        >Set to complete</span
                      >
                    </button>
                    <button
                      v-if="list.status == 'On Progress' || list.status == 'Bidding'"
                      class="btn dropdown-item btn-sm"
                      @click="openManifest(list.shipment)"
                    >
                      <span>Manifest</span>
                    </button>
                    <button
                      v-if="list.status == 'On Progress' || list.status == 'Bidding'"
                      class="btn dropdown-item btn-sm"
                      @click="openOfferingDocuments(list)"
                    >
                      <span>Documents</span>
                    </button>
                    <button
                    v-if="list.status == 'On Progress'"
                    class="btn dropdown-item btn-sm"
                    @click="openOfferingStatus(list)"
                    >
                      <span>Status</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-toggle="collapse"
              :data-target="'#show' + index"
              aria-expanded="true"
              @click="openCollapse(list.id, list)"
            >
              <div class="txt-md font-weight-bold">
                {{ hide_pelabuhan(list.from) }}
                <i class="fas fa-arrow-right" />
                {{ hide_pelabuhan(list.destination) }}
              </div>

              <div class="txt-md">
                {{ list.vessel }} &#8226;
                <span v-if="list.tugboat" class="text-muted">{{
                  list.tugboat
                }}</span>
                <span v-else class="text-muted">No tugboat</span>
              </div>

              <div class="txt-sm text-muted">
                {{ list.laycan }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="collapse == true"
          :id="'show' + index"
          class="collapse"
          data-parent="#accordion"
        >
          <div class="card-body pl-0 pr-0">
            <div class="row txt-md font-weight-bold">
              <div class="col-6">Cargo type</div>
              <div class="col-6">Quantity</div>
            </div>

            <div class="row txt-sm text-muted">
              <div class="col-6">
                {{ list.cargo }}
              </div>
              <div class="col-6">
                {{ list.qty }}
              </div>
            </div>

            <div class="row txt-md font-weight-bold">
              <div class="col-6">Loading Duration</div>
              <div class="col-6">Discharging Duration</div>
            </div>

            <div class="row txt-sm text-muted">
              <div class="col-6 text-info">{{ list.loading_duration }} Day</div>
              <div class="col-6 text-info">
                {{ list.discharge_duration }} Day
              </div>
            </div>

            <div class="row txt-md font-weight-bold">
              <div class="col-6">Notes</div>
            </div>

            <div class="row txt-sm text-muted">
              <div class="col-12">
                <div v-if="list.notes" style="white-space: pre-line">
                  {{ list.notes }}
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <div class="alert alert-warning" role="alert">
        <i class="fa fa-info mr-2" /> No records available
      </div>
    </div>

    <div id="pagination" class="pages-tab">
      <div class="row no-gutters count-record">
        <div class="col-12">
          <div class="text-center">Total Records: {{ totalRecords }}</div>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-4 pl-4">
          <button
            class="btn btn-secondary btn-sm"
            :disabled="currentPage <= 1"
            @click="previousPage"
          >
            Previous
          </button>
        </div>
        <div class="col-4">
          <div class="pages">{{ currentPage }} of {{ perPage || 1 }}</div>
        </div>
        <div class="col-4 pr-4">
          <button
            class="btn btn-secondary btn-sm float-right"
            :disabled="currentPage >= perPage"
            @click="nextPage"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../plugins/axios";
import formatDate from "../../mixins/formatDate";
import formatChar from "../../mixins/formatChar";
import {
  SwalInfo,
  SwalSuccess,
  SwalWarning,
  SwalConfirm,
} from "../../plugins/sweetalert2";

export default {
  mixins: [formatDate, formatChar],
  data() {
    return {
      collapse: false,
      isActive: false,
      startPage: 0,
      lengthPage: 10,
      currentPage: 1,
      perPage: null,

      listData: Array,
      totalRecords: null,
      filter: "",
      tags: [
        { name: "All", filter: "", active: true },
        { name: "Bidding", filter: 0, active: false },
        { name: "Approved", filter: 1, active: false },
      ],

      cancelLoading: true
    };
  },

  mounted() {
    this.loadData("");
  },

  methods: {
    openCollapse(prm, data) {
      this.collapse = true;
      this.isActive = prm;

      const laycan = data.laycan.split(" - ");

      const startDate = laycan[0].split("/");
      const endDate = laycan[1].split("/");

      const startDateFormat =
        startDate[2] + "-" + startDate[1] + "-" + startDate[0];
      const endDateFormat = endDate[2] + "-" + endDate[1] + "-" + endDate[0];

      const currentCargo = {
        only_view: true,
        cargo: {
          name: data.cargo,
        },
        qty: {
          amount: data.qty,
          type: "",
        },
        laycan: {
          from: startDateFormat,
          to: endDateFormat,
        },
        port: {
          from: {
            name: data.from,
            location: {
              latitude: data.port_from_latitude,
              longitude: data.port_from_longitude,
            },
          },
          destination: {
            name: data.destination,
            location: {
              latitude: data.port_destination_latitude,
              longitude: data.port_destination_longitude,
            },
          },
        },
      };

      this.$store.commit("SET_CURRENT_CARGO", currentCargo);
    },
    setLoading() {
      this.$store.commit("SET_LOADING");
    },

    showDetail(data) {
      this.$router.push({
        name: "detail-vessel",
        params: {
          id: data,
        },
      });
    },

    previousPage() {
      this.startPage = this.startPage - this.lengthPage;
      this.currentPage -= 1;
      this.loadData(this.filter);
    },

    nextPage() {
      this.startPage = this.startPage + this.lengthPage;
      this.currentPage += 1;
      this.loadData(this.filter);
    },

    activeTag(filter) {
      this.tags.find((el) => {
        el.filter === filter ? (el.active = true) : (el.active = false);
      });
    },

    async loadData(filter) {
      const vm = this;

      vm.filter = filter;
      vm.activeTag(vm.filter);
      vm.setLoading();

      const params = {
        "columns[0][data]": "0",
        "columns[0][name]": "id",
        "columns[0][searchable]": "true",
        "columns[0][orderable]": "true",
        "columns[0][search][value]": "",
        "columns[0][search][regex]": "false",
        "order[0][column]": "0",
        "order[0][dir]": "desc",
        start: vm.startPage,
        filter: filter,
        length: vm.lengthPage,
      };

      let endpoint = "transaction/shipment/offers/on-going";
      await API.get(endpoint, { params })
        .then((response) => {
          const meta = response.data;
          if (meta.code === 200) {
            vm.totalRecords = meta.recordsFiltered;
            const data = meta.data;
            vm.listData = [];
            data.forEach((element) => {
              let statusData = element.status;

              if (statusData === "Expired") {
                statusData = "On Progress";
              }

              vm.listData.push({
                id: element.id,
                shipment: element.shipment,
                cargo: element.cargo,
                completed_at: element.completed_at,
                confirmed_at: element.confirmed_at,
                destination: element.destination,
                discharge_duration: element.discharge_duration,
                from: element.from,
                laycan: element.laycan,
                loading_duration: element.loading_duration,
                notes: element.notes,
                notes_vessel: element.notes_vessel,
                qty: element.qty,
                status: element.status,
                color:
                  element.status === "Bidding"
                    ? "primary"
                    : element.status === "On Progress"
                    ? "info"
                    : "secondary",
                status_name:
                  element.status === "Bidding"
                    ? "Bidding"
                    : element.status === "On Progress"
                    ? "Approved"
                    : "-",
                tugboat: element.tugboat,
                vessel: element.vessel,
                port_from_latitude: element.port_from_latitude,
                port_from_longitude: element.port_from_longitude,
                port_destination_latitude: element.port_destination_latitude,
                port_destination_longitude: element.port_destination_longitude,
                current_offering_status: element.current_offering_status,
              });
            });
          }
        })
        .catch((error) => {
          console.log("Booking Ongoing", error.response.data.message);
        })
        .finally(() => {
          vm.setLoading();
          vm.perPage = Math.ceil(vm.totalRecords / vm.lengthPage);
        });
    },

    completeBooking(id_shipment, id_offer) {
      const vm = this;
      let endpoint = "transaction/shipment/set-complete";
      let values = {
        shipment: id_shipment,
        offer: id_offer,
      };
      SwalConfirm("Anda yakin ingin menyelesaikan shipment ini?", async () => {
        await API.patch(endpoint, values)
          .then(async (response) => {
            const meta = response.data;
            if (meta.code === 200) {
              SwalSuccess(
                response.data.message,
                async () => await vm.loadData("")
              );
            }
          })
          .catch((error) => {
            SwalWarning(error.response.data.message);
          });
      });
    },

    cancelBooking(id_shipment, id_offer) {
      const vi = this;
      let endpoint = "transaction/shipment/offers/cancel";
      let values = {
        shipment: id_shipment,
        id: id_offer,
      };
      SwalConfirm("Anda yakin ingin cancel booking ini ?", async () => {
        vi.setLoading();
        await API.patch(endpoint, values)
          .then(async (response) => {
            vi.setLoading();

            const meta = response.data;
            if (meta.code === 200) {
              SwalSuccess(meta.message, async () => {
                await vi.loadData("");
              });
            }
          })
          .catch((error) => {
            vi.setLoading();

            SwalWarning(error.response.data.message);
          });
      });
    },

    async openOfferingDocuments(offeringList) {
       this.$store.commit("SET_SHOW_OFFERING_LIST_DOCUMENTS", {
         offerId : offeringList.id,
         editable: true,
         openDate : new Date(),
       });
    },

    async openOfferingStatus(offeringList) {
       this.$store.commit("SET_SHOW_OFFERING_STATUS", {
         offerId : offeringList.id,
         editable: true,
         openDate : new Date(),
       });
    },

    async openManifest(shipmentId) {
       this.$store.commit("SET_SHOW_MANIFEST", {
         shipmentId : shipmentId,
         editable: true,
         openDate : new Date(),
       });
    },
  },
};
</script>

<style>
.offering-status-icon{
  width:20px;
  margin-right:10px;
}
</style>
