<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="showModal"
      hide-footer
      scrollable
      size="xl"
      :title="title"
      @hide="onCancel"
    >
      <div v-if="!loadingReport">
         <!-- graph -->
         <div class="row mb-4">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
            <div class="text-center">Wear</div>
            <LineChartGenerator
              :chart-data="wearChartData"
              chart-id="wearChartData"
              dataset-id-key="wear"
              :height="300"
              :chart-options="plugins"
            />
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
            <div class="text-center">Contamination</div>
            <LineChartGenerator
              :chart-options="plugins"
              :chart-data="contaminationChartData"
              chart-id="contaminationChartData"
              dataset-id-key="contamination"
              :height="300"
            />
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
            <div class="text-center">Oil Condition</div>
            <LineChartGenerator
              :chart-options="plugins"
              :chart-data="oilConditionChartData"
              chart-id="oilConditionChartData"
              dataset-id-key="oil"
              :height="300"
            />
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
            <div class="text-center">Viscosity at 100&deg;C</div>
            <LineChartGenerator
              :chart-options="plugins"
              :chart-data="viscosityChartData"
              chart-id="viscosityChartData"
              dataset-id-key="viscosity"
              :height="300"
            />
          </div>
         </div>
       
        <!-- graph -->

        <div style="display:flex">
          <div style="padding-right:0px;margin-top:0px;display:flex">
            <div class="report_data_content">
              <template v-for="(data, index) in form">
                <div :key="index" :style="'height:' + 43.7 * data.inputs.length+'px'">
                  {{ data.category }}
                </div>
              </template>
            </div>
            <div class="report_data_content">
              <template v-for="(data, index) in form">
                <template v-for="(input, childIndex) in data.inputs" >
                  <div :key="index+'_'+childIndex">
                    {{ input.label }}
                  </div>
                </template>
              </template>
            </div>
          </div>
          <div style="padding-left:0px;margin-top:1px;width:800px;margin:0;overflow-x:scroll;">
            <template v-if="!loadingReport">
              <div :style="'width:'+(reports.length * 200)+'px;display:flex'">
                <div class="report_data_content" v-for="(data, index) in reports" :key="index">
                  <div v-html="renderStatus(data.report_data.report_status)"></div>
                  <div>{{ data.report_data.sample_id }}</div>
                  <div>{{ data.report_data.tested_lubricant }}</div>
                  <div>{{ renderDate(data.report_data.sampled) }}</div>
                  <div>{{ renderDate(data.report_data.reported) }}</div>
                  <div>{{ data.report_data.equipment_age }}</div>
                  <div>{{ data.report_data.equipment_uom }}</div>
                  <div v-html="renderStatus(data.report_data.contamination_rating)"></div>
                  <div v-html="renderStatus(data.report_data.equipment_rating)"></div>
                  <div v-html="renderStatus(data.report_data.lubricant_rating)"></div>
                  <div>{{ data.report_data.cr }}</div>
                  <div>{{ data.report_data.pb }}</div>
                  <div>{{ data.report_data.fe }}</div>
                  <div>{{ data.report_data.cu }}</div>
                  <div>{{ data.report_data.sn }}</div>
                  <div>{{ data.report_data.mo }}</div>
                  <div>{{ data.report_data.ni }}</div>
                  <div>{{ data.report_data.k }}</div>
                  <div>{{ data.report_data.na }}</div>
                  <div>{{ data.report_data.si }}</div>
                  <div>{{ data.report_data.b }}</div>
                  <div>{{ data.report_data.ca }}</div>
                  <div>{{ data.report_data.p }}</div>
                  <div>{{ data.report_data.zn }}</div>
                  <div>{{ data.report_data.mg }}</div>
                  <div>{{ data.report_data.pq_index }}</div>
                  <div>{{ data.report_data.st }}</div>
                  <div>{{ data.report_data.oxi }}</div>
                  <div>{{ data.report_data.nit }}</div>
                  <div>{{ data.report_data.sul }}</div>
                  <div>{{ data.report_data.fuel_content }}</div>
                  <div>{{ data.report_data.v100 }}</div>
                  <div>{{ data.report_data.tbn }}</div>
                  <div>{{ data.report_data.water }}</div>
                  <div style="padding-top:5px">
                    <b-button size="sm" v-if="typeof data.report_data.comments !== 'undefined' && data.report_data.comments !== null && data.report_data.comments !== ''" @click="viewComment(data.report_data.comments)">
                      Comments
                    </b-button>   
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center md:w-1/3">
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
                <vs-progress indeterminate color="primary">primary</vs-progress>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <!-- loading spinner -->
         <b-spinner
          type="grow"
          variant="primary"
          class="mb-3"
        />
      </div>
    </b-modal>

    <b-modal
      v-model="showCommentModal"
      hide-footer
      scrollable
      size="sm"
      title="Comment"
    >
      <pre>
{{ selectedComment }}
      </pre>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import API from '../../plugins/axios'
import formatDate from '../../mixins/formatDate'

import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'EngineHealthReportModal',
  mixins: [ formatDate ],
  props: {
    data: {
      type: String,
      default: String
    },
    plugins : {
      type: Object,
      default: () => {
        return {
          plugins : {
            legend: {
              position:'bottom',
              labels: {
                boxWidth: 10,
                boxHeight: 10
              }
            }
          }
        }
      }
    }
  },
  components: {
    LineChartGenerator
  },
  data () {
    return {
      showModal: false,
      showCommentModal: false,
      isLoading: false,
      title: 'Engine Report',
      dataDetail: Object,
      notes: '',
      reports : [],
      loadingReport: false,
      id : null,

      selectedComment : null,

      form : [
        {
          category : "Sample Info",
          inputs : [
            {
              label : "Report Status",
            },
            {
              label : "Sample ID",
            },
            {
              label : "Tested Lubricant",
            },
            {
              label : "Sampled",
            },
            {
              label : "Reported",
            },
            {
              label : "Equipment Age",
            },
            {
              label : "Equipment UOM",
            },
            {
              label : "Contamination Rating",
            },
            {
              label : "Equipment Rating",
            },
            {
              label : "Lubricant Rating",
            },
          ]
        },
        {
          category : "Wear (ppm)",
          inputs : [
            {
              label : "Cr (Chromium)",
            },
            {
              label : "Pb (Lead)",
            },
            {
              label : "Fe (Iron)",
            },
            {
              label : "Cu (Copper)",
            },
            {
              label : "Sn (Tin)",
            },
            {
              label : "Mo (Molybdenum)",
            },
            {
              label : "Ni (Nickel)",
            }
          ]
        },
        {
          category : "Contamination (ppm)",
          inputs : [
            {
              label : "K (Potassium)",
            },
            {
              label : "Na (Sodium)",
            },
            {
              label : "Si (Silicon)",
            }
          ]
        },
        {
          category : "Additive (ppm)",
          inputs : [
            {
              label : "B (Boron)",
            },
            {
              label : "Ca (Calcium)",
            },
            {
              label : "P (Phosphorus)",
            },
            {
              label : "Zn (Zinc)",
            },
            {
              label : "Mg (Magnesium)",
            }
          ]
        },
        {
          category : "Oil Condition",
          inputs : [
            {
              label : "PQ Index",
            },
            {
              label : "ST (Soot)",
            },
            {
              label : "OXI (Oxidation)",
            },
            {
              label : "NIT (Nitration)",
            },
            {
              label : "SUL (Sulfur Products)",
            },
            {
              label : "Fuel Content (Vol.%)",
            },
            {
              label : "V100 (Viscosity at 100 C)",
            },
            {
              label : "TBN (Total Base Number)",
            },
            {
              label : "Water (Vol.%)",
            }
          ]
        }
      ],

      chartOptions : {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          position: 'bottom'
        },
      },

      wearChartData: {
        labels : [],
        datasets : [],
      },

      contaminationChartData: {
        labels : [],
        datasets : [],
        chartOptions : {
          responsive: true,
          maintainAspectRatio: true
        }
      },

      oilConditionChartData: {
        labels : [],
        datasets : [],
        chartOptions : {
          responsive: true,
          maintainAspectRatio: true
        }
      },

      viscosityChartData: {
        labels : [],
        datasets : [],
        chartOptions : {
          responsive: true,
          maintainAspectRatio: true
        }
      }
    }
  },
  methods: {
    renderDate(date){
      return moment(date).format('DD-MMM-YYYY')
    },
    viewComment(comment){
      this.selectedComment = comment
      this.showCommentModal = true
    },
    renderStatus(status){
      if(status == 'Normal'){
        return '<span class="text-success">'+status+'</span>'
      }else if(status == 'Caution'){
        return '<span class="text-warning">'+status+'</span>'
      }else if(status == 'Alert'){
        return '<span class="text-danger">'+status+'</span>'
      }else{
        return status
      }
    },
    onCancel () {
      this.$emit('toggle')
    },
    show(id) {
      this.id = id
      this.showModal = true
      this.loadData()
    },
    loadData () {
      this.loadingReport = true

      const params = {
        'columns[0][data]': '0',
        'columns[0][name]': 'engine.id',
        'columns[0][searchable]': 'true',
        'columns[0][orderable]': 'true',
        'columns[0][search][value]': this.id,
        'columns[0][search][regex]': 'false',
      }
      this.isLoading = true
      API.get('master/vessel/vessel-engine-checkup-report/read', { params })
        .then(res => {
          const data = res.data.data
          this.reports = data

          this.populateChart(data)

          this.loadingReport = false
        })
        .catch(err => {
          console.log(err)

          this.loadingReport = false
        })
      .then(()=> {
        this.loadingReport = false
      })
    },

    normalizeReportData(input){
      //check if input is integer
      if(!isNaN(input)){
        return parseInt(input)
      }else{
        return 0
      }
    },

    populateChart(data){
      // wear
      let crArr = []
      let pbArr = []
      let feArr = []
      let cuArr = []
      let snArr = []
      let moArr = []
      let niArr = []

      //contamination
      let kArr = []
      let naArr = []
      let siArr = []

      //oil condition
      let oxiArr = []
      let sulArr = []
      let nitArr = []
      let tbnArr = []

      //viscosity
      let v100Arr = []

      let wearChartDataValue = []
      let contaminationChartDataValue = []
      let oilConditionChartDataValue = []
      let viscosityChartDataValue = []
      let chartLabels = []

      if(data !== null && data.length > 0){
        data.reverse().map(perData => {
          chartLabels.push(this.renderDate(perData.checked_date))

          crArr.push(this.normalizeReportData(perData.report_data.cr))
          pbArr.push(this.normalizeReportData(perData.report_data.pb))
          feArr.push(this.normalizeReportData(perData.report_data.fe))
          cuArr.push(this.normalizeReportData(perData.report_data.cu))
          snArr.push(this.normalizeReportData(perData.report_data.sn))
          moArr.push(this.normalizeReportData(perData.report_data.mo))
          niArr.push(this.normalizeReportData(perData.report_data.ni))

          kArr.push(this.normalizeReportData(perData.report_data.k))
          naArr.push(this.normalizeReportData(perData.report_data.na))
          siArr.push(this.normalizeReportData(perData.report_data.si))

          oxiArr.push(this.normalizeReportData(perData.report_data.oxi))
          sulArr.push(this.normalizeReportData(perData.report_data.sul))
          nitArr.push(this.normalizeReportData(perData.report_data.nit))
          tbnArr.push(this.normalizeReportData(perData.report_data.tbn))

          v100Arr.push(this.normalizeReportData(perData.report_data.v100))
        })
      }

      wearChartDataValue = [
        {
            label : 'Cr (Chromium)',
            backgroundColor: '#4472c4',
            borderColor: '#4472c4',
            data: crArr
        },
        {
            label : 'Pb (Lead)',
            backgroundColor: '#ed7d31',
            borderColor: '#ed7d31',
            data: pbArr
        },
        {
            label : 'Fe (Iron)',
            backgroundColor: '#a5a5a5',
            borderColor: '#a5a5a5',
            data: feArr
        },
        {
            label : 'Cu (Copper)',
            backgroundColor: '#ffc000',
            borderColor: '#ffc000',
            data: cuArr
        },
        {
            label : 'Sn (Tin)',
            backgroundColor: '#5b9bd5',
            borderColor: '#5b9bd5',
            data: snArr
        },
        {
            label : 'Mo (Molybdenum)',
            backgroundColor: '#70ad47',
            borderColor: '#70ad47',
            data: moArr
        },
        {
            label : 'Ni (Nickel)',
            backgroundColor: '#264478',
            borderColor: '#264478',
            data: niArr
        }
      ]
      
      contaminationChartDataValue = [
        {
          label : 'K (Potassium)',
          backgroundColor: '#4472c4',
          borderColor: '#4472c4',
          data: kArr
        },
        {
          label : 'Na (Sodium)',
          backgroundColor: '#ed7d31',
          borderColor: '#ed7d31',
          data: naArr
        },
        {
          label : 'Si (Silicon)',
          backgroundColor: '#a5a5a5',
          borderColor: '#a5a5a5',
          data: siArr
        },
      ]

      oilConditionChartDataValue = [
        {
          label : 'OXI (Oxidation)',
          backgroundColor: '#4472c4',
          borderColor: '#4472c4',
          data: oxiArr
        },
        {
          label : 'NIT (Nitration)',
          backgroundColor: '#ed7d31',
          borderColor: '#ed7d31',
          data: nitArr
        },
        {
          label : 'SUL (Sulfur Products)',
          backgroundColor: '#a5a5a5',
          borderColor: '#a5a5a5',
          data: sulArr
        },
        {
          label : 'TBN (Total Base Number)',
          backgroundColor: '#ffc000',
          borderColor: '#ffc000',
          data: tbnArr
        },
      ]

       viscosityChartDataValue = [
        {
          label : 'V100',
          backgroundColor: '#4472c4',
          borderColor: '#4472c4',
          data: v100Arr
        },
      ]

      this.wearChartData.labels = chartLabels
      this.contaminationChartData.labels = chartLabels
      this.oilConditionChartData.labels = chartLabels
      this.viscosityChartData.labels = chartLabels

      this.wearChartData.datasets = wearChartDataValue
      this.contaminationChartData.datasets = contaminationChartDataValue
      this.oilConditionChartData.datasets = oilConditionChartDataValue
      this.viscosityChartData.datasets = viscosityChartDataValue
    }
  }
}
</script>

<style scoped>
.report_table{
  width: 100%;
  font-size: 8pt;
}

.report_table tr {
  height:42px;
}

.report_table tr td {
  padding:5px;
  border: 1px solid #bdbdbd;
}

.input-report{
  height:25px;
  border:none;
}

.form-input{
  width:100%;
}

.labelx{
  margin-bottom:10px;
}

.submit-btn{
  padding: 0.75rem 2rem;
  border:none;
  cursor: pointer;
  background: #1074bf;
  color:#fff;
  border-radius: 5px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

.submit-btn:hover{
  box-shadow: 0px 0px 10px #bdbdbd;
}

.report_data_content{
  width: 180px;
  font-size: 8pt;
}

.report_data_content div{
  height:42px;
  border:1px solid #bdbdbd;
  text-align: center;
  margin-bottom: 2px;
  padding-top:10px;
}
</style>
