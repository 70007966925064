<template>
  <b-modal
    id="modal-center"
    v-model="showModal"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    centered
    scrollable
    :title="title"
    @hide="onCancel"
  >
    <b-overlay
      :show="isLoading"
      rounded="sm"
      spinner-type="grow"
      spinner-small
      variant="white"
      opacity="1"
    >
      <p>{{ notes }}</p>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            default: Object
        }
    },
    data () {
        return {
            isLoading: false,
            showModal: false,
            title: '',
            notes: ''
        }
    },
    watch: {
        show () {
            this.showModal = this.show
            if (this.showModal) {
                this.title = this.data.status === 'Rejected' ? 'Lost Bid Note' : this.data.status === 'Notes' ? 'Notes' : 'Cancelled Note'
                this.notes = this.data.notes_vessel    
            }
        }
    },
    methods: {
        onCancel () {
            this.$emit('toggle')
        }
    },
}
</script>

<style>

</style>
