<template>
  <div
    id="form-update-vessel"
    class="container pt-5"
  >
    <loading-dialog :show="isLoading" />
    <div class="py-5 text-center">
      <h2>Data  Vessel</h2>
      <router-link
        :to="'../../app'"
        class="text-decoration-none float-left"
      >
        <i class="fa fa-arrow-left mr-2" />Back to previous
      </router-link>
      <!-- <p class="lead">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Pariatur hic reiciendis dolores ipsum a laudantium tenetur natus eveniet quis necessitatibus nostrum maiores, est facere quaerat. Consequatur ab tempore illum assumenda.</p> -->
    </div>
    <div class="row mb-5 w3-animate-opacity">
      <div class="col-md-8">
        <div class="needs-validation">
          <div v-if="isLoading">
            <div
              v-for="n in 3"
              :key="n"
              class="card my-4"
            >
              <div class="card-header bg-active">
                <b-skeleton width="85%" />
              </div>
              <div class="card-body px-4">
                <b-skeleton width="40%" />
                <b-skeleton width="85%" />
              </div>
            </div>
          </div>

          <div v-else>
            <div
              v-for="(cardValue, cardKey) in customField"
              v-show="findLoadCapacity(cardValue)"
              :key="cardKey"
              class="card mb-4"
            >
              <div class="card-header bg-active d-flex justify-content-between">
                <h4>{{ cardKey }}</h4>
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="toggleUpdateForm(cardKey)"
                >
                  <i class="fas fa-pencil-alt mx-1" />
                  Update
                </b-button>
              </div>
              <div class="card-body px-4">
                <div
                  v-for="(fieldValue, fieldKey) in cardValue"
                  :key="fieldKey"
                  class="row"
                >
                  <div class="col-md-12">
                    <label>{{ fieldValue.label }}</label>
                    <label
                      v-if="findRequired(fieldValue.mandatory)"
                      class="text-info ml-2"
                    > (Optional) </label>
                    <div class="d-flex justify-content-between">
                      <p
                        v-if="fieldValue.field === 'gross_tonnage' ||
                          fieldValue.field === 'net_tonnage' ||
                          fieldValue.field === 'deadweight' ||
                          fieldValue.field === 'load_capacity' ||fieldValue.field === 'size_loa' ||
                          fieldValue.field === 'size_lbp' ||
                          fieldValue.field === 'size_width' ||
                          fieldValue.field === 'size_depth'"
                        class="text-muted border-bottom w-100"
                      >
                        {{ thausandSparator(selectedVessel[fieldValue.field]) || '-' }}
                      </p>
                      <p
                        v-else
                        class="text-muted border-bottom w-100"
                      >
                        {{ selectedVessel[fieldValue.field] || '-' }}
                      </p>
                      <span
                        v-show="fieldValue.field === 'size_loa' ||
                          fieldValue.field === 'size_lbp' ||
                          fieldValue.field === 'size_width' ||
                          fieldValue.field === 'size_depth'"
                      >
                        Meter
                      </span>
                      <span
                        v-show="fieldValue.field === 'gross_tonnage' ||
                          fieldValue.field === 'net_tonnage' ||
                          fieldValue.field === 'deadweight' ||
                          fieldValue.field === 'load_capacity'"
                      >
                        MT
                      </span>
                      <span v-show="fieldValue.field === 'horsepower'">
                        HP
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          v-if="licenseValid.value ==='Verified'"
          class="license-card"
        >
          <div class="d-flex justify-content-between align-content-center text-primary mt-2">
            <h4 class="text-muted">
              License Vessel
            </h4>
            <a
              class="text-primary text-decoration-none mt-2 c-pointer"
              @click="showRecordsPaymentLicense = !showRecordsPaymentLicense"
            > Records License</a>
          </div>
          <div class="card my-4">
            <div
              v-if="!isLoading"
              class="card-body"
            >
              <label>Status</label>
              <p class="text-muted">
                {{ license.status || '-' }}
              </p>
              <div
                class="c-pointer"
                :title="license.until || 'none'"
              >
                <label>License Start</label>
                <p class="text-muted">
                  {{ ddMMyyyy(license.start) || '-' }}
                </p>
              </div>
              <div
                class="c-pointer"
                :title="license.until || 'none'"
              >
                <label>License Expired</label>
                <p class="text-muted">
                  {{ ddMMyyyy(license.end) || '-' }}
                </p>
              </div>
              <div class="card-footer bg-white">
                <transition name="fade">
                  <div
                    v-show="offerTips"
                    class="more-offer"
                  >
                    <div />
                    <div class="d-flex align-items-center">
                      <i class="far fa-lightbulb fa-2x text-success" />
                      <div class="mx-2">
                        Buy license here.
                      </div>
                    </div>

                    <i
                      class="fas fa-times fa-sm text-muted c-pointer"
                      @click="offerTips = false"
                    />
                  </div>
                </transition>
                <button
                  class="btn btn-primary btn-block"
                  @click="buyLicense()"
                >
                  <i class="fa fa-plus-circle" /> Buy License
                </button>
              </div>
            </div>
            <div
              v-else
              class="card-body"
            >
              <b-skeleton
                v-show="isLoading"
                width="60%"
              />
              <b-skeleton
                v-show="isLoading"
                width="60%"
              />
            </div>
          </div>
        </div>

        <h4 class="text-muted my-4">
          Vessel Status
        </h4>
        <div class="card my-4">
          <div
            v-if="!isLoading"
            class="card-body"
          >
            <label>Keterangan</label>
            <small
              v-show="licenseValid.value === 'Rejected'"
              class="btn-link mx-2 c-pointer"
              @click="showNotes({ status:'Notes', notes_vessel: license.notes })"
            >Open Notes</small>
            <p class="d-flex justify-content-start">
              <b-skeleton
                v-show="isLoading"
                animation="wave"
                width="40%"
              />
              <span
                v-show="licenseValid"
                class="badge badge-primary badge-pill"
                :class="licenseValid.style"
              >{{ licenseValid.value }}</span>
              <span
                v-show="isNew.show"
                class="badge badge-warning badge-pill mx-2"
              >{{ isNew.text }}</span>
            </p>
            <label>Registered Date</label>
            <p class="text-muted">
              {{ ddMMyyyy(userCreate.created_at) || '-' }}
            </p>
            <label>Registered By</label>
            <p class="text-muted">
              {{ userCreate.name || '-' }}
            </p>
            <label>User Email</label>
            <p class="text-muted">
              {{ userCreate.email || '-' }}
            </p>
          </div>
          <div
            v-else
            class="card-body"
          >
            <b-skeleton
              v-show="isLoading"
              width="30%"
            />
            <b-skeleton
              v-show="isLoading"
              width="70%"
            />
            <br>
            <b-skeleton
              v-show="isLoading"
              width="30%"
            />
            <b-skeleton
              v-show="isLoading"
              width="70%"
            />
            <br>
            <b-skeleton
              v-show="isLoading"
              width="30%"
            />
            <b-skeleton
              v-show="isLoading"
              width="70%"
            />
          </div>
        </div>

        <Particulars />
        <div class="d-flex justify-content-end text-primary">
          <i class="fa fa-pencil-alt mx-1" />
          <small
            class="text-decoration-none c-pointer mx-1"
            @click="toggleEditParticulars"
          >Edit Particulars</small>
        </div>
        
        <h4 class="text-muted mt-4">
          Vessel Engine
        </h4>
        <small class="text-muted">Klik tombol dibawah ini untuk mengelola mesin kapal.</small>
        <router-link
          :to="'/vessel-engines/list/' + this.$route.params.id"
          class="btn btn-primary w-100 mt-4 ml-0 mr-0"
        >
            <i class="fa fa-cog mr-2" />Manage Engines
        </router-link>
      </div>
    </div>
    <UpdateVessel
      :show="showFormUpdate"
      :data="dataUpdate"
      @toggle="toggleUpdateForm"
    />
    <EditParticulars
      :show="showFormEditParticulars"
      :data="selectedParticulars"
      @toggle="showFormEditParticulars = false"
    />
    <RecordsPaymentLicense
      :show="showRecordsPaymentLicense"
      :data="recordsPaymentLicense"
      @toggle="showRecordsPaymentLicense = !showRecordsPaymentLicense"
    />
    <ViewNotes ref="view-notes" />
  </div>
</template>
<script>
import Particulars from './Particulars'
import LoadingDialog from "@/components/dialogs/LoadingDialog"
import { mapState } from 'vuex'
import UpdateVessel from "@/components/modals/UpdateVessel"
import EditParticulars from "@/components/modals/EditParticulars"

import formatDate from '../../mixins/formatDate'
import formatValidation from '../../mixins/formatValidation'
import API from "../../plugins/axios";
import formatChar from "@/mixins/formatChar";
import RecordsPaymentLicense from "@/components/modals/RecordsPaymentLicense";
import ViewNotes from "../../components/modals/ViewNotes";

export default {
  components: {
    RecordsPaymentLicense,
    Particulars,
    'loading-dialog': LoadingDialog,
    UpdateVessel,
    ViewNotes,
    EditParticulars
  },
  mixins: [formatDate, formatValidation, formatChar],
  data () {
    return {
      showFormUpdate: false,
      showFormEditParticulars: false,
      showRecordsPaymentLicense: false,

      offerTips: false,
      customField: {},
      dataUpdate: {},
      dataEditParticulars: {}
    }
  },
  computed: {
    ...mapState('moduleVessel', [
      'selectedVessel',
      'selectedVesselType',
      'userCreate',
      'license',
      'buildYear',
      'isLoading',
      'selectedParticulars',
      'selectedParticulars',
      'recordsPaymentLicense'
    ]),
    licenseValid () {
      let data = {}
      let isValid = this.license.is_valid
      if (!this.isLoading) {
        if (isValid === 'Valid') {
          data = {
            style: 'badge-primary',
            value: 'Verified'
          }
        } else if (isValid === 'Rejected') {
          data = {
            style: 'badge-danger',
            value: isValid
          }
        } else if (isValid === 'Pending') {
          data = {
            style: 'badge-secondary',
            value: isValid
          }
        }
      }
      return data
    },
    isNew () {
      let data = {}
      let created = this.userCreate.created_at
      if (!this.isLoading) {
        data = {
          show: this.addDate(new Date(created), 0, 12) > new Date(),
          text: 'New'
        }
      }
      return data
    }
  },
  watch: {
    selectedVesselType () {
      this.loadCustomField()
    }
  },

  created () {
    this.loadSelectedVessel()
    if (this.selectedVesselType) this.loadCustomField()
  },
  methods: {
    loadSelectedVessel () {
      const payload = this.$route.params.id
      this.$store.dispatch('moduleVessel/LOAD_SELECTED', payload)
    },
    loadCustomField () {
      const vm = this
      const payload = {
        id: vm.selectedVesselType
      }
      API.get('master/vessel-type/detail-validation', {params: payload})
        .then((res) => {
          const data = res.data.data
          vm.customField = data.data_validate

          // added field vessel_type on custom field, because custom field generated by vessel_type
          vm.customField[Object.keys(vm.customField)[0]].push({
            label:'Vessel Type',
            field:'vessel_type'
          })
        })
        .catch(() => {})
    },
    toggleUpdateForm (payload) {
      this.showFormUpdate = !this.showFormUpdate
      this.dataUpdate =
        {
          group: payload,
          vesselType: this.selectedVesselType
        }
    },
    toggleEditParticulars () {
      this.showFormEditParticulars = !this.showFormEditParticulars
    },
    buyLicense () {
      this.$router.push({
        name:'buy-license',
        params: {
          id: this.$route.params.id
        }
      })
    },
    showNotes (data) {
      this.$refs["view-notes"].show(data)
    }
  }
}
</script>

<style scoped>
.more-offer {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 50px;
  bottom: 80px;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 10px #888;
  transition: opacity 0.1s ease-in-out;
}

.more-offer::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
