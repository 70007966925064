<template>
  <div
    class="sidebar"
    :class="{ active: activePanelCargoList }"
  >
    <div class="row mx-0">
      <div class="col-lg px-0">
        <button
          class="btn btn-light form-control btn-sm mx-0"
          @click="$store.commit('SET_PANEL_CARGO_LIST')"
        >
          <i class="fas fa-times" />
        </button>
      </div>
    </div>

    <div class="title my-1">
      {{ title }}
    </div>
    <div class="subtitle ">
      Berikut adalah cargo yang tersedia pada pelabuhan ini.
    </div>

    <div class="cargo-list">
      <div class="row mx-0">
        <div class="col-lg-12 px-1">
          <div class="list-grou">
            <a
              v-for="(item, index) in cargoList"
              :key="index"
              href="#"
              class="list-group-item list-group-item-action py-2"
              @click="findRoute(item)"
            >
              <div class="d-flex w-100 justify-content-between">
                <div class="subtitle">{{ hide_pelabuhan(item.port.destination.name) }}</div>
              </div>
              <small> {{ item.cargo.name }}, {{ thausandSparator(item.qty.amount) }}{{ item.qty.type }} </small>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import formatDate from "../mixins/formatDate";
import formatChar from "../mixins/formatChar";

export default {
  name: "LefbarCargoList",
  mixins: [formatDate, formatChar],
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  watch: {
    cargoList(val) {
      console.log(val)
      if (val.length > 0) {
        this.$store.commit('SET_PANEL_CARGO_LIST', true);
      }
    }
  },
  computed: {
    ...mapState([
        'activePanelCargoList',
        'cargoList'
    ])
  },
  methods: {
    findRoute (data) {
      this.$emit('find', data)
    }
  },
}
</script>

<style scoped>

</style>
