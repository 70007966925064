<template>
  <div
    id="view-document"
    class="modal fade bd-example-modal-lg"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header pt-1 pb-1">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span
              aria-hidden="true"
              @click="clear_documents()"
            >&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- <embed class="" :src="link"> -->
          <object
            :data="main_data.link"
            :type="main_data.type"
            typemustmatch
            :class="styleParticular"
          >
            <p>You don't have a viewer plugin, but you can 
              <a :href="main_data.link">Download the file.</a>
            </p>
          </object>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
// import LoadingDialog from "../../components/dialogs/ConfirmDialog";
export default {
  data() {
    return {
      main_data: Object,
      styleParticular: ''
    };
  },
  methods: {
    show(payload) {
      $("#view-document").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      })
      this.main_data = payload
      this.styleParticular = payload.type === 'application/pdf' ? 'pdf' : 'img'
    },

    clear_documents() {
      this.Object = ''
    },
  },
};
</script>
<style lang="scss" scoped>
#view-document {
  .modal-content {
    // background-color: transparent;
    .modal-header {
      background-color: white;
      border-bottom: none;
    }

    .modal-body {
      padding: 0;
      text-align: center;

      .img {
        max-width: 100%;
        height: auto;
        max-height: 100%;
      }

      .pdf {
        width: 100%;
        height: 700px;
      }
    }
  }
}
</style>
