var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"register"}},[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"form-signup"},[_c('div',{staticClass:"text-center mb-4"},[_c('h1',{staticClass:"h1 mb-3 font-weight-thin"},[_vm._v(" Company Data ")]),_c('p',[_c('span',{staticClass:"text-muted"},[_vm._v(" Silahkan melengkapi data perusahaan terlebih dahulu untuk melanjutkan ")])])]),_c('ValidationProvider',{attrs:{"vid":"email_company","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.entry.email_company),expression:"entry.email_company"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"","autofocus":"","disabled":_vm.entry.email_company},domProps:{"value":(_vm.entry.email_company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.entry, "email_company", $event.target.value)}}}),_c('label',{attrs:{"for":"inputName"}},[_vm._v("Email")]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{attrs:{"vid":"name","name":"Fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.entry.name),expression:"entry.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"","autofocus":"","disabled":_vm.entry.name},domProps:{"value":(_vm.entry.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.entry, "name", $event.target.value)}}}),_c('label',{attrs:{"for":"inputName"}},[_vm._v("Fullname")]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{attrs:{"vid":"phone","name":"Phone Number","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.entry.phone),expression:"entry.phone"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"","autofocus":""},domProps:{"value":(_vm.entry.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.entry, "phone", $event.target.value)}}}),_c('label',{attrs:{"for":"inputName"}},[_vm._v("Phone Number")]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{attrs:{"vid":"name_company","name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.entry.name_company),expression:"entry.name_company"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"","autofocus":""},domProps:{"value":(_vm.entry.name_company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.entry, "name_company", $event.target.value)}}}),_c('label',{attrs:{"for":"inputName"}},[_vm._v("Company Name")]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{attrs:{"vid":"phone_company","name":"Company Phone Number","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.entry.phone_company),expression:"entry.phone_company"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"","autofocus":""},domProps:{"value":(_vm.entry.phone_company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.entry, "phone_company", $event.target.value)}}}),_c('label',{attrs:{"for":"inputName"}},[_vm._v("Company Phone Number")]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{attrs:{"vid":"country","name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.entry.country),expression:"entry.country"}],staticClass:"form-control custom-select",attrs:{"id":"inputCountry","placeholder":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.entry, "country", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.countryEntries),function(item,index){return _c('option',{key:index,domProps:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('label',{attrs:{"for":"inputCountry"}},[_vm._v("Country")]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{attrs:{"vid":"address_company","name":"Company Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.entry.address_company),expression:"entry.address_company"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Company Address","autofocus":""},domProps:{"value":(_vm.entry.address_company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.entry, "address_company", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('button',{staticClass:"btn btn-primary btn-block mx-0",attrs:{"disabled":_vm.isLoading,"data-btn":"register"},on:{"click":_vm.saveAction}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"spinner-border spinner-border-sm mx-2",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v("Lanjutkan ")]),_c('p',{staticClass:"mt-4 text-center"},[_vm._v(" Kembali ke halaman "),_c('a',{attrs:{"href":_vm.linkHomepage}},[_vm._v("Beranda")])]),_c('p',{staticClass:"mt-2 mb-3 text-muted text-center"},[_vm._v(" © 2020 ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }