<template>
  <div class="container container-profile">
    <div class="container shadow-lg rounded-100">
      <div class="row mb-4">
        <div class="col-md-12">
          <router-link
            :to="'app'"
            class="text-decoration-none"
          >
            <i class="fa fa-arrow-left mr-2" />Back to previous
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 flex-column align-items-center">
          <VerticalNavigation />
        </div>
        <div class="col-md-9">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerticalNavigation from "@/components/VerticalNavigation";
export default {
  name: 'Account',
  components: { VerticalNavigation }
}
</script>

<style scoped>

</style>
