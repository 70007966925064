export default {
  methods: {
    // Melakukan hide word pelabuhan
    hide_pelabuhan(value) {
      return value;
    },
    thausandSparator(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    splitStrings(val) {
      let filename = val.replace(/\.[^/.]+$/, "")
      const ext = val.split('.').pop();
      const maxLength = 12
      if (filename.length > maxLength) {
        return `${filename.substring(0, maxLength)}... .${ext}`
      } else {
        return val
      }
    },
  }
}