<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="showModal"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      centered
      scrollable
      :title="title"
      @hide="onCancel"
    >
      <b-overlay
        :show="isLoading"
        rounded="sm"
        spinner-type="grow"
        spinner-small
        variant="white"
        opacity="1"
      >
        <b-container
          v-if="dataDetail.id"
          class="bv-example-row"
        >
          <span class="text-muted">#{{ dataDetail.id }}</span>
          <h5>
            <b-badge :variant="dataDetail.variant">
              {{ dataDetail.status }}
            </b-badge>
          </h5>
          <hr>
          <b-row>
            <b-col cols="4">
              <span>Vessel Name</span>
            </b-col>
            <b-col cols="8">
              <span class="text-muted">{{ dataDetail.vessel.name }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <span>License</span>
            </b-col>
            <b-col cols="8">
              <span class="text-muted"> {{ dataDetail.license }}</span>
            </b-col>
          </b-row>

          <hr>

          <div class="activity">
            <h6>Updated Activity</h6>
            <div v-show="dataDetail.payment_license_history.length < 1">
              No updated activities.
            </div>
            <b-list-group
              v-for="(activity, index) in dataDetail.payment_license_history"
              :key="index"
              class="mb-0 list-group-flush border-bottom"
            >
              <b-list-group-item
                v-if="activity.notes_admin"
                class="flex-column row-from align-items-start border-0"
              >
                <div class="d-flex w-100 justify-content-between">
                  <h6 class="mb-0">
                    {{ activity.admin_name }}
                  </h6>
                </div>
                <div class="text-muted">
                  <small>Update License: {{ activity.waktu }}</small>
                </div>
                <p class="mb-0 text-notes text-muted">
                  {{ activity.notes_admin }}
                </p>
              </b-list-group-item>

              <b-list-group-item
                v-if="activity.notes_user"
                class="flex-column row-to align-items-start"
              >
                <div class="">
                  <small class="font-italic">You replied :</small>
                  <h6 class="mb-1">
                    {{ activity.user_name }}
                  </h6>
                </div>
                <p class="mb-0 text-notes text-muted">
                  {{ activity.notes_user }}
                </p>
              </b-list-group-item>
            </b-list-group>
            <div class="d-flex justify-content-end mt-2">
              <b-button
                v-if="dataDetail.status === 'Follow Up'"
                variant="primary"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Reply
              </b-button>
              <div class="dropdown-menu form-notes p-4">
                <div class="form-group">
                  <i class="fa fa-times c-pointer float-right" />
                  <label>Notes</label>
                  <b-form-textarea
                    id="textarea-state"
                    v-model="notes"
                    rows="5"
                  />
                </div>
                <b-button
                  size="sm"
                  variant="success"
                  @click="acceptHandler()"
                >
                  Send
                </b-button>
                <small class="float-right">{{ notes.length }}</small>
              </div>
              <b-button
                v-if="dataDetail.status === 'Waiting Confirmation'"
                variant="outline-secondary"
                @click="cancelHandler()"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import API from '../../plugins/axios'
import formatDate from '../../mixins/formatDate'
import {SwalConfirm, SwalSuccess} from "../../plugins/sweetalert2";
export default {
  name: 'DetailPaymentLicense',
  mixins: [ formatDate ],
  props: {
    show: {
      type: Boolean,
      default: true
    },
    data: {
      type: String,
      default: String
    }
  },
  data () {
    return {
      showModal: false,
      isLoading: false,
      title: 'Detail Payment License',
      dataDetail: Object,
      notes: ''
    }
  },
  watch: {
    show() {
      this.showModal = this.show
      if (this.showModal) this.loadData()
      else this.dataDetail = Object
    },
    data () {
      this.loadData()
    }
  },
  methods: {
    onCancel () {
      this.$emit('toggle')
    },
    loadData () {
      const params = {
        id: this.data
      }
      this.isLoading = true
      API.get('transaction/payment/license-vessel/detail', { params })
        .then(res => {
          const data = res.data.data
          this.dataDetail = {
              id: data.id,
              license : data.payment_license_date.waktu,
              vessel: data.vessel,
              user: data.user,
              payment_license_history: data.payment_license_history,
              status: data.payment_status.type === 'Menunggu Verifikasi Admin' ? 'Waiting Confirmation' :
                      data.payment_status.type === 'Menunggu Follow Up' ? 'Follow Up' :
                      data.payment_status.type === 'Batal' ? 'Cancelled' : 'Success',
              variant: data.payment_status.type === 'Menunggu Verifikasi Admin' ? 'primary' :
                       data.payment_status.type === 'Menunggu Follow Up' ? 'warning' :
                       data.payment_status.type === 'Batal' ? 'danger' : 'success',

          }
        })
        .catch(err => {
          console.log(err.response.data)
        })
      .then(()=> {
        this.isLoading = false
      })
    },
    acceptHandler () {
      const vm = this
      const params = {
        id: vm.data,
        notes: vm.notes
      }
      API.patch('transaction/payment/license-vessel/verified/waiting', params)
        .then(res => {
          SwalSuccess (res.data.message, async () => {
            if (vm.$route.params.id) await vm.$store.dispatch('moduleVessel/LOAD_SELECTED', vm.$route.params.id)
            else vm.$parent.loadData(0)
            vm.$emit('toggle')
          })
        })
        .catch(err => {
          console.log(err.response.data)
        })
        .then(() => {
          vm.notes = ''
        })
    },

    cancelHandler () {
      const vm = this

      const format = {
        title: 'Wait...',
        text: 'Anda yakin ingin membatalkan pembelian license ?',
        textConfirm: 'Accept',
        textCancel: 'Cancel'
      }
      SwalConfirm (format, () => vm.doCancel())
    },

    doCancel () {
      const vm = this
      const params = {
        id: vm.data
      }
      API.patch('transaction/payment/license-vessel/verified/cancelled', params)
        .then(res => {
          SwalSuccess (res.data.message, async () => {
            if (vm.$route.params.id) await vm.$store.dispatch('moduleVessel/LOAD_SELECTED', vm.$route.params.id)
            else vm.$parent.loadData(1)
            vm.$emit('toggle')
          })
        })
        .catch(err => {
          console.log(err.response.data)
        })
    }
  }
}
</script>

<style scoped>
  .form-notes {
    min-width: 20rem !important;
    left: -100px !important;
    top: -50px !important;
  }

  .row-from {
    background-color: #F8F9FA;
  }

  .row-to {
    background-color: #CCE6F4;
  }

  .text-notes {
    background-color: #EDF2F4;
    padding: 10px;
    font-size: 0.8rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
</style>
