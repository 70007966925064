<template>
  <div>
    <h4 class="d-flex justify-content-start align-items-center">
      <span class="badge badge-secondary badge-pill mx-1">{{ selectedParticulars.length }}</span>
      <span class="text-muted mx-1">Vessel Particulars</span>
    </h4>
    <small class="text-muted">Klik item dibawah ini untuk melihat gambar atau document.</small>

    <ul
      v-if="selectedParticulars.length"
      class="list-group my-2"
    >
      <li
        v-for="(item, index) in selectedParticulars"
        :key="index"
        class="list-group-item list-group-item-action d-flex justify-content-between lh-condensed"
      >
        <div class="d-flex align-items-center btn-link text-decoration-none c-pointer w-100">
          <img
            v-if="checkJpg(item.document_type)"
            src="../../assets/icons/jpg.png"
            salt="JPG File"
          >
          <img
            v-else-if="checkPng(item.document_type)"
            src="../../assets/icons/png.png"
            alt="PNG File"
          >
          <img
            v-else-if="checkPdf(item.document_type)"
            src="../../assets/icons/pdf.png"
            alt="PDF File"
          >
          <div
            class="mx-2 w-100"
            @click="open_particular(item.url, item.document_type)"
          >
            <p class="my-0">
              {{ splitStrings(item.name) }}
            </p>
            <small class="text-muted">Upload date: {{ ddmmyyyyStripe(item.upload_date) }}</small>
          </div>
          <span
            class="fa fa-trash btn-link c-pointer"
            @click="onDelete(item.path)"
          />
        </div>
      </li>
    </ul>

    <ul
      v-else
      class="list-group mb-3"
    >
      <li class="list-group-item text-center">
        <p class="my-0">
          No Particulars
        </p>
      </li>
    </ul>

    <ViewDocumentOne ref="view-particular" />
  </div>
</template>

<script>
import ViewDocumentOne from '../../components/modals/ViewDocumentOne'
import formatDate from '../../mixins/formatDate'
import { mapState } from 'vuex'
import formatValidation from "@/mixins/formatValidation";
import formatChar from '../../mixins/formatChar';
import {SwalConfirm} from "../../plugins/sweetalert2";
export default {
  components: {
    ViewDocumentOne,
  },

  mixins:[formatDate, formatValidation, formatChar],

  computed: {
    ...mapState('moduleVessel', [
      'selectedParticulars',
      'particularsOption'
    ]),
  },

  methods: {
    open_particular (link, type) {
      const payload = {
        link: link,
        type: type
      }
      this.$refs["view-particular"].show(payload)
    },
    onDelete (path) {
      const vm = this
      const payload = {
        path: path,
        vessel: vm.$route.params.id
      }
      SwalConfirm ('Anda yakin ingin menghapus file ini ?', async () => await vm.$store.dispatch('moduleVessel/DELETE_PARTICULAR', payload))
    }
  }

}
</script>

<style lang="scss" scoped>
  img {
    max-width: 40px;
    min-width: 40px;
  }
</style>
