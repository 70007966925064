<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="showModal"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      centered
      scrollable
      :title="title"
      @hide="onCancel"
    >
      <b-overlay
        rounded="sm"
        spinner-type="grow"
        spinner-small
        variant="white"
        opacity="1"
      >
        <b-container
          v-if="data.id"
          class="bv-example-row"
        >
          <b-row>
            <b-col cols="5">
              <b-row>
                <b-col
                  cols="3"
                  class="d-flex align-items-center justify-content-center p-0"
                >
                  <img
                    class="img-size"
                    src="\images\ic-roam-port.png"
                    alt
                  >
                </b-col>
                <b-col
                  cols="9"
                  class="d-flex flex-column"
                >
                  <p class="border-bottom font-weight-bold pb-1 mb-2">
                    {{ hide_pelabuhan(data.port.from.name) }}
                  </p>
                  <span
                    class="text text-muted"
                    v-html="convertDms(data.port.from.location.dms.latitude)"
                  />
                  <span
                    class="text text-muted"
                    v-html="convertDms(data.port.from.location.dms.longitude)"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="2"
              class="d-flex align-items-center justify-content-center"
            >
              <i class="fas fa-angle-right fa-2x" />
            </b-col>
            <b-col cols="5">
              <b-row>
                <b-col
                  cols="3"
                  class="d-flex align-items-center justify-content-center p-0"
                >
                  <img
                    class="img-size"
                    src="\images\ic-roam-port.png"
                    alt
                  >
                </b-col>
                <b-col
                  cols="9"
                  class="d-flex flex-column"
                >
                  <p class="border-bottom font-weight-bold pb-1 mb-2">
                    {{ hide_pelabuhan(data.port.destination.name) }}
                  </p>
                  <span
                    class="text text-muted"
                    v-html="convertDms(data.port.destination.location.dms.latitude)"
                  />
                  <span
                    class="text text-muted"
                    v-html="convertDms(data.port.destination.location.dms.longitude)"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <hr>

          <b-row class="mb-4">
            <b-col
              cols="6"
              class="d-flex flex-column"
            >
              <span class="font-weight-bolder w3-text-gray">Open Date</span>
              <span class="text"> {{ ddmmyyySlash(data.laycan.from) }} - {{ ddmmyyySlash(data.laycan.to) }}</span>
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col
              cols="6"
              class="d-flex flex-column"
            >
              <span class="font-weight-bolder w3-text-gray">Cargo Type</span>
              <span class="text"> {{ data.cargo.name }}</span>
            </b-col>
            <b-col
              cols="6"
              class="d-flex flex-column"
            >
              <span class="font-weight-bolder w3-text-gray">Load Capacity</span>
              <span class="text"> {{ thausandSparator(data.qty.amount) }} {{ data.qty.type }}</span>
            </b-col>
          </b-row>


          <b-row>
            <b-col
              cols="6"
              class="d-flex flex-column"
            >
              <span class="font-weight-bolder w3-text-gray">Loading Duration</span>
              <span class="text"> {{ data.duration.loading }} Day</span>
            </b-col>
            <b-col
              cols="6"
              class="d-flex flex-column"
            >
              <span class="font-weight-bolder w3-text-gray">Discharge Duration</span>
              <span class="text"> {{ data.duration.discharge }} Day</span>
            </b-col>
          </b-row>

          <hr>

          <div class="activity">
            <p class="font-weight-bolder w3-text-gray">
              Notes from cargo owner:
            </p>
            <b-form-textarea
              id="textarea"
              :value="data.notes"
              rows="3"
              max-rows="6"
              disabled
            />

            <div class="d-flex justify-content-center mt-2">
              <b-button
                variant="success"
                :disabled="loading"
                @click="bookingHandler()"
              >
                <span
                  v-if="loading"
                >
                  <i class='fa fa-spinner rotating'></i>&nbsp;Booking...
                </span>
                <span
                  v-else
                >
                  Booking Now
                </span>
              </b-button>
            </div>
          </div>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import API from '../../plugins/axios'
import formatDate from '../../mixins/formatDate'
import formatChar from '../../mixins/formatChar'
import { 
  SwalSuccess, 
  SwalConfirm, 
  SwalWarning 
} from "../../plugins/sweetalert2";

export default {
  name: 'DetailCargo',
  mixins: [ formatDate, formatChar ],
  props: {
    show: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: Object
    },
    booking: {
      type: Object,
      default: Object
    }
  },
  data () {
    return {
      showModal: false,
      title: 'Detail Cargo',
      loading: false
    }
  },
  watch: {
    show() {
      this.showModal = this.show
    }
  },
  created () {
    this.showModal = this.show
  },
  methods: {

    onCancel () {
      this.$store.commit('SET_MODAL_DETAIL_CARGO', false)
    },

    convertDms (dms) {
      let d = dms[0]
      let m = dms[1]
      let s = dms[2]
      let w = dms[3]
      return `${d}<sup>'</sup>${m}<sup>"</sup>${s}<sup>&#x26AC;</sup>${w}`
    },

    bookingHandler () {
      const format = {
        title: 'Wait...',
        text: 'Anda yakin ingin melakukan booking ?',
        confirmText: 'Yes',
        cancelText: 'No'
      }
      SwalConfirm (format, () => this.doBooking())
    },
    buyLicense (data) {
      this.$router.push({
        name:'buy-license',
        params: {
          id: data
        }
      })
    },
    doBooking () {
      const vm = this

      //show loading
      vm.loading = true;

      const params = {
        shipment: vm.data.id,
        vessel: vm.booking.vessel,
        tugboat: vm.booking.tugboat
      }
      API.post("transaction/shipment/offers/create", params)
        .then((response) => {
          //hide loading
          vm.loading = false;

          const meta = response.data;
          if (meta.code === 200) {
            SwalSuccess ('Booking sudah di ajukan.')

            //show nav
            this.$store.commit('TOOGLE_NAV')

            //redirect to booking list on going
            this.$router.push({ name: 'booking-list' })
          }
        })
        .catch((error) => {
          //hide loading
          vm.loading = false;

          if (error.response.status === 400) {
            if (error.response.data.data.name) {
              const format = {
                title: 'Oops!',
                text: `Kapal <strong>${error.response.data.data.name}</strong> belum memiliki lisensi, silahkan hubungi customer service kami atau lakukan pembelian lisensi pada menu License`,
                confirmText: 'Buy License',
                cancelText: 'No'
              }
              SwalConfirm (format, () => {
                vm.buyLicense(error.response.data.data.vessel)
              })

              return
            }
            
            SwalWarning (error.response.data.message)
          }
        })
        .then(() => {
          //hide loading
          vm.loading = false;

          vm.onCancel()
        })
    }
  }
}
</script>

<style scoped>
  .form-conship {
    min-width: 20rem !important;
    left: -100px !important;
    top: -50px !important;
  }

  .img-size {
    width: 35px;
    height: 35px;
  }

  .text {
    font-size: 0.9rem;
  }
</style>
