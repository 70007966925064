<template>
  <b-modal
    id="manifest-modal"
    v-model="modalShow"
    :title="title"
    centered
    size="lg"
    hide-footer
  >
    <template v-if="loading">
      <div class="text-center">
        <b-spinner
          type="grow"
          variant="primary"
          class="mb-3"
        />
      </div>
    </template>
    <template v-else>
      <form v-on:submit="saveManifest">
        <div class="mb-5">
          Reference:
          <input type="text" maxlength="100" v-model="referenceNumber" :disabled="!isEditAble"/>
        </div>
        <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block">
          <div class="row">
            <div class="col-lg-2 col-md-3">Item*</div>
            <div class="col-lg-2 col-md-3">Serial Number</div>
            <div class="col-lg-3 col-md-3">Description</div>
            <div class="col-lg-2 col-md-3">Qty*</div>
            <div class="col-lg-2 col-md-3">Unit*</div>
          </div>
        </div>
        <div class="row mt-3" v-for="(manifest, index) in manifestList" v-bind:key="index">
          <div class="col-lg-2 col-md-3">
            <div class="d-block d-md-block d-sm-block d-lg-none">Item*</div>
            <input type="text" maxlength="100" class="form-control" v-model="manifest.item" :disabled="!isEditAble" required/>
          </div>
          <div class="col-lg-2 col-md-3">
            <div class="d-block d-md-block d-sm-block d-lg-none">Serial Number</div>
            <input type="text" maxlength="150" class="form-control" v-model="manifest.serial_number" :disabled="!isEditAble" />
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="d-block d-md-block d-sm-block d-lg-none">Description</div>
            <input type="text" maxlength="250" class="form-control" v-model="manifest.description" :disabled="!isEditAble" />
          </div>
          <div class="col-lg-2 col-md-3">
            <div class="d-block d-md-block d-sm-block d-lg-none">Qty*</div>
            <input type="number" maxlength="10" class="form-control" v-model="manifest.qty" :disabled="!isEditAble" required/>
          </div>
          <div class="col-lg-2 col-md-3">
            <div class="d-block d-md-block d-sm-block d-lg-none">Unit*</div>
            <select class="form-control" v-model="manifest.unit" :disabled="!isEditAble" required>
              <option v-for="unit in units" v-bind:key="unit.key">{{ unit.label }}</option>
            </select>
          </div>
          <div v-if="isEditAble" class="col-lg-1 col-md-3">
            <div class="d-block d-md-block d-sm-block d-lg-none">&nbsp;</div>
            <button
              v-b-tooltip.hover.topright="'remove manifest from list'"
              class="btn btn-danger"
              @click="removeManifest(index)"
            >
              <i class="fa fa-trash-alt"></i>
            </button>
          </div>
          <div class="d-block d-md-block d-sm-block d-lg-none col-12 divider mt-3"></div>
        </div>

        <div v-if="lastUpdated !== null" class="text-muted text-right mt-3">
          last updated: {{ ddMMyyyyt(lastUpdated) }}
          <span @click="openManifestLogs()" style="cursor:pointer" class="btn btn-sm btn-primary ms-2">View Logs</span>
        </div>

        <div v-if="isEditAble">
          <div class="text-center mt-4 mb-4">
            <a
              v-b-tooltip.hover.topright="'Add more manifest to list'"
              @click="addManifest()"
              class="text-primary pointer"
            >
              Add More<i class="fa fa-plus ml-3"></i>
            </a>
          </div>

          <div class="text-right">
            <button 
              type="submit" 
              class="btn btn-primary"
            >Save Manifest</button>
          </div>
        </div>
      </form>
    </template>
  </b-modal>
</template>
<script>
import API from '@/plugins/axios'
import formatDate from "@/mixins/formatDate";
import SW from 'sweetalert2'
import { SwalSuccess, SwalConfirm, SwalInfo, SwalWarning } from '@/plugins/sweetalert2'
import moment from 'moment'
import { SwalFailed } from '../../plugins/sweetalert2'
import axios from '../../plugins/axios'

export default {
  mixins: [
    formatDate
  ],

  data () {
    return {
      shipmentId: null,
      loading: false,
      modalShow: false,
      isEditAble: true,
      title: 'Cargo Manifest',
      referenceNumber: '',
      units: [
        {
          key: 'Unit',
          label: 'Unit'
        },
        {
          key: 'Pcs',
          label: 'Pcs'
        },
        {
          key: 'MT',
          label: 'MT'
        },
        {
          key: 'Liter',
          label: 'Liter'
        },
      ],
      manifestList: [
        {
          item: '',
          serial_number: '',
          description: '',
          qty: '',
          unit: '',
        }
      ],
      lastUpdated : null
    }
  },

  methods: {
    reset(){
      this.loading = false
      this.shipmentId = null
      this.statusList = null
      this.selectedStep = null
      this.referenceNumber = ''
      this.manifestList = [
         {
          item: '',
          serial_number: '',
          description: '',
          qty: '',
          unit: '',
        }
      ]
      this.lastUpdated = null
    },

    selectStep(step){
      this.selectedStep = step;
      //mark as active
      this.statusList.forEach(item => {
        if(item.sort_order <= step.sort_order){
          item.id = -1
        }else{
          item.id = null;
        }
      })
    },

    show(id_shipment, isEditAble){
      this.reset()

      this.shipmentId = id_shipment
      this.isEditAble = isEditAble
      this.modalShow = true

      this.getManifestData()
    },

    addManifest(){
      this.manifestList.push({
        item: '',
        serial_number: '',
        description: '',
        qty: '',
        unit: '',
      })
    },

    removeManifest(index){
      //remove specified index from list
      this.manifestList.splice(index, 1)
    },

    getManifestData(){
      const vm = this

      vm.loading = true

      const endpoint = `/transaction/shipment/shipment-manifest/read?shipment_id=${vm.shipmentId}`

      API.get(endpoint)
        .then((response) => {
          const meta = response.data
          if (meta.code === 200) {
            const results = meta.data
            vm.manifestList = results.manifest
            vm.referenceNumber = results.reference_number

            if(results.manifest.length < 1){
              vm.addManifest()
            }else{
              results.manifest.map(item => {
                vm.lastUpdated = item.updated_at
                //stop
                return false
              })
            }
          }

          vm.loading = false
        })
        .catch((error) => {
          vm.loading = false
        })
    },

    saveManifest(e){
      e.preventDefault()

      const vm = this
      const endpoint = `/transaction/shipment/shipment-manifest/submit`

      vm.loading = true

      API.post(endpoint, {
        shipment_id: vm.shipmentId,
        reference_number: vm.referenceNumber,
        data: vm.manifestList
      })
        .then((response) => {
          const meta = response.data
          if (meta.code === 200) {
            const results = meta.data

            //show swal success
            SwalSuccess(meta.message)
          }

          vm.loading = false
        })
        .catch((error) => {
          vm.loading = false
        })
    },

    async openManifestLogs() {
       this.$store.commit("SET_SHOW_MANIFEST_LOGS", {
         shipmentId : this.shipmentId,
         openDate : new Date(),
       });
    },
  }
}
</script>
<style lang="scss">
.divider{
  border-bottom:1px solid #ccc;
}
.pointer{
  cursor: pointer;
}
</style>
