<template>
  <div>
    <div id="form-add-vessel">
      <loading-dialog :show="isLoading" />
      <div class="row">
        <div class="col-10">
          <h3 class="font-weight-bold">
            ADD VESSEL
          </h3>
        </div>
        <div class="col-2">
          <router-link to="../app">
            <div class="close-tip c-pointer float-right">
              &times;
            </div>
          </router-link>
        </div>
      </div>
      <ValidationObserver ref="form">
        <div class="group-form">
          <div class="form-group row">
            <div class="col-lg-12">
              <label>Vessel Type</label>
              <ValidationProvider
                v-slot="{ errors }"
                name="Vessel Type"
                rules="required"
              >
                <select
                  id="vesseltype"
                  v-model.trim="vessel_type"
                  class="custom-select"
                  :class="{'is-invalid': errors[0]}"
                  @change="loadCustomField"
                >
                  <option
                    v-for="(vesselType, index) in dataDropdown"
                    :key="index"
                    :value="vesselType.id"
                  >
                    {{ vesselType.name }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <FormVessel
          :data="detailCustomField"
          :type="vessel_type"
          @values="updateForm"
        />

        <!-- Ship particular atau document terkait dengan vessel -->
        <div class="group-form">
          <div class="form-group row">
            <h4>Ship Particular</h4>
            <div class="col-lg-12">
              <small>File yang di upload harus memiliki ekstensi <mark>JPG</mark>,
                <mark>PNG</mark> atau <mark>PDF</mark>
              </small>
            </div>
          </div>

          <br>
          <div class="large-12 medium-12 small-12 cell">
            <div
              v-cloak
              class="upload-room"
              :class="{ active: hoverDropFiles }"
              @click="addFiles()"
              @drop.prevent="handleDrop"
              @dragover.prevent
              @dragenter="hoverDropFiles = true"
              @dragleave="hoverDropFiles = false"
            >
              <input
                id="files"
                ref="files"
                class="files-upload"
                type="file"
                multiple
                :disabled="actionDisabled"
                @change="handleClick"
              >
              <span class="title-files-upload">Select or drag files here</span>
            </div>
          </div>
          <br>
          <div class="large-12 medium-12 small-12 cell">
            <button
              class="btn btn-primary btn-sm"
              :disabled="actionDisabled"
              @click="addFiles()"
            >
              Add Files
            </button>
            <button
              class="btn btn-warning btn-sm"
              :disabled="actionDisabled"
              @click="clearFiles()"
            >
              Clear Files
            </button>
            <span class="float-right total-size">Total files size : {{ convertFileSize(sizeFiles) }}</span>
          </div>
          <br>
          <div class="large-12 medium-12 small-12 cell">
            <ul
              v-for="(file, index) in files"
              :key="index"
              class="list-group"
            >
              <li class="list-group-item">
                <span class="badge badge-primary mr-3">
                  {{ index + 1 }}
                </span>
                {{ file.name }}
                <span class="badge badge-secondary ml-2">{{ convertFileSize(file.size) }}</span>
                <span
                  class="remove-file"
                  @click="removeFile(index, file.size)"
                >&times;</span>
              </li>
            </ul>
            <div
              v-if="actionDisabled"
              class="d-flex flex-column align-items-center"
            >
              {{ uploadPrecentage }}%
              <div class="progress w-50">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  :aria-valuenow="uploadPrecentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="{ width: uploadPrecentage + '%' }"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- End ship particular -->

        <div class="row float-right mr-5 pr-5 pb-5">
          <router-link to="../app">
            <button
              class="btn btn-secondary mr-2"
              :disabled="actionDisabled"
            >
              Cancel
            </button>
          </router-link>
          <button
            class="btn btn-primary mr-5"
            :disabled="actionDisabled"
            @click="submitVessel"
          >
            Add vessel
          </button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import LoadingDialog from "@/components/dialogs/LoadingDialog"
import { mapState } from "vuex"

import { SwalConfirm, SwalWarning, SwalSuccess } from "../../plugins/sweetalert2";
import API from "../../plugins/axios"
import FormVessel from '@/components/FormVessel.vue'
import formatValidation from "@/mixins/formatValidation";

export default {
  components: {
    FormVessel,
    'loading-dialog': LoadingDialog
  },
  mixins: [formatValidation],
  data() {
    return {
      isLoading: false,

      actionDisabled: false,

      vessel_type: null,
      formData: {},
      detailCustomField: {},

      hoverDropFiles: false,
      files: [],
      sizeFiles: 0,
      maxFiles: 10,
      maxSizeFiles: 2097152,
      maxTotalSizeFiles: 20971520,
      uploadPrecentage: 0
    }
  },

  computed: {
    ...mapState('moduleVesselType', [
      'dataDropdown'
    ]),
    company () {
      let data = JSON.parse(localStorage.getItem('userData'))
      return data.company.id
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$store.dispatch('moduleVesselType/LOAD_DROPDOWN')
    },
    updateForm(evt) {
      this.formData = evt;
    },

    async loadCustomField () {
      const vi = this
      const paramsData = {
        id: vi.vessel_type
      }
      if (!vi.isLoading) vi.isLoading = true

      await API.get('master/vessel-type/detail-validation', {
        params: paramsData
      }).then((res) => {
        const data = res.data.data.data_validate
        vi.detailCustomField = data
      }).catch((err) => {
        console.log(err.response.data.message)
      }).then(() => {
        if (vi.isLoading) vi.isLoading = false
      })
    },

    submitVessel () {
      const vm = this

      this.$refs.form.validate().then(success => {
        if (!success || vm.files.length === 0) {
          if (!success) {
            SwalWarning ('Mohon isi field yang belum di isi.')
          } else {
            SwalWarning ('Mohon sertakan particulars & document lainnya.')
          }
        } else {
          SwalConfirm ('Anda yakin ingin menambahkan vessel baru ?', () => {
            vm.formData['vessel_type'] = vm.vessel_type
            API.post("master/vessel/create-custom", vm.formData)
              .then(async (response) => {
                const meta = response.data
                await vm.submitFiles(meta.data.vessel)
              })
              .catch((error) => {
                SwalWarning (error.response.data.message)
              })
              .finally(() => {
              })
          })
        }
      })
    },
    // Submits files to the server
    submitFiles(id_vessel) {
      let vm = this;
      vm.actionDisabled = true
      let formFiles = new FormData();
      for (let i = 0; i < vm.files.length; i++) {
        formFiles.append("id", id_vessel);
        formFiles.append("files[" + i + "]", vm.files[i]);
      }
      for (var value of formFiles.values()) {
        console.log(value);
      }
      console.log("Before sumbited", vm.files);
      API.post("master/vessel/doc/upload", formFiles, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        //FUNGSI INI YANG MEMILIKI PERAN UNTUK MENGUBAH SEBERAPA JAUH PROGRESS UPLOAD FILE BERJALAN
        onUploadProgress: function(progressEvent) {
          //DATA TERSEBUT AKAN DI ASSIGN KE VARIABLE progressBar
          this.uploadPrecentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }.bind(this),
      })
      .then(() => {
        SwalSuccess ('Anda berhasil menambahkan vessel baru', () => vm.$router.push({ name:'app' }) )
      })
      .catch((error) => {
        if (error.response.data.code === 400) {
          SwalWarning ('Upload files wajib di isi!')
        }
        this.uploadPrecentage = 0;
      }).finally(() => {
        vm.actionDisabled = false
        vm.$refs.form.reset();
      })
    },

    // Convert file size
    convertFileSize(bytes, decimals) {
      if (bytes == 0) return "0 Bytes";
      var k = 1024,
          dm = decimals <= 0 ? 0 : decimals || 2,
          sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    // Adds a file
    addFiles() {
      this.$refs["files"].click();
      // console.log(this.files);
    },

    handleDrop (e) {
      const uploadedFiles = e.target.files || e.dataTransfer.files
      this.handleUpload(uploadedFiles)
    },

    handleClick () {
      const uploadedFiles = this.$refs.files.files
      this.handleUpload(uploadedFiles)
    },

    handleUpload (event) {
      const uploadedFiles = event
      const vm = this

      let tempSize = vm.sizeFiles

      //Count total file size and last size of the file
      for (let i = 0; i < uploadedFiles.length; i++) {

        if (uploadedFiles[i].size <= vm.maxSizeFiles) {
          // check the file type
          if (vm.fileTypeValidation(uploadedFiles[i].type)) {
            tempSize = tempSize + uploadedFiles[i].size
          }
        }
      }

      if (uploadedFiles.length + vm.files.length > vm.maxFiles) {
        // Total files rejected if more than 10
        SwalWarning ({ title: 'File rejected!', text: 'File tidak boleh lebih dari 10' }, () => vm.$refs.files.value = '')

      } else if (vm.sizeFiles > vm.maxTotalSizeFiles) {
        // Total file size rejected if more than 20mb
        SwalWarning ({ title: 'File rejected!', text: 'Maksimal keseluruhan files maksimal \'20MB\'' }, () => vm.$refs.files.value = '')
      } else {
        let x = Boolean
        //Adds the uploaded file to the files array
        for (let i = 0; i < uploadedFiles.length; i++) {
          // check the file type
          if (vm.fileTypeValidation(uploadedFiles[i].type)) {
            if (uploadedFiles[i].size <= vm.maxSizeFiles) {
              x = true
            } else {
              SwalWarning ({ title: 'File rejected!', text: 'File size lebih dari \'2MB\'' })
              vm.$refs.files.value = '' // reset file input
              x = false
              break
            }
          } else {
            // Files rejected if the files formated not equal PNG/JPG/PDF
            SwalWarning ({title: 'File rejected!', text:'Semua file harus memiliki extensi \'PDF/JPG/PNG\''})
            vm.$refs.files.value = '' // reset file input
            break
          }
        }
        if (x) {
          // finall
          vm.sizeFiles = tempSize
          for (let j = 0; j < uploadedFiles.length; j++) {
            vm.files.push(uploadedFiles[j])
          }
        }
      }
    },

    // Removes a select file the user has uploaded
    removeFile(key, size) {
      this.$refs.files.value = '' // reset file input
      Array.prototype.slice.call(this.$refs.files, key, 1)
      this.files.splice(key, 1)
      this.sizeFiles = this.sizeFiles - size
    },
    // removeFile(file) {
    //   this.files = this.files.filter(f => {
    //     return f != file;
    //   });
    // },

    clearFiles() {
      this.$refs.files.value = ''
      this.files = []
      this.sizeFiles = 0
      this.uploadPrecentage = 0
    }
  }
}
</script>
