<template>
  <div>
    <div class="avatar-upload">
      <!-- <div class="avatar-edit" @click="clickUpload">
          <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" ref="imageUpload" v-on:change="handleFileUpload()"/>
          <label>
              <i class="fa fa-pencil-alt fa-lg"></i>
          </label>
      </div> -->
      <div class="avatar-preview">
        <img
          id="imagePreview"
          :src="avatarRead"
          alt="avatar"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerticalNavigation',
  data() {
    return {
      avatar: '',
      avatarRead: '/images/image_user.png'
    }
  },
  methods: {
    clickUpload() {
      this.$refs["imageUpload"].click();
    },

    handleFileUpload(){
      let reader = new FileReader()
      let vm = this

      vm.avatar = this.$refs.imageUpload.files[0]

      reader.onload = (e) => {
        vm.avatarRead = e.target.result
      };
      reader.readAsDataURL(vm.avatar)
    },
  },
}
</script>

<style scoped>
  .no-background {
    background-color: transparent;
  }
</style>
