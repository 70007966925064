export default {
  methods: {
    // Format date such : 30 December 9999 1:20pm
    ddMMyyyyt (myDate) {
      let currentDate = new Date(myDate)
      var day = currentDate.getDate()
      var month = currentDate.getMonth()
      var year = currentDate.getFullYear()
      let hours = currentDate.getHours()
      let minutes = currentDate.getMinutes()

      if (day < 10) {
        day = "0" + day
      }

      let months = ['January', 'February', 'March', 'April', 'May', 'Juni', 'July', 'Augustus', 'September', 'October', 'November', 'December']

      minutes = minutes < 10 ? '0'+ minutes : minutes
      let strTime = hours + ':' + minutes
      if(myDate) return `${day} ${months[month]} ${year} ${strTime}`
      else return '-'
    },

    // format date ke 30 December 1997
    ddMMyyyy (myDate) {
      let currentDate = new Date(myDate)
      var day = currentDate.getDate()
      var month = currentDate.getMonth()
      var year = currentDate.getFullYear()
      if (day < 10) {
        day = "0" + day
      }

      let months = ['January', 'February', 'March', 'April', 'May', 'Juni', 'July', 'Augustus', 'September', 'October', 'November', 'December']
      if (myDate) return `${day} ${months[month]} ${year}`
      else  return '-'
    },

    // format date ke 30 12 1997
    ddmmyyyy (myDate) {
      let currentDate = new Date(myDate)
      var day = currentDate.getDate()
      var month = currentDate.getMonth() + 1 //January is 0!
      var year = currentDate.getFullYear()

      if (day < 10) {
        day = "0" + day
      }
      if (month < 10) {
        month = "0" + month
      }
      if (myDate) return `${day} ${month} ${year}`
      else return '-'
    },

    // format date ke 30/12/9999
    ddmmyyySlash(myDate) {
      var currentDate = new Date(myDate)
      var day = currentDate.getDate()
      var month = currentDate.getMonth() + 1 //January is 0!
      var year = currentDate.getFullYear()
      
      if (day < 10) {
        day = "0" + day
      }
      if (month < 10) {
        month = "0" + month
      }
      if (myDate) return `${day}/${month}/${year}`
      else return '-'
    },

    // format date ke 30-12-9999
    ddmmyyyyStripe(myDate) {
      var currentDate = new Date(myDate)
      var day = currentDate.getDate()
      var month = currentDate.getMonth() + 1 //January is 0!
      var year = currentDate.getFullYear()

      if (day < 10) {
        day = "0" + day
      }
      if (month < 10) {
        month = "0" + month
      }
      if (myDate) return `${day}-${month}-${year}`
      else return '-'
    },

    // Added day and hours
    addDate: function(myDate, addDay, addHours) {
      var currentDate = new Date(myDate)
      var day = currentDate.getDate()
      let hours = currentDate.getHours()
      
      currentDate.setDate(day + addDay)
      currentDate.setHours(hours + addHours )
      return currentDate
    },
  },
}