<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="show"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      centered
      :title="data.group"
    >
      <b-overlay
        :show="isLoading"
        rounded="sm"
        spinner-type="grow"
        spinner-small
        variant="white"
        opacity="1"
      >
        <ValidationObserver ref="form">
          <div
            v-for="(field, index) in customFieldByGroup"
            v-show="!isLoading"
            :key="index"
            class="row my-4"
          >
            <div class="col-md-12">
              <label>{{ field.label }}</label>
              <label
                v-if="findRequired(field.mandatory)"
                class="text-info ml-2"
              > (Optional) </label>

              <ValidationProvider
                v-slot="{ errors }"
                :name="field.label"
                :rules="field.mandatory"
              >
                <div class="input-group">
                  <input
                    v-if="field.type === 'text'"
                    v-model="formUpdate[field.field]"
                    type="text"
                    class="form-control"
                    :class="{'is-invalid': errors[0]}"
                  >
                  <label
                    v-else-if="field.field === 'vessel_type' && dataVesselType"
                    class="text-muted"
                  > {{ dataVesselType.name }}</label>
                  <select
                    v-else-if="field.type === 'dropdown' &&
                      (field.field === 'build_year' ||
                        field.field === 'main_engine_build_year' ||
                        field.field === 'aux_engine_build_year')"
                    v-model="formUpdate[field.field]"
                    class="custom-select"
                    :class="{'is-invalid': errors[0]}"
                  >
                    <option
                      v-for="(data, index) in dropdownBy"
                      :key="index"
                      :value="data"
                    >
                      {{ data }}
                    </option>
                  </select>

                  <select
                    v-else-if="field.type === 'dropdown' && field.field === 'classification'
                    "
                    v-model="formUpdate[field.field]"
                    class="custom-select"
                    :class="{'is-invalid': errors[0]}"
                  >
                    <option
                      v-for="(data, index) in dropdownClassification"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>

                  <select
                    v-else-if="field.type === 'dropdown' && field.field === 'country'"
                    v-model="formUpdate[field.field]"
                    class="custom-select"
                    :class="{'is-invalid': errors[0]}"
                  >
                    <option
                      v-for="(data, index) in dropdownCountry"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>

                  <select
                    v-else-if="
                      field.type === 'dropdown' &&
                        field.field === 'fuel_type'"
                    v-model="formUpdate[field.field]"
                    class="custom-select"
                    :class="{'is-invalid': errors[0]}"
                  >
                    <option
                      v-for="(data, index) in dropdownFuelType"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>

                  <div class="input-group-append">
                    <span
                      v-show="field.field === 'size_loa' ||
                        field.field === 'size_lbp' ||
                        field.field === 'size_width' ||
                        field.field === 'size_depth'"
                      class="input-group-text"
                      title="Satuan dalam ukuran berat"
                    >
                      Meter
                    </span>
                    <span
                      v-show="field.field === 'gross_tonnage' ||
                        field.field === 'net_tonnage' ||
                        field.field === 'deadweight' ||
                        field.field === 'load_capacity'"
                      class="input-group-text"
                      title="Satuan dalam ukuran berat"
                    >
                      MT
                    </span>
                    <span
                      v-show="field.field === 'horsepower'"
                      class="input-group-text"
                      title="Satuan dalam ukuran horsepower"
                    >
                      HP
                    </span>
                  </div>

                  <div class="invalid-feedback">
                    <!-- Wajib di isi. -->
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>

        <div class="d-flex justify-content-end mt-4">
          <b-button
            type="submit"
            variant="primary"
            @click="onSubmit"
          >
            Submit
          </b-button>
          <b-button
            type="reset"
            variant="danger"
            @click="onCancel"
          >
            Cancel
          </b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import API from '../../plugins/axios'
import {SwalConfirm, SwalWarning} from "../../plugins/sweetalert2";
import formatValidation from '../../mixins/formatValidation'
export default {
  name: "UpdateVessel",
  mixins: [formatValidation],
  props: {
    show: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: Object
    }
  },
  data () {
    return {
      isLoading: false,
      title: 'Update Vessel',
      text: 'You can update data here',
      formUpdate: {},
      // tempFormUpdate: {},
      dropdownFuelType: [
        {
          id: 1,
          name: 'Solar'
        }
      ],
      dropdownQtyType: [
        {
          id: 1,
          name: 'MT'
        }
      ]
    }
  },
  computed: {
    customFieldByGroup () {
      return this.$parent.customField[this.data.group];
    },
    dropdownCountry () {
      return this.$store.state.moduleCountry.dataDropdown
    },
    dropdownClassification () {
      return this.$store.state.moduleClassifications.dataDropdown
    },
    dropdownVesselType () {
      return this.$store.state.moduleVesselType.dataDropdown
    },
    dataVesselType () {
      if (this.dropdownVesselType.length > 0) {
        return this.dropdownVesselType.find( el => el.id === this.formUpdate.vessel_type)
      } else {
        return false
      }
    },
    dropdownBy () {
      return this.$store.state.moduleVessel.buildYear
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.loadDetailData()
      }
    }
  },
  created () {
    this.loadDropdown()
  },
  methods: {
    loadDetailData () {
      const vm = this
      const data = {
        id: vm.$route.params.id
      }
      vm.isLoading = true
      API.get('master/vessel/detail', {params: data})
        .then((res) => {
          const data = res.data.data
          vm.formUpdate = {
            id: data.id,
            name: data.name,
            callsign: data.callsign,
            imo: data.imo_number,
            stamp_name: data.stamp,
            company: data.company.id,
            shipyard: data.shipyard_name,
            build_year: data.build_year,
            country: data.country.id,
            classification: data.classification.id,
            vessel_type: data.vessel_type.id,
            size_loa: data.size.loa,
            size_lbp: data.size.lbp,
            size_width: data.size.width,
            size_depth: data.size.depth,
            summer_draft: data.draft.summer,
            winter_draft: data.draft.winter,
            tropical_draft: data.draft.tropical,
            freshwater_draft: data.draft.freshwater,
            gross_tonnage: data.weight.gross,
            net_tonnage: data.weight.net,
            deadweight: data.weight.deadweight,
            load_capacity: data.load_capacity,
            main_engine: data.engine.main.engine,
            main_engine_build_year: data.engine.main.build_year,
            main_engine_number: data.engine.main.number,
            horsepower: data.engine.horsepower,
            fuel_type: data.fuel_type.id,
            aux_engine: data.engine.aux.engine,
            aux_engine_build_year: data.engine.aux.build_year,
            aux_engine_number: data.engine.aux.number
          }
          vm.formUpdate['load_capacity_type'] = 1
        })
      .catch(() => {})
      .then(() => {
        // this.createForm()
        vm.isLoading = false
      })
    },
    // async createForm () {
    //   this.formUpdate = {}
    //   this.customFieldByGroup.forEach( el => {
    //     this.formUpdate[el.field] = this.tempFormUpdate[el.field]
    //   })
    // },
    onSubmit () {
      const vm = this
      this.$refs.form.validate().then(success => {
        if (!success) {
          SwalWarning ('Mohon isi field (wajib) yang belum di isi.')
        } else {
          SwalConfirm ('Anda yakin ingin mengupdate vessel ini ?', async () => {
            await vm.$store.dispatch('moduleVessel/UPDATE_SELECTED', vm.formUpdate)
            this.onCancel()
          })
        }
      })
    },
    onCancel () {
      this.$emit('toggle')
    },
    loadDropdown () {
      this.$store.dispatch('moduleCountry/LOAD_DROPDOWN')
      this.$store.dispatch('moduleClassifications/LOAD_DROPDOWN')
      this.$store.dispatch('moduleVesselType/LOAD_DROPDOWN')
      this.$store.commit('moduleVessel/COUNT_BUILD_YEAR')
    }
  },
}
</script>

<style scoped>

</style>
