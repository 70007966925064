var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},_vm._l((_vm.data),function(dataValue,dataKey){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.findLoadCapacity(dataValue)),expression:"findLoadCapacity(dataValue)"}],key:dataKey,staticClass:"group-form"},[_c('div',{staticClass:"form-group row"},[_c('h4',[_vm._v(_vm._s(dataKey))])]),_vm._l((dataValue),function(value,fieldKey){return _c('div',{key:fieldKey,staticClass:"form-group row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',[_vm._v(_vm._s(value.label))]),_vm._v(" "),(_vm.findRequired(value.mandatory))?_c('label',{staticClass:"text-info"},[_vm._v(" (Optional) ")]):_vm._e(),_c('ValidationProvider',{attrs:{"name":value.label,"rules":value.mandatory},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group"},[(value.type === 'text')?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.dataSend[value.field]),expression:"dataSend[value.field]",modifiers:{"trim":true}}],staticClass:"form-control",class:{'is-invalid': errors[0]},attrs:{"type":"text"},domProps:{"value":(_vm.dataSend[value.field])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataSend, value.field, $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}):(
                value.type === 'dropdown' &&
                  (value.field === 'build_year' ||
                    value.field === 'main_engine_build_year' ||
                    value.field === 'aux_engine_build_year'))?_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.dataSend[value.field]),expression:"dataSend[value.field]",modifiers:{"trim":true}}],staticClass:"custom-select",class:{'is-invalid': errors[0]},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataSend, value.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.dropdownBy),function(data,index){return _c('option',{key:index,domProps:{"value":data}},[_vm._v(" "+_vm._s(data)+" ")])}),0):(
                value.type === 'dropdown' &&
                  value.field === 'classification'
              )?_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.dataSend[value.field]),expression:"dataSend[value.field]",modifiers:{"trim":true}}],staticClass:"custom-select",class:{'is-invalid': errors[0]},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataSend, value.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.dropdownClassification),function(data,index){return _c('option',{key:index,domProps:{"value":data.id}},[_vm._v(" "+_vm._s(data.name)+" ")])}),0):(
                value.type === 'dropdown' &&
                  value.field === 'country')?_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.dataSend[value.field]),expression:"dataSend[value.field]",modifiers:{"trim":true}}],staticClass:"custom-select",class:{'is-invalid': errors[0]},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataSend, value.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.dropdownCountry),function(data,index){return _c('option',{key:index,domProps:{"value":data.id}},[_vm._v(" "+_vm._s(data.name)+" ")])}),0):(
                value.type === 'dropdown' &&
                  value.field === 'fuel_type')?_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.dataSend[value.field]),expression:"dataSend[value.field]",modifiers:{"trim":true}}],staticClass:"custom-select",class:{'is-invalid': errors[0]},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataSend, value.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.dropdownFuelType),function(data,index){return _c('option',{key:index,domProps:{"value":data.id}},[_vm._v(" "+_vm._s(data.name)+" ")])}),0):_vm._e(),_c('div',{staticClass:"input-group-append"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(value.field === 'size_loa' ||
                  value.field === 'size_lbp' ||
                  value.field === 'size_width' ||
                  value.field === 'size_depth'),expression:"value.field === 'size_loa' ||\n                  value.field === 'size_lbp' ||\n                  value.field === 'size_width' ||\n                  value.field === 'size_depth'"}],staticClass:"input-group-text",attrs:{"title":"Satuan dalam ukuran berat"}},[_vm._v(" Meter ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(value.field === 'gross_tonnage' ||
                  value.field === 'net_tonnage' ||
                  value.field === 'deadweight' ||
                  value.field === 'load_capacity'),expression:"value.field === 'gross_tonnage' ||\n                  value.field === 'net_tonnage' ||\n                  value.field === 'deadweight' ||\n                  value.field === 'load_capacity'"}],staticClass:"input-group-text",attrs:{"title":"Satuan dalam ukuran berat"}},[_vm._v(" MT ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(value.field === 'horsepower'),expression:"value.field === 'horsepower'"}],staticClass:"input-group-text",attrs:{"title":"Satuan dalam ukuran horsepower"}},[_vm._v(" HP ")])]),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)})],1)])})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }