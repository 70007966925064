<template>
  <div>
    <div class="row m-0">
      <div class="col-2 tab-tag pl-4 pr-0">
        <p class="mt-2 mb-2">Filter :</p>
      </div>
      <div class="col-10 tab-tag pl-0">
        <button
          v-for="(tag, index) in tags"
          :key="index"
          class="btn btn-outline-primary btn-sm"
          :class="{ active: tag.active }"
          @click="loadData(tag.filter)"
        >
          {{ tag.name }}
        </button>
      </div>
    </div>

    <div class="position-relative">
      <div id="content-list" class="scroll-list">
        <FabSidenav url="../vessels/create" color="blue" />

        <ul v-if="listData.length > 0" class="list-group">
          <li
            v-for="(list, index) in listData"
            :key="index"
            class="list-group-item card-vessel c-pointer py-3"
          >
            <div @click="showDetail(list.id)">
              <div class="d-flex w-100 justify-content-between">
                <div>
                  <span
                    class="badge badge-pill"
                    :class="`badge-${list.color}`"
                    >{{ list.valid }}</span
                  >
                  <span
                    v-if="
                      addDate(new Date(list.created_at), 0, 12) > new Date()
                    "
                    class="badge badge-pill badge-warning ml-2"
                    >New</span
                  >
                </div>
                <small
                  v-if="list.valid === 'Verified'"
                  class="text-muted font-italic"
                  >License {{ list.license }}</small
                >
              </div>

              <div class="title-vessel">
                {{ list.name }}
              </div>
              <small class="text-vessel">
                {{ list.vessel_type }}
                <small v-if="list.vessel_type !== 'Tug Boat'" class="text-info"
                  >, {{ thausandSparator(list.load_capacity) }}</small
                >
              </small>
            </div>

            <div class="text-right">
              <router-link
                :to="'/vessel-engines/list/' + list.id"
                :class="'btn btn-xs ' + renderEngineButtonClass(list.engine_health)"
              >
                <i class="fa fa-cogs"></i>&nbsp;&nbsp;Engine Health
              </router-link>
              <button
                v-if="list.is_history_exist"
                class="btn btn-primary btn-xs"
                @click="showPositionHistory(list)"
              >
                <i class="fa fa-history"></i>&nbsp;&nbsp;View History
              </button>
            </div>
          </li>
        </ul>

        <div
          v-else-if="listData < 1"
          class="alert alert-warning text-center"
          role="alert"
        >
          <i class="fa fa-info mr-2" /> No records available
        </div>
      </div>
    </div>

    <div id="pagination" class="pages-tab">
      <div class="row no-gutters count-record">
        <div class="col-12">
          <div class="text-center">Total Records: {{ totalRecords }}</div>
        </div>
      </div>
      <!-- Button Action Next and Previous -->
      <div class="row no-gutters">
        <div class="col-4 pl-4">
          <button
            class="btn btn-secondary btn-sm"
            :disabled="currentPage <= 1"
            @click="previousPage()"
          >
            Previous
          </button>
        </div>
        <div class="col-4 pl-1 pr-1">
          <div class="pages">{{ currentPage }} of {{ perPage || 1 }}</div>
        </div>
        <div class="col-4 pr-4">
          <button
            class="btn btn-secondary btn-sm float-right"
            :disabled="currentPage >= perPage"
            @click="nextPage()"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <PositionHistory ref="position-history" />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import API from "../../plugins/axios";
import formatDate from "../../mixins/formatDate";
import formatChar from "../../mixins/formatChar";
import FabSidenav from "../../components/FabSidenav";
import { icon } from "leaflet";
import PositionHistory from "@/components/modals/PositionHistory";

const NAMESPACE_INTERVAL = "VESSEL_LIST_INTERVAL_DATA";

export default {
  name: "VesselList",
  components: {
    FabSidenav,
    PositionHistory,
  },
  mixins: [formatDate, formatChar],
  data() {
    return {
      intervalRequest: 300000,
      startPage: 0,
      lengthPage: 10,
      currentPage: 1,
      perPage: null,
      listData: Array,
      totalRecords: null,
      filter: "",
      tags: [
        { name: "All", filter: "", active: true },
        { name: "Verified", filter: 1, active: false },
        { name: "Pending", filter: 0, active: false },
        { name: "Rejected", filter: 2, active: false },
      ],
    };
  },
  computed: {
    ...mapState(["currentVesselTrail"]),
  },
  mounted() {
    this.loadData("");

    this.actionIntervalData();
  },
  methods: {
    ...mapMutations("moduleVessel", {
      setListData: "SET_LIST_DATA",
    }),

    renderEngineButtonClass(engineHealth){
      if(engineHealth === 'alert'){
        return 'btn-danger';
      }
      else if(engineHealth === 'caution'){
        return 'btn-warning';
      }
      else if(engineHealth === 'normal'){
        return 'btn-success';
      }
      else{
        return 'btn-secondary';
      }
    },

    showPositionHistory(data) {
      this.$refs["position-history"].show(data);
    },

    setLoading() {
      this.$store.commit("SET_LOADING");
    },

    showDetail(data) {
      this.$router.push({
        name: "detail-vessel",
        params: {
          id: data,
        },
      });
    },

    previousPage() {
      this.startPage = this.startPage - this.lengthPage;
      this.currentPage -= 1;
      this.loadData(this.filter);
    },

    nextPage() {
      this.startPage = this.startPage + this.lengthPage;
      this.currentPage += 1;
      this.loadData(this.filter);
    },

    activeTag(filter) {
      this.tags.find((el) => {
        el.filter === filter ? (el.active = true) : (el.active = false);
      });
    },

    async loadData(filter) {
      const vm = this;

      vm.filter = filter;
      vm.activeTag(vm.filter);
      vm.setLoading();

      const params = {
        "columns[0][data]": "0",
        "columns[0][name]": "id",
        "columns[0][searchable]": "true",
        "columns[0][orderable]": "true",
        "columns[0][search][value]": "",
        "columns[0][search][regex]": "false",
        "order[0][column]": "0",
        "order[0][dir]": "desc",
        start: vm.startPage,
        filter: filter,
        length: vm.lengthPage,
      };

      let endpoint = "master/vessel";
      await API.get(endpoint, { params })
        .then((response) => {
          const meta = response.data;
          if (meta.code === 200) {
            vm.totalRecords = meta.recordsFiltered;
            const data = meta.data;
            vm.listData = [];
            data.forEach((element) => {
              //normalize lat & lng
              let lat = element.latitude;
              let lng = element.longitude;
              if (!Number.isInteger(lat) && lat != null) {
                lat = lat.replace(",", ".");
                lat = parseFloat(lat);
              }

              if (!Number.isInteger(lng) && lng != null) {
                lng = lng.replace(",", ".");
                lng = parseFloat(lng);
              }

              //get position history
              let positionData = [];
              let positions = element.position_history;
              positions.forEach((position, index) => {
                //normalize lat & lng
                let lat = position.latitude;
                let lng = position.longitude;
                if (!Number.isInteger(lat)) {
                  lat = lat.replace(",", ".");
                  lat = parseFloat(lat);
                }

                if (!Number.isInteger(lng)) {
                  lng = lng.replace(",", ".");
                  lng = parseFloat(lng);
                }

                //get rotation
                var rotation = 0;
                if (index > 0) {
                  let positionAfter = positions[index - 1];

                  var lat1 = lat;
                  var lat2 = positionAfter.latitude;
                  var long1 = lng;
                  var long2 = positionAfter.longitude;

                  var dLon = long2 - long1;
                  var y = Math.sin(dLon) * Math.cos(lat2);
                  var x =
                    Math.cos(lat1) * Math.sin(lat2) -
                    Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
                  var brng = Math.atan2(y, x);

                  brng = brng * (180 / Math.PI);
                  brng = (brng + 360) % 360;
                  rotation = brng; // count degrees counter-clockwise - remove to make clockwise
                }

                //set icon
                let iconData = null;
                let label = "";

                if (index > 0) {
                  iconData = icon({
                    iconUrl:
                      position.speed <= 0
                        ? "/images/icon-no-speed.png"
                        : "/images/icon-wind-direction.png",
                    iconSize: [16, 16],
                    iconAnchor: [8, 7],
                  });

                  label = element.name + " position<br/>" + lat + ", " + lng;
                }

                return positionData.push({
                  name: element.name,
                  vessel_type: element.vessel_type,
                  provider: element.source_provider,
                  load_capacity: element.load_capacity,
                  timestamp: position.datetime,
                  course: position.course,
                  speed: position.speed,
                  lat: lat,
                  lng: lng,
                  icon: iconData,
                  rotation: rotation,
                  label: label,
                  windspeedKmph: position.windspeedKmph,
                  winddirDegree: position.winddirDegree,
                  swellHeight_m: position.swellHeight_m,
                  swellDir: position.swellDir,
                  tides: position.tides,
                  address: position.address,
                });
              });

              vm.listData.push({
                classification: element.classification,
                company: element.company,
                created_at: element.created_at,
                id: element.id,
                license: element.license,
                load_capacity: element.load_capacity,
                name: element.name,
                payment_license: element.payment_license,
                require_tugboat: element.require_tugboat,
                user_create: element.user_create,
                valid:
                  element.valid === "Valid"
                    ? "Verified"
                    : element.valid === "Rejected"
                    ? "Rejected"
                    : "Pending",
                color:
                  element.valid === "Valid"
                    ? "primary"
                    : element.valid === "Rejected"
                    ? "danger"
                    : "secondary",
                vessel_type: element.vessel_type,
                latitude: lat,
                longitude: lng,
                heading: element.heading,
                course: element.course,
                speed: element.speed,
                timestamp: element.timestamp,
                windspeedKmph: element.windspeedKmph,
                winddirDegree: element.winddirDegree,
                swellHeight_m: element.swellHeight_m,
                swellDir: element.swellDir,
                tides: element.tides,
                provider: element.source_provider,
                position_history: positionData,
                show_history:
                  this.currentVesselTrail !== null
                    ? this.currentVesselTrail.id === element.id
                      ? true
                      : false
                    : false,
                is_history_exist: element.is_history_exist,
                address: element.address,
                engine_health: element.engine_health
              });
            });

            this.setListData(this.listData);
          }
        })
        .catch((error) => {
          console.log("Vessel", error);
          this.listData = [];
          this.setListData(this.listData);
        })
        .finally(() => {
          vm.setLoading();
          vm.perPage = Math.ceil(vm.totalRecords / vm.lengthPage);
        });
    },

    actionIntervalData() {
      const interval = setInterval(() => {
        this.loadData(this.filter);
      }, this.intervalRequest);

      window[NAMESPACE_INTERVAL] = interval;
    },
  },

  destroyed() {
    clearInterval(window[NAMESPACE_INTERVAL]);
  },
};
</script>

<style></style>
