<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="showModal"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      centered
      scrollable
      :title="title"
      @hide="onCancel"
    >
      <b-overlay
        :show="isLoading"
        rounded="sm"
        spinner-type="grow"
        spinner-small
        variant="white"
        opacity="1"
      >
        <b-list-group v-if="data.length > 0">
          <b-list-group-item
            v-for="(record, index) in data"
            :key="index"
            href="#"
            class="d-flex flex-column align-items-start"
            @click="onClick(record.id)"
          >
            <div class="d-flex w-100 justify-content-between align-items-center">
              <h6 class="mb-1">
                #{{ record.id }}
              </h6>
            </div>

            <b-row class="small">
              <b-col cols="3">
                User name
              </b-col>
              <b-col cols="9">
                {{ record.user_request }}
              </b-col>
              <div class="w-100" />
              <b-col cols="3">
                Date request
              </b-col>
              <b-col cols="9">
                {{ $parent.ddMMyyyy(record.request_payment_license) }}
              </b-col>
            </b-row>
            <div class="d-flex w-100 justify-content-end">
              <small
                class="font-italic"
                :class="`text-${record.variant}`"
              >{{ record.status }}.</small>
            </div>
          </b-list-group-item>
        </b-list-group>
        <b-list-group v-else>
          <b-list-group-item
            href="#"
            class="flex-column align-items-start"
          >
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1">
                No Records Payment License
              </h6>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-overlay>
    </b-modal>
    <DetailPaymentLicense
      :show="showModalDetail"
      :data="dataDetail"
      @toggle="showModalDetail = !showModalDetail"
    />
  </div>
</template>

<script>
import DetailPaymentLicense from "./DetailPaymentLicense";
export default {
  name: 'RecordsPaymentLicense',
  components: {DetailPaymentLicense},
  props: {
    show: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: Array
    }
  },
  data () {
    return {
      showModal: false,
      isLoading: false,
      title: 'Records Payment License',
      showModalDetail: false,
      dataDetail: null
    }
  },
  watch: {
    show () {
      this.showModal = this.show
    }
  },
  methods: {
    onCancel () {
      this.$emit('toggle')
    },
    onClick (id) {
      this.showModalDetail = true
      this.dataDetail = id
    }
  },
}
</script>

<style scoped>
</style>

