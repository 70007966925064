<template>
  <div id="resetpassword">
    <div class="form-signin">
      <div class="text-center mb-4">
        <h1 class="h1 mb-3 font-weight-thin">
          Lupa Sandi
        </h1>
        <p><span class="text-muted">Ship Owner</span></p>
      </div>
      <div
        v-if="alerting"
        class="row"
      >
        <div class="col-md-12">
          <div
            class="alert alert-info"
            role="alert"
          >
            Reset password telah dikirim ke <strong>{{ alerting }}</strong>, mohon di cek email anda.
          </div>
        </div>
      </div>
      <div class="form-label-group">
        <input
          id="inputEmail"
          v-model="user_email"
          type="email"
          class="form-control"
          placeholder=""
          required
          autofocus
          @keyup.enter="reset_password"
        >
        <label for="inputEmail">Email address</label>
      </div>


      <button
        v-if="btn_reset"
        class="btn btn-primary btn-block mx-0"
        @click="reset_password"
      >
        Recovery Password
      </button>
      <button
        v-else
        class="btn btn-primary btn-block mx-0"
        disabled
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </button>
      <p class="mt-4 text-center">
        Kembali ke halaman
        <router-link :to="linkLogin">
          Log In
        </router-link>
      </p>
      <p class="mt-2 mb-3 text-muted text-center">
        &copy; 2020
      </p>
    </div>
  </div>
</template>

<script>
import API from "../plugins/axios";
import {SwalSuccess, SwalFailed, SwalWarning} from "../plugins/sweetalert2";

export default {
  data() {
    return {
      user_email: "",
      btn_reset: true,
      alerting: null,
      linkLogin: "/",
      placeholder: {
        email: "example@zonasea.com",
      },
    };
  },
  methods: {
    reset_password() {
      const vm = this
      vm.btn_reset = false
      let endpoint = `auth/forgotpassword`;
      let values = {
        email: vm.user_email,
      };
      API.post(endpoint, values)
        .then((res) => {
          vm.btn_reset = true
          const meta = res.data;
          if (meta.code === 200) {
            SwalSuccess ('Silahkan cek email anda!', () => {
              vm.alerting = this.user_email;
              vm.user_email = null;
            })
          }
        })
        .catch((err) => {
          this.btn_reset = true
          if (typeof err.response === "undefined") {
            SwalFailed (err.response.statusText)
          } else {
            SwalWarning (err.response.data.message)
          }
        })
    },
  },
};
</script>

<style></style>
