var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-center","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","centered":"","title":_vm.data.group},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm","spinner-type":"grow","spinner-small":"","variant":"white","opacity":"1"}},[_c('ValidationObserver',{ref:"form"},_vm._l((_vm.customFieldByGroup),function(field,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],key:index,staticClass:"row my-4"},[_c('div',{staticClass:"col-md-12"},[_c('label',[_vm._v(_vm._s(field.label))]),(_vm.findRequired(field.mandatory))?_c('label',{staticClass:"text-info ml-2"},[_vm._v(" (Optional) ")]):_vm._e(),_c('ValidationProvider',{attrs:{"name":field.label,"rules":field.mandatory},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group"},[(field.type === 'text')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formUpdate[field.field]),expression:"formUpdate[field.field]"}],staticClass:"form-control",class:{'is-invalid': errors[0]},attrs:{"type":"text"},domProps:{"value":(_vm.formUpdate[field.field])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formUpdate, field.field, $event.target.value)}}}):(field.field === 'vessel_type' && _vm.dataVesselType)?_c('label',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.dataVesselType.name))]):(field.type === 'dropdown' &&
                    (field.field === 'build_year' ||
                      field.field === 'main_engine_build_year' ||
                      field.field === 'aux_engine_build_year'))?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formUpdate[field.field]),expression:"formUpdate[field.field]"}],staticClass:"custom-select",class:{'is-invalid': errors[0]},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formUpdate, field.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.dropdownBy),function(data,index){return _c('option',{key:index,domProps:{"value":data}},[_vm._v(" "+_vm._s(data)+" ")])}),0):(field.type === 'dropdown' && field.field === 'classification'
                  )?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formUpdate[field.field]),expression:"formUpdate[field.field]"}],staticClass:"custom-select",class:{'is-invalid': errors[0]},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formUpdate, field.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.dropdownClassification),function(data,index){return _c('option',{key:index,domProps:{"value":data.id}},[_vm._v(" "+_vm._s(data.name)+" ")])}),0):(field.type === 'dropdown' && field.field === 'country')?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formUpdate[field.field]),expression:"formUpdate[field.field]"}],staticClass:"custom-select",class:{'is-invalid': errors[0]},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formUpdate, field.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.dropdownCountry),function(data,index){return _c('option',{key:index,domProps:{"value":data.id}},[_vm._v(" "+_vm._s(data.name)+" ")])}),0):(
                    field.type === 'dropdown' &&
                      field.field === 'fuel_type')?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formUpdate[field.field]),expression:"formUpdate[field.field]"}],staticClass:"custom-select",class:{'is-invalid': errors[0]},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formUpdate, field.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.dropdownFuelType),function(data,index){return _c('option',{key:index,domProps:{"value":data.id}},[_vm._v(" "+_vm._s(data.name)+" ")])}),0):_vm._e(),_c('div',{staticClass:"input-group-append"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(field.field === 'size_loa' ||
                      field.field === 'size_lbp' ||
                      field.field === 'size_width' ||
                      field.field === 'size_depth'),expression:"field.field === 'size_loa' ||\n                      field.field === 'size_lbp' ||\n                      field.field === 'size_width' ||\n                      field.field === 'size_depth'"}],staticClass:"input-group-text",attrs:{"title":"Satuan dalam ukuran berat"}},[_vm._v(" Meter ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(field.field === 'gross_tonnage' ||
                      field.field === 'net_tonnage' ||
                      field.field === 'deadweight' ||
                      field.field === 'load_capacity'),expression:"field.field === 'gross_tonnage' ||\n                      field.field === 'net_tonnage' ||\n                      field.field === 'deadweight' ||\n                      field.field === 'load_capacity'"}],staticClass:"input-group-text",attrs:{"title":"Satuan dalam ukuran berat"}},[_vm._v(" MT ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(field.field === 'horsepower'),expression:"field.field === 'horsepower'"}],staticClass:"input-group-text",attrs:{"title":"Satuan dalam ukuran horsepower"}},[_vm._v(" HP ")])]),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)})],1)])}),0),_c('div',{staticClass:"d-flex justify-content-end mt-4"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"type":"reset","variant":"danger"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }