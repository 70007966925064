<template>
  <div>
    <router-view />

    <EngineHealthReportModal ref="engine-health-report-modal"/>
  </div>
</template>

<script>
import EngineHealthReportModal from "@/components/modals/EngineHealthReportModal";
import {  mapState } from "vuex";

export default {
  components: {
    EngineHealthReportModal
  },
  computed: {
    ...mapState([
      "showEngineHealthReport"
    ]),
  },
  watch: {
    showEngineHealthReport: {
      handler: function (newVal, oldVal) {
        if(newVal.vesselEngineId !== null){
          this.$refs["engine-health-report-modal"].show(newVal.vesselEngineId);
        }
      },
      deep: true,
    },
  }
}
</script>

<style>

</style>