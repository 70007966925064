<template>
  <div v-if="adsMarkers.show">
    <l-marker
      v-for="(l, index) in adsMarkers.data"
      :key="index"
      :lat-lng="[l.latitude, l.longitude]"
      @click="actionMarkerClick(l)"
    >
      <l-icon
        :icon-size="[25, 25]"
        :icon-anchor="[0, 0]"
        :icon-url="l.category.url"
      />
      <l-popup style="width: 300px;">
        <b-card
          no-body
          class="overflow-hidden border-0"
        >
          <b-row no-gutters>
            <b-col
              md="4"
              class="p-1"
              align-self="center"
            >
              <b-card-img
                :src="l.ads_company.logo"
                alt="Image"
                class="rounded"
              />
            </b-col>
            <b-col
              md="8"
              align-self="center"
            >
              <b-card-body class="p-1">
                <h5>{{ l.ads_company.name }}</h5>
                <p class="text-muted mb-2 mt-0">
                  {{ l.name_branch }}
                </p>
                <table>
                  <tr>
                    <td>Phone</td>
                    <td>:</td>
                    <td>{{ l.phone }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td><a :href="`mailto:${l.email}`">{{ l.email }}</a></td>
                  </tr>
                  <tr>
                    <td>Website</td>
                    <td>:</td>
                    <td><a :href="l.website">{{ l.website }}</a></td>
                  </tr>
                </table>
                <b-button-group class="w-100">
                  <b-button
                    v-b-modal="'modal-advertisement'"
                    size="sm"
                    variant="primary"
                    class="mx-0 my-2"
                    @click="openAds(l)"
                  >
                    View Products
                  </b-button>
                </b-button-group>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </l-popup>
    </l-marker>
  </div>
</template>
<script>
import {
  LIcon,
  LMarker,
  LPopup
} from 'vue2-leaflet'
import {mapState} from "vuex";
import API from "./../plugins/axios"
// import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";

export default {
  props: {
    adsCompany: {
      type: [
        Number, 
        String
      ],
      default: ''
    }
  },

  name: "AdsMarker.vue",
  components: {
    LIcon,
    LMarker,
    LPopup,
    // "v-marker-cluster": Vue2LeafletMarkerCluster
  },

  data() {
    return {
      locations: [],
      clusterOptions: {
        disableClusteringAtZoom: 18,
        chunkedLoading: true
      },
      loaded: false,
      idCompany: '',
    }
  },
  computed: {
    ...mapState([
      'adsMarkers'
    ])
  },
  created () {

  },
  methods: {
    openAds (data) {
      this.$emit('send', data)
    },

    actionMarkerClick (data) {
      const branchId = data.id_branch || ''

      if (!branchId) return

      if (!this.adsCompany) return

      API.get(`publicity/product/search-company?ads_company_branch=${branchId}&ads_category=${this.adsCompany}`)
        .then(() => {
          // 
        })
    }
  },
}
</script>

<style scoped>

</style>
