<template>
  <div id="login">
    <div class="form-signin">
      <div class="text-center mb-4">
        <h1 class="h1 mb-3 font-weight-thin">
          Log In
        </h1>
        <p><span class="text-muted">Ship Owner</span></p>
      </div>

      <div class="form-label-group">
        <input
          id="inputEmail"
          v-model="user_email"
          type="email"
          class="form-control"
          placeholder=""
          required
          autofocus
          @keyup.enter="perform_login"
        >
        <label for="inputEmail">Email address</label>
      </div>

      <div class="form-label-group">
        <input
          id="inputPassword"
          v-model="user_password"
          class="form-control"
          placeholder=""
          :type="passwordFieldType"
          required
          @keyup.enter="perform_login"
        >
        <label for="inputPassword">Password</label>
        <span
          class="field-icon mt-2"
          :class="iconShowPassword"
          @click="show_password()"
        />
      </div>

      <div class="checkbox">
        <router-link :to="linkResetPassword">
          <a class="">Lupa Sandi ?</a>
        </router-link>
      </div>
      <button
        id="btn-login"
        class="btn btn-primary btn-block mx-0"
        :disabled="loadingLogin"
        @click="perform_login"
      >
        <span
          v-if="loadingLogin"
          id="loading-login"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        /> Log In
      </button>
      <router-link :to="linkRegister">
        <button class="btn btn-light btn-block mx-0">
          Sign Up
        </button>
      </router-link>

      <div class="or-social-auth">
        <span class="caption">
          OR
        </span>
      </div>

      <button 
        class="btn btn-primary btn-block mx-0 btn-google"
        @click="socialAuth('google')"
      >
        <i class="mdi mdi-google mr-3" /> Masuk dengan Google
      </button>

      <p class="mt-4 text-center">
        Kembali ke halaman
        <a
          :href="linkHomepage"
          @click="goToParentSite(linkHomepage)"
        >Beranda</a>
      </p>
      <p class="mt-2 mb-3 text-muted text-center">
        &copy; 2020
      </p>
    </div>
  </div>
</template>

<script>
import { SwalWarning } from "../plugins/sweetalert2";
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "LoginPage",
  data () {
    return {
      user_email: "",
      user_password: "",
      login_type: "VS",

      passwordFieldType: "password",
      iconShowPassword: "fa fa-fw fa-eye",
      // linkHomepage: '#/',
      linkHomepage: process.env.VUE_APP_HOME_URL,
      linkRegister: "/register",
      linkResetPassword: "/reset-password",
      placeholder: {
        email: "example@zonasea.com",
        password: "type your password",
      },
      email: "",
    };
  },
  computed: {
    ...mapGetters({
      loadingLogin: 'moduleAuth/GET_LOADING_LOGIN'
    })
  },
  methods: {
    ...mapActions({
      login: 'moduleAuth/LOGIN'
    }),
    async perform_login() {
      const dataLayer = window.dataLayer || []

      dataLayer.push({
        'event': 'clickSignin',
        'clickSignin': true
      })
      
      if (this.user_email === "" && this.user_password === "") {
        SwalWarning ('Tolong periksa kembali kolom yang masih kosong!')
      } else {
        let form = {
          user: this.user_email,
          password: this.user_password,
          login_type: this.login_type
        }
        this.login(form)
      }
    },
    show_password() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.iconShowPassword =
        this.iconShowPassword === "fa fa-fw fa-eye"
          ? "fa fa-fw fa-eye-slash"
          : "fa fa-fw fa-eye";
    },
    goToParentSite (url) {
      const dataLayer = window.dataLayer || []

      dataLayer.push({
        'event': 'parentSiteApps',
        'parentSite': url
      })
    },
    socialAuth (provider) {
      if (!provider) return

      let apiUrl = process.env.VUE_APP_API_URL
      apiUrl = apiUrl.split('/api')[1]

      const urlOrigin = window.origin

      if (provider === 'google') {
        window.location.href = `//api${apiUrl}/auth/google?r=${urlOrigin}`
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-google {
  background-color: #DB4437 !important;
  border-color: #DB4437 !important;
}
</style>
