<template>
  <b-modal
    id="manifest-logs-modal"
    v-model="modalShow"
    :title="title"
    centered
    size="lg"
    hide-footer
  >
    <template v-if="loading">
      <div class="text-center">
        <b-spinner
          type="grow"
          variant="primary"
          class="mb-3"
        />
      </div>
    </template>
    <template v-else>
      <div v-for="(log, index) in manifestLogs" v-bind:key="index" class="border p-3 mb-3">
        <div class="row">
          <div class="col-12 col-md-6">
            {{ log.full_name }}
          </div>
          <div class="col-12 col-md-6">
            {{ ddMMyyyyt(log.created_at) }}
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import API from '@/plugins/axios'
import formatDate from "@/mixins/formatDate";
import SW from 'sweetalert2'
import { SwalSuccess, SwalConfirm, SwalInfo, SwalWarning } from '@/plugins/sweetalert2'
import moment from 'moment'
import { SwalFailed } from '../../plugins/sweetalert2'
import axios from '../../plugins/axios'

export default {
  mixins: [formatDate],

  data () {
    return {
      shipmentId: null,
      loading: false,
      modalShow: false,
      title: 'Cargo Manifest Logs',
      referenceNumber: '',
      units: [
        {
          key: 'Unit',
          label: 'Unit'
        },
        {
          key: 'Pcs',
          label: 'Pcs'
        },
        {
          key: 'MT',
          label: 'MT'
        },
        {
          key: 'Liter',
          label: 'Liter'
        },
      ],
      manifestList: [
        {
          item: '',
          serial_number: '',
          description: '',
          qty: '',
          unit: '',
        }
      ],
      lastUpdated : null
    }
  },

  methods: {
    reset(){
      this.loading = false
      this.shipmentId = null
      this.manifestLogs = [
      ]
    },

    show(id_shipment){
      this.shipmentId = id_shipment
      this.modalShow = true

      this.getManifestLogsData()
    },

    getManifestLogsData(){
      const vm = this

      vm.loading = true

      const endpoint = `/transaction/shipment/shipment-manifest-log/read?shipment_id=${vm.shipmentId}`

      API.get(endpoint)
        .then((response) => {
          const meta = response.data
          if (meta.code === 200) {
            const results = meta.data
            vm.manifestLogs = results
          }

          vm.loading = false
        })
        .catch((error) => {
          vm.loading = false
        })
    },
  }
}
</script>
<style lang="scss">
.divider{
  border-bottom:1px solid #ccc;
}
.pointer{
  cursor: pointer;
}
</style>
