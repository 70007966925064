<template>
  <div
    id="chatting-container"
    class="chatting-container"
    :class="{ active: isPanelChats && showLeftbar, 'full': isPanelChats && !showLeftbar }"
  >
    <div
      v-if="isPanelChats"
      class="header"
    >
      <div class="user-image">
        <img
          class="rounded-circle"
          src="/images/image_user.png"
        >
      </div>

      <div class="info">
        <div class="chat-user">
          <span
            v-if="dataMessages.detail.offer.status === '4'"
            class="badge badge-success"
          >Completed</span>
          <span
            v-else-if="dataMessages.detail.offer.status === '3'"
            class="badge badge-danger"
          >Cancelled</span>
          <span
            v-else-if="dataMessages.detail.offer.status === '2'"
            class="badge badge-danger"
          >Lost Bid</span>
          <span
            v-else-if="dataMessages.detail.offer.status === '1'"
            class="badge badge-info"
          >Approved</span>
          <span
            v-else-if="dataMessages.detail.offer.status === '0'"
            class="badge badge-primary"
          >Bidding</span>
          {{ dataMessages.detail.pengirim.user.name }}
        </div>
        <!-- <div class="chat-email">
          {{ dataMessages.detail.pengirim.user.email }}
        </div> -->
        <div class="chat-company text-muted text-uppercase">
          {{ dataMessages.detail.pengirim.company.name }}
        </div>
      </div>
      <div
        id="openActionChat"
        :class="{ active: isPanelChats }"
        class="btn-group-action animated fadeIn delay-1"
      >
        <div class="btn-group">
          <button
            class="btn btn-light btn-sm"
            @click="downloadChat(dataMessages.detail.shipment.id, dataMessages.detail.offer.id,'pdf')"
          >
            Export
          </button>
          <!-- <button class="btn btn-light btn-sm">Print</button> -->
        </div>
        <a
          id="close-panel-chat"
          class="close-button c-pointer"
          @click="closePanelChats"
        >&times;</a>
      </div>
    </div>
    <div
      v-if="isPanelChats"
      ref="feed"
      class="content-msg"
    >
      <div class="row col-lg-12 p-0 m-0">
        <!-- start isi chat dialog -->
        <ul
          id="chat-content-utama"
          class="chat-content col-lg-12"
        >
          <!-- <span class="clearfix"><p class="text-center text-muted mb-2"><small>Today</small></p></span> -->
          <span
            v-for="(msg, index) in dataMessages.message"
            :key="index"
          >

            <span class="clearfix" />

            <li
              v-if="msg.from === dataMessages.detail.pengirim.user.id"
              class="chat-content item"
            >
              <div class="row m-0 p-0">
                <div class="col-lg-12">
                  <p class="msg">
                    {{ msg.message }}
                  </p>
                </div>
                <div class="col-lg-12 pl-0">
                  <span class="time">{{ msg.time.slice(0, 5) }}</span>
                </div>
              </div>
            </li>
            <li
              v-else
              class="chat-content item-self"
            >
              <div class="row m-0 p-0">
                <div class="col-lg-12 ">
                  <p class="msg">
                    {{ msg.message }}
                  </p>
                </div>
                <div class="col-lg-12 pl-0">
                  <span class="time">{{ msg.time.slice(0, 5) }}</span>
                </div>
              </div>
            </li>

            <li
              id="chat-bottom"
              style="display: none;"
            />
          </span>
        </ul>
        <!-- end isi chat dialog -->
      </div>
    </div>
    <div
      id="footer-chat"
      class="footer-chat"
      :class="{ active: isPanelChats && showLeftbar, 'full': isPanelChats && !showLeftbar }"
    >
      <div
        v-if="isPanelChats"
        class="row col-12 p-0 m-0"
      >
        <div class="footer-content col-12">
          <div
            v-if="(dataMessages.detail.offer.status === '0' || dataMessages.detail.offer.status === '1') && dataMessages.detail.shipment.status !== '4'"
            class="row m-0 footer-content__wrapper-content"
          >
            <div class="row-content message-box">
              <textarea
                v-model="textMessage"
                class="form-control"
                rows="2"
                style="resize: none;"
                @keydown="sendMsgHandler"
              />
            </div>
            <div class="row-content send-button">
              <div class="row">
                <div class="col-6 col-sm-5 col-md-4 col-lg-3 col-container">
                  <button
                    v-b-tooltip.hover.topright="'Upload Documents'"
                    class="btn btn-warning text-white h-100 w-100"
                    @click="openOfferingDocuments(dataMessages.detail.offer)"
                  >
                    <i class="fa fa-file-alt"></i>
                  </button>
                </div>
                <div class="col-6 col-sm-7 col-md-8 col-lg-9 col-container">
                  <button
                    class="btn btn-primary text-white h-100 w-100"
                    @click="sendMsgButton"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="row m-0"
          >
            <span class="alert alert-warning text-center w-100">You can not chat, this offer is no longer available</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../plugins/axios";
import {SwalWarning} from "../plugins/sweetalert2";
import {mapState} from "vuex"

export default {
  name: "PanelChat",
  data() {
    return {
      textMessage: '',

    }
  },
  watch: {
    dataMessages () {
      if (this.isPanelChats) {

        if (this.$store.state.moduleInbox.refresh.scroll === false) this.scrollToBottom()
        if (this.dataMessages.message.length > this.$store.state.moduleInbox.currentLength) this.scrollToBottom()
      }
    },
    isPanelChats () {
      if (this.isPanelChats) this.scrollToBottom()
      else this.$store.commit('moduleInbox/SET_RESET_TIMEOUT')
    }
  },
  computed: {
    ...mapState([
        'showLeftbar'
    ]),
    dataMessages () { return this.$store.state.moduleInbox.dataMessages },
    isPanelChats () { return this.$store.state.moduleInbox.showPanelChat }
  },
  methods: {
    sendMsgHandler (e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault()
        if(this.textMessage) this.$store.dispatch('moduleInbox/SEND_MESSAGE', this.textMessage).then(()=> this.textMessage = '')
      }
    },

    sendMsgButton () {
      this.$store.dispatch('moduleInbox/SEND_MESSAGE', this.textMessage).then(()=> this.textMessage = '')
      this.textMessage = ''
    },

    downloadChat (id_shipment, id_offer, format_type) {
      let endpoint = `chat/download?shipment=${id_shipment}&offer=${id_offer}&format_type=${format_type}`
      API.get(endpoint)
        .then(function(response) {
          const meta = response.data
          if (meta.code === 200) {
            const fileUrl = meta.data.download_url
            window.open(fileUrl, "_blank")
          }
        })
        .catch((error) => {
          SwalWarning (error.response.data.message)
        })
    },

    closePanelChats: function() {
      this.$store.commit('moduleInbox/SET_PANEL_CHAT', false)
      this.$store.commit('moduleInbox/SET_REFRESH_DATA', false)
      this.$store.commit('moduleInbox/SET_REFRESH_SCROLL', false)
      if (this.$store.state.moduleInbox.refresh.timeout !== null) {
        this.$store.commit('moduleInbox/SET_RESET_TIMEOUT')
      }
    },

    scrollToBottom () {
      setTimeout(() => {
        this.$refs.feed.scrollTop = this.$refs.feed.scrollHeight - this.$refs.feed.clientHeight
      }, 100)
    },

    async openOfferingDocuments(offer) {
      this.$store.commit("SET_SHOW_OFFERING_LIST_DOCUMENTS", {
        offerId : offer.id,
        editable : true,
        openDate : new Date(),
      });
    },
  },
}
</script>

<style scoped>

</style>
