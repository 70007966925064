<template>
  <div id="register">
    <div class="form-signup">
      <div class="text-center mb-4">
        <h1 class="h1 mb-3 font-weight-thin">
          Sign Up
        </h1>
        <p><span class="text-muted">Ship Owner</span></p>
      </div>

      <div class="form-label-group">
        <input
          id="inputName"
          v-model.trim="$v.formData.name.$model"
          type="text"
          placeholder=""
          class="form-control"
          :class="{
            'is-invalid': $v.formData.name.$error,
            'is-valid': !$v.formData.name.$invalid,
          }"
          autofocus
          @keyup.enter="register"
        >
        <label for="inputName">Name</label>
      </div>

      <div class="form-label-group">
        <input
          id="inputEmail"
          v-model.trim="$v.formData.email.$model"
          type="email"
          placeholder=""
          class="form-control"
          :class="{
            'is-invalid': $v.formData.email.$error,
            'is-valid': !$v.formData.email.$invalid,
          }"
          @keyup.enter="register"
        >
        <label for="inputEmail">Email address</label>
        <div class="invalid-feedback">
          Email format does not match.
        </div>
      </div>

      <div class="form-label-group">
        <input
          id="inputCompany"
          v-model.trim="$v.formData.company.$model"
          type="text"
          placeholder=""
          class="form-control"
          :class="{
            'is-invalid': $v.formData.company.$error,
            'is-valid': !$v.formData.company.$invalid,
          }"
          @keyup.enter="register"
        >
        <label for="inputCompany">Company</label>
      </div>

      <div class="form-label-group">
        <select
          id="inputCountry"
          v-model.trim="$v.formData.country.$model"
          type="text"
          placeholder=""
          class="form-control custom-select"
          :class="{
            error: $v.formData.country.$error,
            dirty: !$v.formData.country.$invalid,
          }"
          @keyup.enter="register"
        >
          <option
            v-for="(item, index) in tempDataCountry"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
        <label for="inputCountry">Country</label>
      </div>

      <div class="form-label-group tooltippw w-100">
        <input
          id="inputPassword"
          v-model.trim="$v.formData.password.$model"
          class="form-control"
          placeholder=""
          :type="passwordFieldType"
          :class="{
            'is-invalid': !validPassword && formData.password.length > 0,
            'is-valid': validPassword && formData.password.length > 0,
          }"
          @keyup.enter="register"
          @input="check_password"
        >
        <label for="inputPassword">Password</label>
        <span
          class="field-icon mt-2"
          :class="iconShowPassword"
          @click="show_password()"
        />
        <div class="valid-feedback">
          Looks Good!
        </div>
        <div class="invalid-feedback">
          Password format does not match.
        </div>
        <div class="tooltiptext">
          <ul class="mt-3">
            <li><small class="text-muted">Use at least:</small></li>
            <li :class="{ is_valid: passwordHint.upper }">
              <small>Contains uppercase</small>
            </li>
            <li :class="{ is_valid: passwordHint.lower }">
              <small>Contains lowercase</small>
            </li>
            <li :class="{ is_valid: passwordHint.number }">
              <small>Contains number</small>
            </li>
            <li :class="{ is_valid: passwordHint.min }">
              <small>Min 8 character</small>
            </li>
          </ul>
        </div>
      </div>


      <button
        class="btn btn-primary btn-block mx-0"
        :disabled="btnLoading"
        data-btn="register"
        @click="register"
      >
        <span
          v-show="btnLoading"
          class="spinner-border spinner-border-sm mx-2"
          role="status"
          aria-hidden="true"
        />Sign Up
      </button>

      <router-link :to="linkLogin">
        <button
          class="btn btn-light btn-block mx-0"
          :disabled="btnLoading"
        >
          Log In
        </button>
      </router-link>

      <div class="or-social-auth">
        <span class="caption">
          OR
        </span>
      </div>

      <button 
        class="btn btn-primary btn-block mx-0 btn-google"
        @click="socialAuth('google')"
      >
        <i class="mdi mdi-google mr-3" /> Daftar dengan Google
      </button>

      <p class="mt-4 text-center">
        Kembali ke halaman
        <a :href="linkHomepage">Beranda</a>
      </p>
      <p class="mt-2 mb-3 text-muted text-center">
        &copy; 2020
      </p>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  maxLength,
  minLength,
  integer
} from "vuelidate/lib/validators";
import API from "../plugins/axios";

import { SwalWarning, SwalSuccess } from "../plugins/sweetalert2";

export default {
  name: "RegisterPage",
  data() {
    return {
      btnLoading: false,
      formData: {
        name: "",
        email: "",
        company: "",
        country: "",
        // province: "",
        // city: "",
        password: "",
        confirmPassword: "",
        typeRegis: "VS"
      },
      passwordHint:{
        lower: false,
        upper: false,
        min: false,
        number: false,
        length: null,
      },
      validPassword: false,
      passwordFieldType: "password",
      iconShowPassword: "fa fa-fw fa-eye",
      tempDataCountry: [],
      tempDataState: [],
      tempDataCity: [],
      login_type: "VS",
      linkHomepage: process.env.VUE_APP_HOME_URL,
      linkLogin: "/",
      linkShipOwner: "/user/ship-owner",
      linkResetPassword: "/reset-password",
      placeholder: {
        name: "Enter your name ",
        email: "Enter your email",
        company: "Enter your company",
        password: "Enter your password",
        confirmPassword: "Confirm password",
      },
      email: "",
    };
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(70),
      },
      email: {
        required,
        email,
        maxLength: maxLength(100),
      },
      company: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(70),
      },
      country: {
        required,
        integer,
      },
      // province: {
      //   required,
      //   integer,
      // },
      // city: {
      //   required,
      //   integer,
      // },
      password: {
        required,
        minLength: minLength(8),
      }
    },
  },
  mounted() {
    this.load_country();
  },
  methods: {
    check_password(){
      this.passwordHint.length = this.formData.password.length;

      if (this.passwordHint.length >= 8) {
        this.passwordHint.min = true;
      } else {
        this.passwordHint.min = false;
			}

      this.passwordHint.number = /\d/.test(this.formData.password);
      this.passwordHint.upper = /[A-Z]/.test(this.formData.password);
      this.passwordHint.lower = /[a-z]/.test(this.formData.password);

      if (this.passwordHint.min === true &&
					this.passwordHint.upper === true &&
          this.passwordHint.lower === true &&
          this.passwordHint.number === true) {
						this.validPassword = true;
      } else {
        this.validPassword = false;
      }
      console.log(this.validPassword)
    },
    // Load country
    load_country() {
      let vi = this;
      this.tempDataCountry = [];
      API.get("master/country/dropdown")
        .then((response) => {
          const meta = response.data;
          if (meta.code === 200) {
            const data = meta.data;
            vi.tempDataCountry = data;
          }
        });
    },
    async register() {
      let vi = this;
      vi.$v.$touch();
      if (vi.$v.$invalid || !this.validPassword) {
        SwalWarning ('Tolong periksa kembali format dan kolom yang masih kosong!')
      } else {
        vi.btnLoading = true
        const tempData = {
          type_regis: vi.formData.typeRegis,
          full_name: vi.formData.name,
          email: vi.formData.email,
          company: vi.formData.company,
          country: vi.formData.country,
          password: vi.formData.password,
        }
        await API.post("registration", tempData)
          .then((res) => {
            const meta = res.data;
              SwalSuccess (meta.message, () => {
                this.$router.push({
                  name: 'resend-email-verification' ,
                  params : {
                    email : vi.formData.email
                  }
                })
              })
          })
          .catch((err) => {
            SwalWarning (err.response.data.message)
          })
          .then(() => {
            vi.btnLoading = false
          })
      }
    },
    show_password() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.iconShowPassword =
        this.iconShowPassword === "fa fa-fw fa-eye"
          ? "fa fa-fw fa-eye-slash"
          : "fa fa-fw fa-eye"
    },
    socialAuth (provider) {
      if (!provider) return

      let apiUrl = process.env.VUE_APP_API_URL
      apiUrl = apiUrl.split('/api')[1]

      const urlOrigin = window.origin

      if (provider === 'google') {
        window.location.href = `//api${apiUrl}/auth/google?r=${urlOrigin}`
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-google {
  background-color: #DB4437 !important;
  border-color: #DB4437 !important;
}
</style>
