<template>
  <router-link
    :to="url"
    title="Add New Vessel"
  >
    <div
      class="floating-vessel-action"
      :class="color"
    >
      <i class="fas fa-plus fa-sm text" />
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'white'
    }
  },
}
</script>

<style>

</style>