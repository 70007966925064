export default {
  methods: {
    findRequired (val) {
      return val ? val.includes('nullable') : false
    },
    findLoadCapacity (val)  {
      const x = val.some(el => el.field !== 'load_capacity' && el.status !== '0')
      return x
    },
    checkJpg (val) {
      const ext = ['jpeg', 'jpg']
      return ext.some(el => val.includes(el))
    },
    checkPng (val) {
      const ext = ['png']
      return ext.some(el => val.includes(el))
    },
    checkPdf (val) {
      const ext = ['pdf']
      return ext.some(el => val.includes(el))
    },
    fileTypeValidation (val) {
      if (val === 'image/jpeg' || val === 'image/png' || val === 'application/pdf') return true
      else return false
    }
  }
}