<template>
  <div class="scroll-finder">
    <b-card>
      <div class="d-flex align-items-center justify-space-between">
        <i class="fas fa-box fa-4x box-icon" />
        <div class="ml-4">
          <h4>Find your cargo !</h4>
          <h6 class="text-muted">
            This feature only works for verified and licensed vessels
          </h6>
        </div>
      </div>

      <br>
      <b-row class="mb-4">
        <b-col
          cols="4"
          class="d-flex align-items-center font-weight-bolder"
        >
          Vessel
        </b-col>
        <b-col
          cols="8"
          class="d-flex align-items-center"
        >
          <b-form-select
            v-model="selectedVessel"
            :options="dropdownVessel"
            @change="findVesselOnly"
          />
        </b-col>
      </b-row>
      <b-card v-if="dataVessel.id">
        <b-row>
          <b-col
            cols="4"
            class="d-flex align-items-center small font-weight-bolder"
          >
            License
          </b-col>
          <b-col
            cols="8"
            class="d-flex align-items-center text-muted"
          >
            <b-badge
              :variant="dataVessel.license.status === 'Aktif' ? 'primary' : 'danger'"
              class="ml-1"
            >
              {{ dataVessel.license.status }}
            </b-badge>
            <a
              v-if="dataVessel.license.status != 'Aktif'"
              class="small ml-2"
              href="#"
              @click="buyLicense(dataVessel.id)"
            >Buy License</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="4"
            class="d-flex align-items-center small font-weight-bolder"
          >
            Max Capacity
          </b-col>
          <b-col
            cols="8"
            class="d-flex align-items-center small text-muted"
          >
            : {{ thausandSparator(dataVessel.load_capacity) }} MT
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="4"
            class="d-flex align-items-center small font-weight-bolder"
          >
            Vessel Type
          </b-col>
          <b-col
            cols="8"
            class="d-flex align-items-center small text-muted"
          >
            : {{ dataVessel.vessel_type.name }}
          </b-col>
        </b-row>
      </b-card>

      <b-row
        v-if="showTugboat"
        class="my-4"
      >
        <b-col
          cols="4"
          class="d-flex align-items-center"
        >
          Tugboat
        </b-col>
        <b-col
          cols="8"
          class="d-flex align-items-center"
        >
          <b-form-select
            v-model="selectedTugboat"
            :options="dropdownTugboat"
            @change="findWithTugboat"
          />
        </b-col>
      </b-row>

      <b-card v-if="dataTugboat.id">
        <b-row>
          <b-col
            cols="4"
            class="d-flex align-items-center small font-weight-bolder"
          >
            License
          </b-col>
          <b-col
            cols="8"
            class="d-flex align-items-center text-muted"
          >
            <b-badge
              :variant="dataTugboat.license.status === 'Aktif' ? 'primary' : 'danger'"
              class="ml-1"
            >
              {{ dataTugboat.license.status }}
            </b-badge>
            <a
              v-if="dataTugboat.license.status != 'Aktif'"
              class="small ml-2"
              href="#"
              @click="buyLicense(dataTugboat.id)"
            >Buy License</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="4"
            class="d-flex align-items-center small font-weight-bolder"
          >
            Horsepower
          </b-col>
          <b-col
            cols="8"
            class="d-flex align-items-center small text-muted"
          >
            : {{ dataTugboat.engine.horsepower }} HP
          </b-col>
        </b-row>
      </b-card>
    </b-card>
    <DetailCargo
      :show="modalDetailCargo"
      :data="fromMarker.data"
      :booking="paramsBooking"
    />
  </div>
</template>

<script>
import API from '../../plugins/axios'
import {SwalWarning} from '../../plugins/sweetalert2'
import formatDate from '../../mixins/formatDate'
import formatChar from '../../mixins/formatChar'
import DetailCargo from "../../components/modals/DetailCargo";
import {mapState} from "vuex";

export default {
  components: {
    DetailCargo
  },
  mixins: [formatDate, formatChar],
  data () {
    return {
      selectedVessel: null,
      selectedTugboat: null,

      dropdownVessel: [],
      dropdownTugboat: [],

      dataVessel: Object,
      dataTugboat: Object,

      locationCargoList: [], // list cargo per lokasi (titik)

      showTugboat: false,

      vesselFromQuery: null,
      tugboatFromQuery: null,
      shipmentFromQuery: null,
    }
  },
  computed: {
    ...mapState([
        'modalDetailCargo',
        'fromMarker',
        'paramsBooking'
    ])
  },
  created () {
    this.vesselFromQuery = this.$route.query.vessel
    this.tugboatFromQuery = this.$route.query.tugboat
    this.shipmentFromQuery = this.$route.query.shipment

    this.loadDropdowns()
  },
  methods: {
    buyLicense (data) {
      this.$router.push({
        name:'buy-license',
        params: {
          id: data
        }
      })
    },
    loadDropdowns(){
      const vm = this
      vm.loadDropdownVessel();
    },
    findVesselOnly () {
      if (this.selectedVessel) {
        this.loadDetailVessel(false)
        // Jika vessel dipilih
        if (this.selectedVessel.require_tugboat) {
          // Jika Vessel butuh tugboat maka tampil select tugboat
          this.resetCargoList()
          this.showTugboat = true
        }
        else {
          // Jika tidak maka hide dan reset tugboat, kemudian load cargo.
          this.dataTugboat = Object
          this.showTugboat = false
          this.selectedTugboat = null
          this.loadCargo(false)
        }
      } else {
        // Jika vessel tidak dipilih maka hide dan reset tugboat, kemudian reset cargo list
        this.resetCargoList()
        this.dataTugboat = Object
        this.dataVessel = Object
        this.showTugboat = false
        this.selectedTugboat = null
      }
    },

    findWithTugboat () {
      if (this.selectedVessel && this.selectedTugboat) {
        // Jika tugboat dipilih maka Load cargo.
        this.loadDetailVessel(true)
        this.loadCargo(true)
      }
      else {
        // reset cargo list
        this.dataTugboat = Object
        this.resetCargoList()
      }
    },

    loadDetailVessel (tugboat) {
      let vm = this
      let params = Object

      if ( !tugboat ) params = { id: vm.selectedVessel.id }
      else params = { id: vm.selectedTugboat }
      API.get("master/vessel/detail", { params })
        .then((response) => {
          const data = response.data.data

          if (tugboat) vm.dataTugboat = data
          else vm.dataVessel = data
        })
    },

    resetCargoList () {
      this.locationCargoList = []
      this.$store.dispatch('UPDATE_CARGO', { data: [], params: {} })
    },

    loadCargo (tugboat) {
      let vm = this
      vm.resetCargoList()
      let params = Object
      vm.$store.commit('SET_LOADING')

      if ( !tugboat ) params = { vessel: vm.selectedVessel.id }
      else params = { vessel: vm.selectedVessel.id, tugboat: vm.selectedTugboat }

      API.get("transaction/shipment/offers/cargo-finder", { params })
        .then((response) => {
          const data = response.data.data
          vm.locationCargoList = data
          console.log('Cargo is loaded')
        })
        .catch(error => {
          SwalWarning({title: 'Sorry', text: error.response.data.message})
        })
        .finally(() => {
          const payload = {
            params: params,
            data: vm.locationCargoList
          }
          this.$store.dispatch('UPDATE_CARGO', payload)
          vm.$store.commit('SET_LOADING')

          //check if deep link
          if(vm.shipmentFromQuery !== null){
            console.log("show cargo list");
            const selectedCargo = vm.locationCargoList.find(cargo => cargo.id == vm.shipmentFromQuery)
            vm.$store.commit("SET_CURRENT_CARGO", selectedCargo);
            vm.$store.dispatch("SHOW_CARGO_LIST", selectedCargo);
          }
        })
    },

    loadDropdownVessel () {
      const vm = this
      vm.dropdownVessel = []

      API.get("master/vessel/dropdown")
        .then((response) => {
          const data = response.data.data
          vm.dropdownVessel.push({
            value: null,
            text: 'Please select vessel'
          })
          data.map (el => {
            vm.dropdownVessel.push({
              value: el,
              text: el.name,
              require_tugboat: el.require_tugboat,
              vessel_type: el.vessel_type
            })
          })
        })
        .catch(() => {
          vm.dropdownVessel.push({
            value: null,
            text: 'No Vessel is available'
          })
        })
        .finally(() => {
          if(this.vesselFromQuery !== null){
            this.dropdownVessel.map(el => {
              if(el.value !== null){
                if(el.value.id === this.vesselFromQuery){
                  this.selectedVessel = el.value
                  this.findVesselOnly()
                }
              }
            })
          }

          vm.loadDropdownTugboat();
        })
    },
    loadDropdownTugboat () {
      const vm = this
      vm.dropdownTugboat = []

      API.get("master/vessel/tugboat/dropdown")
        .then((response) => {
          const data = response.data.data
          vm.dropdownTugboat.push({
            value: null,
            text: 'Please select tugboat'
          })
          data.map (el => {
            vm.dropdownTugboat.push({
              value: el.id,
              text: el.name
            })
          })
        })
        .catch(() => {
          vm.dropdownTugboat.push({
            value: null,
            text: 'No Tugboat is available'
          })
        })
        .finally(() => {
          if(this.tugboatFromQuery !== null){
            console.log(this.tugboatFromQuery)
            console.log(this.dropdownTugboat)
            this.dropdownTugboat.map(el => {
              if(el.value !== null){
                if(el.value === this.tugboatFromQuery){
                  this.selectedTugboat = el.value
                  this.loadDetailVessel(el.value);

                  this.loadCargo(el.value)
                }
              }
            })
          }
        })
    }
  },
}
</script>

<style scoped>
.box-icon {
  color: #014F86;
}
</style>
