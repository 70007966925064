<template>
  <b-modal
    id="offering-status-modal"
    v-model="modalShow"
    :title="title"
    centered
    size="lg"
    hide-footer
  >
    <template v-if="loading">
      <div class="text-center">
        <b-spinner
          type="grow"
          variant="primary"
          class="mb-3"
        />
      </div>
    </template>
    <template v-else>
      <div 
        v-for="(item, index) in statusList" 
        :key="index" 
        :class="'status-box' + (item.id !== null ? ' active' : '')"
        @click="isEditAble ? selectStep(item) : null">
        <div class="status-box-icon">
          <img :src="item.full_file_path"/>
        </div>
        <div class="status-box-label">
          {{ item.offering_list_status_type_name }}
        </div>
        <div v-if="item.user_full_name !== null" class="status-box-information">
          <div>
            <b>{{ item.user_full_name }}</b>
          </div>
          <div>
            {{ item.created_at }}
          </div>
        </div>
      </div>

      <div class="text-right" v-if="isEditAble">
        <button 
          type="button" 
          class="btn btn-primary"
          @click="saveStatus"
        >Save Progress</button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import API from '@/plugins/axios'
import SW from 'sweetalert2'
import { SwalSuccess, SwalConfirm, SwalInfo, SwalWarning } from '@/plugins/sweetalert2'
import moment from 'moment'
import { SwalFailed } from '../../plugins/sweetalert2'
import axios from '../../plugins/axios'

export default {
  mixins: [
  ],

  data () {
    return {
      offeringListId: null,
      loading: false,
      modalShow: false,
      isEditAble: true,
      title: 'Offering Status',
      statusList : [],
      selectedStep : null
    }
  },

  methods: {
    reset(){
      this.loading = false
      this.offeringListId = null
      this.statusList = null
      this.selectedStep = null
    },

    selectStep(step){
      this.selectedStep = step;
      //mark as active
      this.statusList.forEach(item => {
        if(item.sort_order <= step.sort_order){
          item.id = -1
        }else{
          item.id = null;
        }
      })
    },

    show(id_offering_list, isEditAble){
      this.reset()

      this.offeringListId = id_offering_list
      this.isEditAble = isEditAble
      this.modalShow = true;

      this.getStatuses()
    },

    getStatuses(){
      const vm = this

      vm.loading = true

      const endpoint = `/transaction/shipment/offer-status/read-detail?offering_list_id=${this.offeringListId}`

      API.get(endpoint)
        .then((response) => {
          const meta = response.data
          if (meta.code === 200) {
            const results = meta.data
            vm.statusList = results
          }

          vm.loading = false
        })
        .catch((error) => {
          vm.loading = false
        })
    },

    saveStatus(){
      const vm = this
      const endpoint = `/transaction/shipment/offer-status/create`

      //validate
      if(this.selectedStep === null){
        SwalWarning('Please select a status')
        return
      }

      vm.loading = true

      API.post(endpoint, {
        offering_list_id: vm.offeringListId,
        offering_list_status_type_id: vm.selectedStep.offering_list_status_type_id
      })
        .then((response) => {
          const meta = response.data
          if (meta.code === 200) {
            const results = meta.data
            vm.statusList = results

            //show swal success
            SwalSuccess(meta.message)
          }

          vm.loading = false
          vm.selectedStep = null

          vm.getStatuses()
        })
        .catch((error) => {
          vm.loading = false
        })
    }
  }
}
</script>
<style lang="scss">
#offering-status-modal .modal-body{
  margin: auto !important;
  padding-top:50px;
  padding-bottom:50px;
}
.status-box{
  float:left;
  margin-right:20px;
  text-align: center;
  cursor: pointer;
  width:100px;
  margin-bottom:20px;

  .status-box-information{
    font-size:10pt;
    color:#bdbdbd;
    opacity:0;
  }

  .status-box-icon{
    width:50px;
    height:50px;
    background: #f5f5f5;
    border-radius: 50%;
    margin: auto;

    img{
      margin-top:15px;
      max-height:20px;
      max-width:20px;
    }
  }

  .status-box-label{
    font-size:10pt;
    color:#bdbdbd;
    margin-top:10px;
    height:50px;
  }

  &:hover, &.active{
    .status-box-icon{
      outline: 1px solid #1074bf !important;
    }

    .status-box-label{
      color: #1074bf !important;
      font-weight: bold;
    }

    &:not(:first-child)::before{
      color:#1074bf;
    }
  }

  &:hover{
    .status-box-information{
      opacity:1;
    }
  }

  &:not(:first-child)::before{
    content:'>';
    position:absolute;
    margin-left:-70px;
    margin-top:20px;
    color:#bdbdbd;
  }
}
</style>
