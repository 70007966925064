<template>
  <div class="container pt-5 mb-5">
    <loading-dialog :show="isLoading" />

    <div class="py-5 text-center">
      <h2>{{ selectedVessel.name }} Engines</h2>
      <router-link
        :to="'/vessels/detail/' + selectedVessel.id"
        class="text-decoration-none float-left"
      >
        <i class="fa fa-arrow-left mr-2" />Back to previous
      </router-link>
    </div>
    
    <div class="border p-3">
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Vessel Name</strong>
            <div class="text-muted">
              {{ selectedVessel.name }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Vessel Type</strong>
            <div class="text-muted">
              {{ selectedVessel.vessel_type }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Country</strong>
            <div class="text-muted">
              {{ selectedVessel.country }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Load Capacity</strong>
            <div class="text-muted">
              {{ selectedVessel.load_capacity }} MT
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3 mb-4">
      <div class="col-12 col-sm-12 col-md-6">
        <div>
          Please select your engine to schedule for health checkup.
          <form class="d-flex" @submit.prevent="requestCheckup">
            <select class="form-control" v-model="selectedEngines">
              <option v-for="(data, index) in engines" v-bind:key="index" :value="data">
                {{ data.engine_label }}
              </option>
            </select>
            <button type="submit" class="btn btn-primary" style="width:300px">Request Health Check</button>
          </form>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <div class="text-right mt-4">
          <button @click="addOrEdit(null)" class="btn btn-primary"><i class="fa fa-plus mr-2"></i>&nbsp;Add Engine</button>
        </div>
      </div>
    </div>

    <div>
      <b-nav tabs fill>
        <b-nav-item :active="showEngines" @click="viewTab(0)">Engines <b-badge>{{ engines.length }}</b-badge></b-nav-item>
        <b-nav-item :active="showCheckupRequests" @click="viewTab(1)">Checkup Request <b-badge>{{ checkup_requests.length }}</b-badge></b-nav-item>
      </b-nav>
    </div>
    <table v-if="showEngines" class="table table-hover table-bordered table-vessel-engine">
      <thead>
        <tr>
          <th>Engine Label</th>
          <th>Manufacturer</th>
          <th>Model Number</th>
          <th>Engine Year</th>
          <th>Last Check</th>
          <th>Health</th>
          <!-- <th>Actions</th> -->
        </tr>
      </thead>
      <tbody v-if="!listLoading">
        <tr v-for="(data, index) in engines" v-bind:key="index" @click="goToEngineDetail(data.id)">
          <td>{{ data.engine_label }}</td>
          <td>{{ data.manufacturer }}</td>
          <td>{{ data.model_number }}</td>
          <td>{{ data.engine_year }}</td>
          <td>{{ data.previous_report !== null && typeof data.previous_report !== 'undefined' ? ddmmyyyyStripe(data.previous_report.checked_date) : '' }}</td>
          <td>
            <template v-if="(data.previous_report !== null && typeof data.previous_report !== 'undefined')">
              <span v-html="renderStatus(data.previous_report.report_data.report_status)"></span>
            </template>
          </td>
          <!-- <td>
            <button @click="viewEngineReport(data)" class="btn btn-sm btn-success"><i class="fa fa-edit mr-2"></i>View Report</button>
            <button @click="addOrEdit(data)" class="btn btn-sm btn-primary"><i class="fa fa-edit mr-2"></i>Edit</button>
            <button @click="deleteEngine(data)" class="btn btn-sm btn-danger"><i class="fa fa-trash mr-2"></i>Delete</button>
          </td> -->
        </tr>
      </tbody>
      <tfoot v-if="listLoading">
        <tr>
          <td colspan="5">
            <div class="text-center">
              <i class="fa fa-spinner fa-spin fa-3x"></i>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>

    <table v-if="showCheckupRequests" class="table table-hover table-bordered">
      <thead>
        <tr>
          <th>Status</th>
          <th>Engines</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody v-if="!listLoading">
        <tr v-for="(data, index) in checkup_requests" v-bind:key="index">
          <td>{{ data.status }}</td>
          <td>
            <b-badge style="cursor:pointer" @click="viewEngineReport(engine)" v-for="(engine, engine_index) in data.engines" v-bind:key="engine_index" class="mr-2" variant="primary">
              {{ engine.engine_label }}
            </b-badge>
          </td>
          <td>{{ ddMMyyyyt(data.created_at) }}</td>
        </tr>
      </tbody>
      <tfoot v-if="listLoading">
        <tr>
          <td colspan="5">
            <div class="text-center">
              <i class="fa fa-spinner fa-spin fa-3x"></i>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>

    <b-modal
      id="modal-center"
      v-model="showModal"
      hide-footer
      :title="'Add / Edit Vessel Engine'"
      @hide="onCancel"
    >
      <form @submit.prevent="saveEngine">
        <div>
          <label>Engine Label</label>
          <select class="form-control" v-model="engineSelectedOption">
            <option v-for="(option, index) in defaultEngineOptions" :key="index">{{ option }}</option>
          </select>
        </div>
        <div class="mt-3" v-if="engineSelectedOption === 'Others'">
          <label>Other Engine Label</label>
          <input :disabled="submitLoading" type="text" v-model="engine_label" class="form-control" maxlength="150" placeholder="Input Engine's Label..." required/>
        </div>
        <div class="mt-3">
          <label>Manufacturer</label>
          <input :disabled="submitLoading" type="text" v-model="manufacturer" class="form-control" maxlength="150" placeholder="Input Manufacturer..." required/>
        </div>
        <div class="mt-3">
          <label>Model Number</label>
          <input :disabled="submitLoading" type="text" v-model="model_number" class="form-control" maxlength="150" placeholder="Input Model Number..." required/>
        </div>
         <div class="mt-3">
          <label>Engine Year</label>
          <input :disabled="submitLoading" type="text" v-model="engine_year" class="form-control" maxlength="150" placeholder="Input Engine Year..." required/>
        </div>
        <div class="mt-3">
          <button :disabled="submitLoading" type="submit" class="btn btn-primary w-100 ml-0 mr-0 mt-3">
            <template v-if="submitLoading">
              <i class="fa fa-spinner fa-spin"></i>
            </template>
            <template v-else>
              <i class="fa fa-save mr-2"></i>
              Save
            </template>
          </button>
        </div>
      </form>
    </b-modal>
    
  </div>
</template>
<script>
import { mapState } from 'vuex'
import LoadingDialog from "@/components/dialogs/LoadingDialog"
import { SwalSuccess, SwalWarning, Swal, SwalConfirm } from "../../../plugins/sweetalert2";

import formatDate from '../../../mixins/formatDate'
import formatValidation from '../../../mixins/formatValidation'
import API from "../../../plugins/axios";
import formatChar from "@/mixins/formatChar";

export default {
  components: {
    'loading-dialog': LoadingDialog,
  },
  mixins: [formatDate, formatValidation, formatChar],
  data () {
    return {
      showModal: false,
      showEngines : true,
      showCheckupRequests : false,

      engines : [],
      checkup_requests : [],

      defaultEngineOptions : [
        "Main Engine",
        "Aux Engine",
        "Others"
      ],
      engineSelectedOption : "Main Engine",

      id: null,
      engine_label: 'Main Engine',
      manufacturer: '',
      model_number: '',
      engine_year: '',
      submitLoading : false,
      listLoading : false,
      selectedEngines : null,
    }
  },
  computed: {
    ...mapState('moduleVessel', [
      'selectedVessel',
      'selectedVesselType',
      'userCreate',
      'license',
      'buildYear',
      'isLoading',
    ]),
  },
  watch: {
    selectedVessel () {
      if(this.selectedVessel.id !== "" && this.selectedVessel.id !== null) {
        this.getEngines()
        this.getCheckupRequests()
      }
    },
    showModal(){
      if(this.showModal === false) {
        this.id = null
        this.engineSelectedOption = 'Main Engine'
        this.engine_label = 'Main Engine'
        this.manufacturer = ''
        this.model_number = ''
        this.engine_year = ''
      }
    },
    engineSelectedOption(){
      if(this.engineSelectedOption === 'Others') {
        if(this.id === null){
          this.engine_label = ''
        }
      }else{
        this.engine_label = this.engineSelectedOption
      }
    }
  },
  created () {
    this.loadSelectedVessel()
  },
  methods: {
    goToEngineDetail(vesselEngineId){
      this.$router.push({
        name: 'details-vessel-engine',
        params: {
          vesselId: this.selectedVessel.id,
          vesselEngineId: vesselEngineId
        }
      })
    },
    renderStatus(status){
      if(status == 'Normal'){
        return '<span class="text-success">'+status+'</span>'
      }else if(status == 'Caution'){
        return '<span class="text-warning">'+status+'</span>'
      }else if(status == 'Alert'){
        return '<span class="text-danger">'+status+'</span>'
      }else{
        return status
      }
    },
    requestCheckup(){
      //validate
      if(this.selectedEngines === null){
        SwalWarning("Please select engine first!")
      }else{
        //show confirm
        SwalConfirm('Are you sure to request checkup '+this.selectedEngines.engine_label+'?', () => {
          //get selected engines
          let selectedEnginesValue = []
          selectedEnginesValue.push(parseInt(this.selectedEngines.id))

          const vm = this

          vm.isLoading = true

          API.post("/master/vessel/vessel-checkup/create", {
              vessel_id: vm.selectedVessel.id,
              status: 'Requested',
              vessel_engine_ids: selectedEnginesValue
            },
            {
              headers: {
                'Content-Type': 'application/json',
              }
            }
          )
          .then((res) => {
            const body = res.data;
            vm.isLoading = false

            if(res.status === 200){
              SwalSuccess(body.message)

              vm.viewTab(1)
            } else {
              SwalWarning(body.message)
            }
          })
          .catch((error) => {
            vm.isLoading = false

            const data = error.response
            SwalWarning (data.data.message)
          });
        })
      }
    },
    getCheckupRequests(reset = false){
      const vm = this

      vm.listLoading = true

      const params = {
        'start': 0,
        'length': 999,
        'on_user': true,

        "columns[0][data]": "0",
        "columns[0][name]": "id",
        "columns[0][searchable]": "false",
        "columns[0][orderable]": "true",
        "columns[0][search][value]": "",
        "columns[0][search][regex]": "false",

        "columns[1][data]": "1",
        "columns[1][name]": "vessel.id",
        "columns[1][searchable]": "true",
        "columns[1][orderable]": "true",
        "columns[1][search][value]": vm.selectedVessel.id,
        "columns[1][search][regex]": "false",

        "columns[2][data]": "2",
        "columns[2][name]": "status",
        "columns[2][searchable]": "true",
        "columns[2][orderable]": "true",
        "columns[2][search][value]": "",
        "columns[2][search][regex]": "false",

        "order[0][column]": "0",
        "order[0][dir]": "desc",
      }

      API.get("/master/vessel/vessel-checkup/read", {
        params: params
      })
      .then((res) => {
        const body = res.data;
        vm.listLoading = false

        if(res.status === 200){
          if(reset){
            vm.checkup_requests = body.data
          }else{
            vm.checkup_requests = [...vm.checkup_requests, ...body.data]
          }
        } else {
          SwalWarning(body.message)
        }
      })
      .catch((error) => {
        vm.listLoading = false

        const data = error.response
        SwalWarning (data.data.message)
      });
    },
    viewTab(index){
      if(index === 0){
        this.showEngines = true
        this.showCheckupRequests = false

        //reload
        this.getEngines(true)
      }
      else if(index === 1){
        this.showEngines = false
        this.showCheckupRequests = true

        //reload
        this.getCheckupRequests(true)
      }
    },
    loadSelectedVessel () {
      const payload = this.$route.params.id
      this.$store.dispatch('moduleVessel/LOAD_SELECTED', payload)
    },
    getEngines(reset = false){
      const vm = this

      vm.listLoading = true

      const params = {
        'start': 0,
        'length': 999,

        "columns[0][data]": "0",
        "columns[0][name]": "id",
        "columns[0][searchable]": "false",
        "columns[0][orderable]": "true",
        "columns[0][search][value]": "",
        "columns[0][search][regex]": "false",

        "columns[1][data]": "1",
        "columns[1][name]": "vessel_id",
        "columns[1][searchable]": "true",
        "columns[1][orderable]": "false",
        "columns[1][search][value]": vm.selectedVessel.id,
        "columns[1][search][regex]": "false",

        "order[0][column]": "0",
        "order[0][dir]": "desc",
      }

      API.get("/master/vessel/vessel-engine/read", {
        params: params
      })
      .then((res) => {
        const body = res.data;
        vm.listLoading = false

        if(res.status === 200){
          if(reset){
            vm.engines = body.data
          }else{
            vm.engines = [...vm.engines, ...body.data]
          }
        } else {
          SwalWarning(body.message)
        }
      })
      .catch((error) => {
        vm.listLoading = false

        const data = error.response
        SwalWarning (data.data.message)
      });
    },
    onCancel(){
      this.showModal = false
    },
    addOrEdit(data){
      this.showModal = true

      if(data !== null){
        this.id = data.id

        if(data.engine_label === 'Main Engine' || data.engine_label === 'Aux Engine'){
          this.engineSelectedOption = data.engine_label
        }else{
          this.engineSelectedOption = 'Others'
        }

        this.engine_label = data.engine_label
        this.manufacturer = data.manufacturer
        this.model_number = data.model_number
        this.engine_year = data.engine_year
      }
    },
    saveEngine(){
      if(this.id === null){
        this.addNewEngine()
      } else {
        this.editEngine()
      }
    },
    editEngine(){
      const vm = this

      vm.submitLoading = true

      API.patch("/master/vessel/vessel-engine/update", {
          id: vm.id,
          vessel_id: vm.selectedVessel.id,
          engine_label: vm.engine_label,
          manufacturer: vm.manufacturer,
          model_number: vm.model_number,
          engine_year: vm.engine_year,
        }
      )
      .then((res) => {
        const body = res.data;
        vm.submitLoading = false

        if(res.status === 200){
          SwalSuccess(body.message)

          vm.showModal = false

          //update list
          vm.engines = vm.engines.map(item => {
            if(item.id === vm.id){
              item.engine_label = vm.engine_label
              item.manufacturer = vm.manufacturer
              item.model_number = vm.model_number
              item.engine_year = vm.engine_year
            }
            return item
          }) 
        } else {
          SwalWarning(body.message)
        }
      })
      .catch((error) => {
        vm.submitLoading = false

        const data = error.response
        SwalWarning (data.data.message)
      });
    },
    addNewEngine(){
      const vm = this

      vm.submitLoading = true

      API.post("/master/vessel/vessel-engine/create", {
          vessel_id: vm.selectedVessel.id,
          engine_label: vm.engine_label,
          manufacturer: vm.manufacturer,
          model_number: vm.model_number,
          engine_year: vm.engine_year,
        }
      )
      .then((res) => {
        const body = res.data;
        vm.submitLoading = false

        if(res.status === 200){
          SwalSuccess(body.message)

          vm.showModal = false

          //append new engine to list
          vm.engines.unshift(body.data)
        } else {
          SwalWarning(body.message)
        }
      })
      .catch((error) => {
        vm.submitLoading = false

        const data = error.response
        SwalWarning (data.data.message)
      });
    },
    deleteEngine(data){
      const vm = this

      //show confirmation
      SwalConfirm ('Delete '+data.engine_label+'?', () => {
        API.delete("/master/vessel/vessel-engine/delete?id=" + data.id)
          .then((res) => {
            const body = res.data;

            if(res.status === 200){
              SwalSuccess(body.message)

              vm.engines = vm.engines.filter(item => item.id !== data.id)
            }
          }).catch((error) => {
            const data = error.response
            SwalWarning (data.data.message)
          });
      })
    },
    viewEngineReport(data){
      //go to route
      this.$router.push({
        name: "report-vessel-engine",
        params: {
          vesselId: this.selectedVessel.id,
          vesselEngineId: data.id
        },
      });
    }
  }
}
</script>

<style scoped>
.table-vessel-engine tr{
  cursor: pointer;
}
</style>
