<template>
  <div>
    <div class="row m-0">
      <div class="col-2 tab-tag pl-4 pr-0">
        <p class="mt-2 mb-2">Filter :</p>
      </div>
      <div class="col-10 tab-tag d-md-flex justify-content-center">
        <button
          v-for="(tag, index) in tags"
          :key="index"
          class="btn btn-outline-primary btn-sm"
          :class="{ active: tag.active }"
          @click="loadData(tag.filter)"
        >
          {{ tag.name }}
        </button>
      </div>
    </div>

    <div id="content-list" class="scroll-list-payment-license">
      <FabSidenav url="../vessels/buy-license/0" color="yellow" />

      <ul v-if="totalRecords > 0" class="list-group">
        <li
          v-for="(list, index) in listData"
          :key="index"
          class="list-group-item card-vessel c-pointer py-3"
          @click="showDetail(list.id)"
        >
          <div class="d-flex w-100 justify-content-between">
            <span class="badge badge-pill" :class="`badge-${list.class}`">{{
              list.payment_status
            }}</span>
          </div>

          <div class="title-vessel">
            {{ list.vessel }}
          </div>
          <small class="text-vessel"> License: {{ list.license }} </small>
        </li>
      </ul>
      <div v-else class="alert alert-warning text-center" role="alert">
        <i class="fa fa-info mr-2" /> No records available
      </div>
    </div>

    <!-- pagination -->
    <div id="pagination" class="pages-tab">
      <div class="row no-gutters count-record">
        <div class="col-12">
          <div class="text-center">Total Records: {{ totalRecords }}</div>
        </div>
      </div>
      <!-- Button Action Next and Previous -->
      <div class="row no-gutters">
        <div class="col-4 pl-4">
          <button
            class="btn btn-secondary btn-sm"
            :disabled="currentPage <= 1"
            @click="previousPage()"
          >
            Previous
          </button>
        </div>
        <div class="col-4 pl-1 pr-1">
          <div class="pages">{{ currentPage }} of {{ perPage || 1 }}</div>
        </div>
        <div class="col-4 pr-4">
          <button
            class="btn btn-secondary btn-sm float-right"
            :disabled="currentPage >= perPage"
            @click="nextPage()"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <!-- End Pagination -->
    <DetailPaymentLicense
      :show="modalDetail"
      :data="dataDetail"
      @toggle="modalDetail = !modalDetail"
    />
  </div>
</template>

<script>
import DetailPaymentLicense from "../../components/modals/DetailPaymentLicense.vue";
import API from "../../plugins/axios";
import formatDate from "../../mixins/formatDate";
import FabSidenav from "../../components/FabSidenav";

export default {
  components: {
    DetailPaymentLicense,
    FabSidenav,
  },
  mixins: [formatDate],
  data() {
    return {
      modalDetail: false,
      dataDetail: null,
      startPage: 0,
      lengthPage: 10,
      currentPage: 1,
      perPage: null,

      listData: Array,
      totalRecords: null,
      filter: "",
      tags: [
        { name: "Waiting", filter: 1, active: true },
        { name: "Follow Up", filter: 0, active: false },
        { name: "Success", filter: 2, active: false },
        { name: "Cancelled", filter: 3, active: false },
      ],
    };
  },
  mounted() {
    this.loadData(1); // load waiting confirm by default
  },
  methods: {
    setLoading() {
      this.$store.commit("SET_LOADING");
    },
    showDetail(id) {
      this.modalDetail = true;
      this.dataDetail = id;
    },
    previousPage() {
      this.startPage = this.startPage - this.lengthPage;
      this.currentPage -= 1;
      this.loadData(this.filter);
    },
    nextPage() {
      this.startPage = this.startPage + this.lengthPage;
      this.currentPage += 1;
      this.loadData(this.filter);
    },

    activeTag(filter) {
      this.tags.find((el) => {
        el.filter === filter ? (el.active = true) : (el.active = false);
      });
    },

    async loadData(filter) {
      const vm = this;

      vm.filter = filter;
      vm.activeTag(vm.filter);
      vm.setLoading();

      const params = {
        "columns[0][data]": "0",
        "columns[0][name]": "id",
        "columns[0][searchable]": "true",
        "columns[0][orderable]": "true",
        "columns[0][search][value]": "",
        "columns[0][search][regex]": false,
        "order[0][column]": "0",
        "order[0][dir]": "desc",
        start: vm.startPage,
        filter: filter,
        length: vm.lengthPage,
      };
      let endpoint = "transaction/payment/license-vessel";
      await API.get(endpoint, { params })
        .then((response) => {
          const meta = response.data;
          if (meta.code === 200) {
            vm.totalRecords = meta.recordsFiltered;
            const data = meta.data;
            vm.listData = [];
            data.forEach((element) => {
              vm.listData.push({
                id: element.id,
                license: element.license,
                request_payment: element.verif_payment_cancel_at
                  ? vm.ddmmyyySlash(element.verif_payment_cancel_at)
                  : element.verif_payment_done_at
                  ? vm.ddmmyyySlash(element.verif_payment_done_at)
                  : element.request_payment,
                vessel: element.vessel_name,
                payment_status:
                  element.payment_status === "Menunggu Verifikasi Admin"
                    ? "Waiting Confirmation"
                    : element.payment_status === "Menunggu Follow Up"
                    ? "Follow Up"
                    : element.payment_status === "Batal"
                    ? "Cancelled"
                    : "Success",
                class:
                  element.payment_status === "Menunggu Verifikasi Admin"
                    ? "primary"
                    : element.payment_status === "Menunggu Follow Up"
                    ? "warning"
                    : element.payment_status === "Batal"
                    ? "danger"
                    : "success",
              });
            });
          }
        })
        .catch((error) => {
          console.log("Vessel", error.response.data.message);
        })
        .finally(() => {
          vm.perPage = Math.ceil(vm.totalRecords / vm.lengthPage);
          vm.setLoading();
        });
    },
  },
};
</script>

<style></style>
