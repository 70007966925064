<template>
  <div id="register">
    <ValidationObserver ref="form">
      <div class="form-signup">
        <div class="text-center mb-4">
          <h1 class="h1 mb-3 font-weight-thin">
            Company Data
          </h1>
          <p>
            <span class="text-muted">
              Silahkan melengkapi data perusahaan terlebih dahulu untuk melanjutkan
            </span>
          </p>
        </div>

        <ValidationProvider
          v-slot="{ errors }"
          vid="email_company"
          name="Email"
          rules="required|email"
        >
          <div class="form-label-group">
            <input
              v-model="entry.email_company"
              type="text"
              placeholder=""
              class="form-control"
              autofocus
              :disabled="entry.email_company"
            >
            <label for="inputName">Email</label>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          vid="name"
          name="Fullname"
          rules="required"
        >
          <div class="form-label-group">
            <input
              v-model="entry.name"
              type="text"
              placeholder=""
              class="form-control"
              autofocus
              :disabled="entry.name"
            >
            <label for="inputName">Fullname</label>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          vid="phone"
          name="Phone Number"
          rules="required|integer"
        >
          <div class="form-label-group">
            <input
              v-model="entry.phone"
              type="text"
              placeholder=""
              class="form-control"
              autofocus
            >
            <label for="inputName">Phone Number</label>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          vid="name_company"
          name="Company Name"
          rules="required"
        >
          <div class="form-label-group">
            <input
              v-model="entry.name_company"
              type="text"
              placeholder=""
              class="form-control"
              autofocus
            >
            <label for="inputName">Company Name</label>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          vid="phone_company"
          name="Company Phone Number"
          rules="required|integer"
        >
          <div class="form-label-group">
            <input
              v-model="entry.phone_company"
              type="text"
              placeholder=""
              class="form-control"
              autofocus
            >
            <label for="inputName">Company Phone Number</label>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          vid="country"
          name="Country"
          rules="required"
        >
          <div class="form-label-group">
            <select
              id="inputCountry"
              v-model="entry.country"
              placeholder=""
              class="form-control custom-select"
            >
              <option
                v-for="(item, index) in countryEntries"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <label for="inputCountry">Country</label>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          vid="address_company"
          name="Company Address"
          rules="required"
        >
          <div class="form-label-group">
            <textarea
              v-model="entry.address_company"
              type="text"
              placeholder="Company Address"
              class="form-control"
              autofocus
            />
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <button
          class="btn btn-primary btn-block mx-0"
          :disabled="isLoading"
          data-btn="register"
          @click="saveAction"
        >
          <span
            v-show="isLoading"
            class="spinner-border spinner-border-sm mx-2"
            role="status"
            aria-hidden="true"
          />Lanjutkan
        </button>

        <p class="mt-4 text-center">
          Kembali ke halaman
          <a :href="linkHomepage">Beranda</a>
        </p>
        <p class="mt-2 mb-3 text-muted text-center">
          &copy; 2020
        </p>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  mapMutations
} from 'vuex'

import API from "./../../plugins/axios"

// import { SwalWarning, SwalSuccess } from "./../../plugins/sweetalert2"

export default {
  name: "CreateCompanyPage",

  data () {
    return {
      isLoading: false,
      userData: {},
      countryEntries: [],
      entry: {
        user_id: '',
        parent: '',
        name_company: '',
        phone_company: '',
        address_company: '',
        email_company: '',
        name: '',
        phone: '',
        country: '',
        ship_owner: 'T',
        consignee: 'T',
        shipper: 'T'
      },
      linkHomepage: process.env.VUE_APP_HOME_URL
    }
  },
  
  computed: {
    token () {
      return this.$route.params.token
    }
  },
  
  mounted () {
    this.init()
  },
  
  methods: {
    ...mapMutations({
      setUserData: 'moduleAuth/SET_USER_DATA'
    }),

    init () {
      this.setUserData({
        token: {
          access_token: this.token
        },
        data: {}
      })

      API.get('/auth/validate')
        .then(response => {
          const { data } = response.data

          if (data.roles.id) {
            this.$router.push('/')

            return
          }

          this.userData = data

          this.entry = Object.assign(this.entry, {
            user_id: this.userData.user_id,
            name: this.userData.full_name,
            email_company: this.userData.email
          })

          this.load_country()
        })
        .catch(err => {
          console.info('err :: ', err)
        })
    },

    saveAction () {
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.submit()
          }
        })
    },

    submit () {
      this.isLoading = true

      API.post('/master/company/create-company', this.entry)
        .then(() => {
          API.get('/auth/validate')
            .then(res => {
              const { data } = res.data

              this.setUserData({
                token: {
                  access_token: this.token
                },
                data
              })

              window.location = '/app'

              this.isLoading = false
            })
            .catch(err => {
              console.info('err :: ', err)

              this.isLoading = false
            })
        })
        .catch(error => {
          console.info('error :: ', error)

          this.isLoading = false
        })
    },

    setEntry (object, value) {
      this.entry[object] = value
    },

    // Load country
    load_country() {
      let vi = this;
      this.countryEntries = [];
      API.get("master/country/dropdown")
        .then((response) => {
          const meta = response.data;
          if (meta.code === 200) {
            const data = meta.data;
            vi.countryEntries = data;
          }
        })
    }
  }
}
</script>
