<template>
  <div class="">
    <div
      v-for="(dataValue, dataKey) in data"
      v-show="findLoadCapacity(dataValue)"
      :key="dataKey"
      class="group-form"
    >
      <div class="form-group row">
        <h4>{{ dataKey }}</h4>
      </div>

      <div
        v-for="(value, fieldKey) in dataValue"
        :key="fieldKey"
        class="form-group row"
      >
        <div class="col-lg-12">
          <label>{{ value.label }}</label> <label
            v-if="findRequired(value.mandatory)"
            class="text-info"
          > (Optional) </label>
          <ValidationProvider
            v-slot="{ errors }"
            :name="value.label"
            :rules="value.mandatory"
          >
            <div class="input-group">
              <input
                v-if="value.type === 'text'"
                v-model.trim="dataSend[value.field]"
                type="text"
                class="form-control"
                :class="{'is-invalid': errors[0]}"
              >

              <select
                v-else-if="
                  value.type === 'dropdown' &&
                    (value.field === 'build_year' ||
                      value.field === 'main_engine_build_year' ||
                      value.field === 'aux_engine_build_year')"
                v-model.trim="dataSend[value.field]"
                class="custom-select"
                :class="{'is-invalid': errors[0]}"
              >
                <option
                  v-for="(data, index) in dropdownBy"
                  :key="index"
                  :value="data"
                >
                  {{ data }}
                </option>
              </select>

              <select
                v-else-if="
                  value.type === 'dropdown' &&
                    value.field === 'classification'
                "
                v-model.trim="dataSend[value.field]"
                class="custom-select"
                :class="{'is-invalid': errors[0]}"
              >
                <option
                  v-for="(data, index) in dropdownClassification"
                  :key="index"
                  :value="data.id"
                >
                  {{ data.name }}
                </option>
              </select>

              <select
                v-else-if="
                  value.type === 'dropdown' &&
                    value.field === 'country'"
                v-model.trim="dataSend[value.field]"
                class="custom-select"
                :class="{'is-invalid': errors[0]}"
              >
                <option
                  v-for="(data, index) in dropdownCountry"
                  :key="index"
                  :value="data.id"
                >
                  {{ data.name }}
                </option>
              </select>

              <select
                v-else-if="
                  value.type === 'dropdown' &&
                    value.field === 'fuel_type'"
                v-model.trim="dataSend[value.field]"
                class="custom-select"
                :class="{'is-invalid': errors[0]}"
              >
                <option
                  v-for="(data, index) in dropdownFuelType"
                  :key="index"
                  :value="data.id"
                >
                  {{ data.name }}
                </option>
              </select>

              <div class="input-group-append">
                <span
                  v-show="value.field === 'size_loa' ||
                    value.field === 'size_lbp' ||
                    value.field === 'size_width' ||
                    value.field === 'size_depth'"
                  class="input-group-text"
                  title="Satuan dalam ukuran berat"
                >
                  Meter
                </span>
                <span
                  v-show="value.field === 'gross_tonnage' ||
                    value.field === 'net_tonnage' ||
                    value.field === 'deadweight' ||
                    value.field === 'load_capacity'"
                  class="input-group-text"
                  title="Satuan dalam ukuran berat"
                >
                  MT
                </span>
                <span
                  v-show="value.field === 'horsepower'"
                  class="input-group-text"
                  title="Satuan dalam ukuran horsepower"
                >
                  HP
                </span>
              </div>

              <div class="invalid-feedback">
                <!-- Wajib di isi. -->
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatValidation from '../mixins/formatValidation';
export default {
  mixins: [formatValidation],
  props: {
    data: {
      type: Object,
      default: Object
    },
    type: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      dataSend: [],
      dropdownFuelType: [
        {
          id: 1,
          name: 'Solar'
        }
      ],
      dropdownQtyType: [
        {
          id: 1,
          name: 'MT'
        }
      ],
    };
  },
  computed: {
    company () {
      let data = JSON.parse(localStorage.getItem('userData'))
      return data.company.id
    },
    dropdownCountry () {
      return this.$store.state.moduleCountry.dataDropdown
    },
    dropdownClassification () {
      return this.$store.state.moduleClassifications.dataDropdown
    },
    dropdownBy () {
      return this.$store.state.moduleVessel.buildYear
    }
    // dropdownBy: [],
    // dropdownCountry: [],
    // dropdownClassification: [],
  },
  watch: {
    data() {
      this.createForm()
    }
  },

  created() {
    this.loadDropdown()
  },
  methods: {
    createForm () {
      const dt = {};
      for (const [, value] of Object.entries(this.data)) {
        value.forEach((element) => {
          if(element.field === 'load_capacity' && element.status === '0') dt[element.field] = element.value
          else dt[element.field] = ""
        })
      }
      dt['load_capacity_type'] = 1
      dt['fuel_type'] = 1
      dt['company'] = this.company
      // this.data.forEach((item, key) => {
      //   key.forEach((element) => {
      //     dt[element.field] = "";
      //   });
      // });
      this.dataSend = dt;
      this.$emit("values", this.dataSend);
    },
    showGroup(data) {
      return data.find((el) => el.status === 1);
    },
    loadDropdown () {
      this.$store.dispatch('moduleCountry/LOAD_DROPDOWN')
      this.$store.dispatch('moduleClassifications/LOAD_DROPDOWN')
      this.$store.commit('moduleVessel/COUNT_BUILD_YEAR')
    },
  },
};
</script>

<style lang="scss" scoped>
  input {
    border: 1px solid silver;
    border-radius: 4px;
    background: white;
    padding: 5px 10px;
  }

  p {
    margin-bottom: 0;
  }

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    width: 500px;
    margin: auto auto;

    li {
      list-style: none;

      input[type="text"],
      select,
      button {
        height: 30px;
        width: 100%;
        margin-top: 15px;
      }
    }
  }
</style>
