<template>
  <div>
    <b-modal
      id="modal-center"
      size="lg"
      v-model="showModal"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      centered
      scrollable
      :title="title"
    >
      <b-overlay
        rounded="md"
        spinner-type="grow"
        spinner-small
        variant="white"
        opacity="1"
      >
        <div class="row">
          <div class="col-6">
            Filter By Date:
            <br/>
            <date-range-picker
              ref="picker"
              v-model="dateRange"
              :autoApply="true"
              :minDate="Number.isInteger(minDate) ? null : minDate"
              :maxDate="maxDate"
              :singleDatePicker="'range'"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
              @update="updateDateRange"
            >
              <template 
                #input="picker"
                style="min-width: 350px;"
              >
                {{ formatDate(picker.startDate) }} -
                {{ formatDate(picker.endDate) }}
              </template>
            </date-range-picker>
          </div>
          <div class="col-6">
            <button 
              class="btn btn-primary btn-sm mb-3 float-right"
              @click="exportToCSV()"
            >
              <i class="fa fa-download"></i>&nbsp;&nbsp;Export to CSV
            </button>
            <button 
              class="btn btn-primary btn-sm mb-3 float-right"
              @click="viewOnMap()"
            >
              <i class="fa fa-map"></i>&nbsp;&nbsp;View on Map
            </button>
          </div>
        </div>
        <table 
          class="table table-striped small"
        >
          <thead>
            <tr>
              <th>Provider</th>
              <th>Speed / Course</th>
              <th>Wind Speed</th>
              <th>Wind Direction</th>
              <th>Wave Height</th>
              <th>Wave Direction</th>
              <th>Tides</th>
              <th>Latitude / Longitude</th>
              <th>Last Update Position</th>
              <th>Proximity</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(position, index) in historiesToShow"
              :key="index"
            >
              <td>{{ vessel.provider === 'Raztel' ? 'VTS' : 'AIS' }}</td>
              <td>{{ position.speed }} kn / {{ position.course }}</td>
              <td>{{ position.windspeedKmph }} km/h</td>
              <td>{{ position.winddirDegree }}&#176;</td>
              <td>{{ position.swellHeight_m }} m</td>
              <td>{{ position.swellDir }}&#176;</td>
              <td>{{ position.tides }} m</td>
              <td>{{ position.latitude }}&#176; / {{ position.longitude }}&#176;</td>
              <td>{{ formattedDate(position.datetime) }}</td>
              <td>{{ position.address }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td 
                colspan="9" 
                class="text-center"
              >
                <div 
                  v-show="loading" 
                  class="text-center"
                >
                  Loading History...
                </div>
                <button
                  v-show="!loading && loadMoreAvailable" 
                  class="btn btn-primary btn-sm"
                  @click="loadMore"
                >
                  Load More
                </button>
                <div
                  v-show="!loading && !loadMoreAvailable"
                  class="text-muted text-center no-data-history "
                >
                  No more data..
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import API from '../../plugins/axios'
import formatDate from '../../mixins/formatDate'
import formatChar from '../../mixins/formatChar'
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'

export default {
  name: 'PositionHistory',
  components: {
    DateRangePicker
  },
  mixins: [ formatDate, formatChar ],
  data () {
    return {
      dateRange : {
        startDate : new Date().setDate(new Date().getDate() - 6),
        endDate : new Date()
      },
      showModal: false,
      lastPositionId: null,
      page : 1,
      perPage : 5,
      loading: true,
      title: 'Vessel History',
      loadMoreAvailable: false,
      vessel : {},
      histories : [],
      historiesToShow : [],
      maxDate: new Date(),
      minDate: new Date().setDate(new Date().getDate() - (6 * 30))
    }
  },
  methods: {
    resetFilter(){
      this.histories = []
      this.maxDate = new Date(),
      this.minDate = new Date().setDate(new Date().getDate() - (6 * 30))
      this.dateRange = {
        startDate : new Date().setDate(new Date().getDate() - 6),
        endDate : new Date()
      }
      this.loadMoreAvailable = false
      this.lastPositionId = null
      this.page = 1
    },
    formattedDate (value) {
      if (!value) return

      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    },
    setTitle(){
      let title = this.vessel.name + ' History '
      title += `${moment(this.dateRange.startDate).format('DD/MM/YYYY')} to ${moment(this.dateRange.endDate).format('DD/MM/YYYY')}`
      this.title = title;
    },
    show(data) {
      this.showModal = true
      this.vessel = data
      this.page = 1
      
      this.resetFilter()

      this.setTitle()

      this.getHistory();
    },
    getHistory(){
      this.loading = true

      let endPoint = `/master/vessel/history?id=${this.vessel.id}`
      if(this.lastPositionId != null){
        endPoint += `&last_position_id=${this.lastPositionId}`
      }

      //append date range
      endPoint += `&start_date=${moment(this.dateRange.startDate).format('DD-MM-YYYY')}&end_date=${moment(this.dateRange.endDate).format('DD-MM-YYYY')}`

      API.get(endPoint)
      .then(response => {
        this.loading = false
        this.histories = [ ...this.histories , ...response.data.data.history]

        //only get first page
        this.historiesToShow = this.histories.slice(0, this.perPage)

        //set min date
        if(response.data.data.last_position_date != null){
          this.minDate = new Date(response.data.data.last_position_date)
        }

        //set load more available
        if(response.data.data.history.length > 0){
          this.loadMoreAvailable = true
        }else{
          this.loadMoreAvailable = false
        }
      })
    },
    loadMore(){
      this.page = this.page + 1

      //get from histories and set to historiesToShow
      this.historiesToShow = this.histories.slice(0, this.page * this.perPage)

      //get if any more
      if(this.historiesToShow.length < this.histories.length){
        this.loadMoreAvailable = true
      }else{
        this.loadMoreAvailable = false
      }
    },
    exportToCSV(){
      let url = process.env.VUE_APP_API_URL+'/master/vessel/export-history?id='+this.vessel.id

      //append date range
      url += `&start_date=${moment(this.dateRange.startDate).format('DD-MM-YYYY')}&end_date=${moment(this.dateRange.endDate).format('DD-MM-YYYY')}`

      window.open(url)
    },
    formatDate(date){
      return moment(date).format('DD/MM/YYYY')
    },
    updateDateRange(data){
      this.histories = []
      this.lastPositionId = null
      this.page = 1

      this.setTitle()

      this.getHistory();
    },
    viewOnMap(){
      this.histories.map(history => {
        history.name = this.vessel.name
        history.vessel_type = this.vessel.vessel_type
        history.load_capacity = this.vessel.load_capacity
        history.provider = this.vessel.provider
      })

      this.$store.commit('SET_TRAIL_HISTORY', this.histories)
      this.$store.commit('SET_TRAIL_HISTORY_LABEL', this.title)

      this.showModal = false
    }
  }
}
</script>

<style scoped>
  .form-conship {
    min-width: 20rem !important;
    left: -100px !important;
    top: -50px !important;
  }

  .img-size {
    width: 35px;
    height: 35px;
  }

  .text {
    font-size: 0.9rem;
  }
</style>
