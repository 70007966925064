<template>
  <div id="resetpassword">
    <div class="form-signin">
      <div class="text-center mb-4">
        <h1 class="h3 mb-3 font-weight-thin">
          Resend Email Verification
        </h1>
        <p><span class="text-muted">Ship Owner</span></p>
      </div>
      <div
        v-if="alerting"
        class="row"
      >
        <div class="col-md-12">
          <div
            class="alert alert-info"
            role="alert"
          >
            Verifikasi email telah dikirim ke <strong>{{ alerting }}</strong>, mohon di cek email anda.
          </div>
        </div>
      </div>
      <div class="form-label-group">
        <input
          id="inputEmail"
          v-model="user_email"
          type="email"
          class="form-control"
          placeholder=""
          required
          autofocus
          disabled
          @keyup.enter="resend"
        >
        <label for="inputEmail">Email address</label>
      </div>


      <button
        v-if="btn_resend"
        class="btn btn-primary btn-block mx-0"
        @click="resend"
      >
        Resend
      </button>
      <button
        v-else
        class="btn btn-primary btn-block mx-0"
        disabled
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </button>
      <p class="mt-4 text-center">
        Kembali ke halaman
        <router-link :to="linkLogin">
          Log In
        </router-link>
      </p>
      <p class="mt-2 mb-3 text-muted text-center">
        &copy; 2020
      </p>
    </div>
  </div>
</template>

<script>
import API from "../plugins/axios";
import {SwalFailed, SwalWarning, SwalSuccess} from "../plugins/sweetalert2";

export default {

  data() {
    return {
      user_email: "",
      btn_resend: true,
      alerting: null,
      linkLogin: "/",

      placeholder: {
        email: "example@zonasea.com",
      },
    };
  },
  created () {
      this.user_email = this.$route.params.email
      if (!this.user_email) {
        this.$router.push({
            name: 'login'
        })
      }
  },
  methods: {
    resend() {
      this.btn_resend = false
      let endpoint = `registration/resend`;
      let values = {
        user: this.user_email,
      };
      API.patch(endpoint, values)
        .then((res) => {
          this.btn_resend = true
          const meta = res.data;
          if (meta.code === 200) {
            SwalSuccess ('Silahkan cek email anda!', () => this.alerting = this.user_email )
          }
        })
        .catch((err) => {
          this.btn_resend = true
          if (typeof err.response === "undefined") {
            SwalFailed (err.response.statusText)
          } else {
            SwalWarning (err.response.data.message)
          }
        })
    },
  },
};
</script>

<style></style>
