<template>
  <div class="booking-list">
    <!-- Tab Histroy and On Going -->
    <div class="row no-gutters">
      <router-link
        v-for="(menu, index) in menus"
        :key="index"
        v-slot="{ navigate, isActive }"
        :to="menu.url"
      >
        <div
          class="col-6 sub-tab c-pointer"
          :class="{ active: isActive }"
          @click="navigate"
        >
          <label class="text-tab c-pointer">{{ menu.title }}</label>
        </div>
      </router-link>
    </div>
    <!-- End Tag histroy and on goin -->
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        { url: '/app/booking-list/ongoing', title: 'On Going' },
        { url: '/app/booking-list/history', title: 'History' },
      ]
    }
  },
}
</script>

<style>

</style>