<template>
  <div class="container pt-5 mb-5">
    <loading-dialog :show="isLoading" />

    <div class="py-5 text-center">
      <template v-if="!isLoading">
        <h2>{{ selectedVessel.name }} : {{ engine_label }}</h2>
        <router-link
          :to="'/vessel-engines/list/' + selectedVessel.id"
          class="text-decoration-none float-left"
        >
          <i class="fa fa-arrow-left mr-2" />Back to other Engines
        </router-link>
      </template>
    </div>
    
    <div class="border p-3">
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Vessel Name</strong>
            <div class="text-muted">
              {{ selectedVessel.name }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Vessel Type</strong>
            <div class="text-muted">
              {{ selectedVessel.vessel_type }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Country</strong>
            <div class="text-muted">
              {{ selectedVessel.country }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <div class="mb-3">
            <strong>Load Capacity</strong>
            <div class="text-muted">
              {{ selectedVessel.load_capacity }} MT
            </div>
          </div>
        </div>
      </div>
    </div>

    <form @submit.prevent="saveEngine" class="mt-3">
      <div>
        <label>Engine Label</label>
        <select class="form-control" v-model="engineSelectedOption">
          <option v-for="(option, index) in defaultEngineOptions" :key="index">{{ option }}</option>
        </select>
      </div>
      <div class="mt-3" v-if="engineSelectedOption === 'Others'">
        <label>Other Engine Label</label>
        <input :disabled="submitLoading" type="text" v-model="engine_label" class="form-control" maxlength="150" placeholder="Input Engine's Label..." required/>
      </div>
      <div class="mt-3">
        <label>Manufacturer</label>
        <input :disabled="submitLoading" type="text" v-model="manufacturer" class="form-control" maxlength="150" placeholder="Input Manufacturer..." required/>
      </div>
      <div class="mt-3">
        <label>Model Number</label>
        <input :disabled="submitLoading" type="text" v-model="model_number" class="form-control" maxlength="150" placeholder="Input Model Number..." required/>
      </div>
        <div class="mt-3">
        <label>Engine Year</label>
        <input :disabled="submitLoading" type="text" v-model="engine_year" class="form-control" maxlength="150" placeholder="Input Engine Year..." required/>
      </div>
      <div class="mt-3">
        <button :disabled="submitLoading" type="submit" class="btn btn-primary ml-0">
          <template v-if="submitLoading">
            <i class="fa fa-spinner fa-spin"></i>
          </template>
          <template v-else>
            Update
          </template>
        </button>
        <button type="button" class="btn btn-danger" @click="deleteEngine()">Delete</button>
        <button type="button" class="btn btn-success" @click="viewEngineReport()">View Report</button>
      </div>
    </form>
    
  </div>
</template>
<script>
import { mapState } from 'vuex'
import LoadingDialog from "@/components/dialogs/LoadingDialog"
import { SwalSuccess, SwalWarning, Swal, SwalConfirm } from "../../../plugins/sweetalert2";

import formatDate from '../../../mixins/formatDate'
import formatValidation from '../../../mixins/formatValidation'
import API from "../../../plugins/axios";
import formatChar from "@/mixins/formatChar";

export default {
  components: {
    'loading-dialog': LoadingDialog,
  },
  mixins: [formatDate, formatValidation, formatChar],
  data () {
    return {
      showModal: false,
      showEngines : true,
      showCheckupRequests : false,

      engines : [],
      checkup_requests : [],

      defaultEngineOptions : [
        "Main Engine",
        "Aux Engine",
        "Others"
      ],
      engineSelectedOption : "Main Engine",

      id: null,
      engine_label: 'Main Engine',
      manufacturer: '',
      model_number: '',
      engine_year: '',
      submitLoading : false,
      listLoading : false,
      selectedEngines : null,
    }
  },
  computed: {
    ...mapState('moduleVessel', [
      'selectedVessel',
      'selectedVesselType',
      'userCreate',
      'license',
      'buildYear',
      'isLoading',
    ]),
  },
  watch: {
    selectedVessel () {
      if(this.selectedVessel.id !== "" && this.selectedVessel.id !== null) {
        this.getEngine()
      }
    },
    engineSelectedOption(){
      if(this.engineSelectedOption === 'Others') {
        if(this.id === null){
          this.engine_label = ''
        }
      }else{
        this.engine_label = this.engineSelectedOption
      }
    }
  },
  created () {
    this.loadSelectedVessel()
  },
  methods: {
    goToEngineDetail(vesselEngineId){
      this.$router.push({
        name: 'engine-detail',
        params: {
          id: vesselEngineId
        }
      })
    },
    renderStatus(status){
      if(status == 'Normal'){
        return '<span class="text-success">'+status+'</span>'
      }else if(status == 'Caution'){
        return '<span class="text-warning">'+status+'</span>'
      }else if(status == 'Alert'){
        return '<span class="text-danger">'+status+'</span>'
      }else{
        return status
      }
    },
    getEngine(){
      const vm = this

      const params = {
        'start': 0,
        'length': 999,

        "columns[0][data]": "0",
        "columns[0][name]": "id",
        "columns[0][searchable]": "true",
        "columns[0][orderable]": "true",
        "columns[0][search][value]": this.$route.params.vesselEngineId,
        "columns[0][search][regex]": "false",
      }

      API.get("/master/vessel/vessel-engine/read", {
        params: params
      })
      .then((res) => {
        const body = res.data;
        vm.listLoading = false

        if(res.status === 200){
          const engine_data = body.data[0]
          vm.id = engine_data.id
          vm.engine_label = engine_data.engine_label
          vm.manufacturer = engine_data.manufacturer
          vm.model_number = engine_data.model_number
          vm.engine_year = engine_data.engine_year

          if(engine_data.engine_label === 'Main Engine' || engine_data.engine_label === 'Aux Engine'){
            vm.engineSelectedOption = engine_data.engine_label
          }else{
            vm.engineSelectedOption = 'Others'
          }
        } else {
          SwalWarning(body.message)
        }
      })
      .catch((error) => {
        vm.listLoading = false

        const data = error.response
        SwalWarning (data.data.message)
      });
    },
    loadSelectedVessel () {
      const payload = this.$route.params.vesselId
      this.$store.dispatch('moduleVessel/LOAD_SELECTED', payload)
    },
    saveEngine(){
      if(this.id === null){
        this.addNewEngine()
      } else {
        this.editEngine()
      }
    },
    editEngine(){
      const vm = this

      vm.submitLoading = true

      API.patch("/master/vessel/vessel-engine/update", {
          id: vm.id,
          vessel_id: vm.selectedVessel.id,
          engine_label: vm.engine_label,
          manufacturer: vm.manufacturer,
          model_number: vm.model_number,
          engine_year: vm.engine_year,
        }
      )
      .then((res) => {
        const body = res.data;
        vm.submitLoading = false

        if(res.status === 200){
          SwalSuccess(body.message)
        } else {
          SwalWarning(body.message)
        }
      })
      .catch((error) => {
        vm.submitLoading = false

        const data = error.response
        SwalWarning (data.data.message)
      });
    },
    deleteEngine(){
      const vm = this

      //show confirmation
      SwalConfirm ('Delete '+vm.engine_label+'?', () => {
        API.delete("/master/vessel/vessel-engine/delete?id=" + vm.id)
          .then((res) => {
            const body = res.data;

            if(res.status === 200){
              SwalSuccess(body.message)

              //back to previous page
              vm.$router.push({
                name: 'list-vessel-engines',
                params: {
                  id: vm.selectedVessel.id
                }
              })
            }
          }).catch((error) => {
            const data = error.response
            SwalWarning (data.data.message)
          });
      })
    },
    viewEngineReport(){
      //go to route
      this.$router.push({
        name: "report-vessel-engine",
        params: {
          vesselId: this.selectedVessel.id,
          vesselEngineId: this.id
        },
      });
    }
  }
}
</script>

<style scoped>
.table-vessel-engine tr{
  cursor: pointer;
}
</style>
