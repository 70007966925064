<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="showModal"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      centered
      :title="title"
      @hide="onCancel"
    >
      <b-overlay
        :show="isLoading"
        rounded="sm"
        spinner-type="grow"
        spinner-small
        variant="white"
        opacity="1"
      >
        <div class="input-group mb-3">
          <div class="custom-file">
            <input
              id="files"
              ref="files"
              type="file"
              class="custom-file-input"
              multiple
              @change="fileUploadHandler()"
            >
            <label
              v-if="!files.length"
              class="custom-file-label"
            >Choose file or you can drop file here</label>
            <label
              v-else
              class="custom-file-label"
            >{{ files.length }} Files selected</label>
          </div>
        </div>
        <ul class="list-group my-2">
          <li
            v-for="(item, index) in files"
            :key="index"
            class="list-group-item justify-content-between lh-condensed"
          >
            <div class="d-flex align-items-center w-100">
              <img
                v-if="checkJpg(item.type)"
                src="../../assets/icons/jpg.png"
                salt="JPG File"
              >
              <img
                v-else-if="checkPng(item.type)"
                src="../../assets/icons/png.png"
                alt="PNG File"
              >
              <img
                v-else-if="checkPdf(item.type)"
                src="../../assets/icons/pdf.png"
                alt="PDF File"
              >
              <div class="mx-2">
                <p class="my-0">
                  {{ splitStrings(item.name) }}
                </p>
                <small class="text-muted">{{ convertFileSize(item.size) }}</small>
              </div>
              <i
                v-if="!particularsOption.uploadPrecentage"
                class="fa fa-times btn-link text-decoration-none ml-auto c-pointer"
                @click="removeFile(index, item.size)"
              />
            </div>
          </li>
        </ul>
        <div v-if="!particularsOption.uploadPrecentage">
          <div class="d-flex justify-content-end">
            <b-button
              block
              type="submit"
              variant="primary"
              @click="onSubmit"
            >
              Upload
            </b-button>
          </div>
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center"
        >
          {{ particularsOption.uploadPrecentage }}%
          <div class="progress w-100">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              :aria-valuenow="particularsOption.uploadPrecentage"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="{ width: particularsOption.uploadPrecentage + '%' }"
            />
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import formatFile from '@/mixins/formatFile'
import { SwalWarning, SwalConfirm } from "../../plugins/sweetalert2";
import { mapState } from 'vuex'
import formatValidation from "@/mixins/formatValidation";
import formatChar from '../../mixins/formatChar';
export default {
  name: "EditParticulars",
  mixins: [formatFile, formatValidation, formatChar],
  props: {
    show: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: Array
    }
  },
  data () {
    return {
      showModal: false,
      isLoading: false,
      title: 'Edit Particulars',
      text: 'You can update data here',

      files: [],
      sizeFiles: 0,
      maxFiles: 10,
      maxSizeFiles: 2097152,
      maxTotalSizeFiles: 20971520,

    }
  },
  watch: {
    show() {
      this.showModal = this.show
      console.log(this.showModal, this.show)
    }
  },
  computed: {
    ...mapState('moduleVessel',[
      'particularsOption',
      'selectedVessel'
    ])
  },
  methods: {
    onCancel () {
      this.$emit('toggle')
    },

    fileUploadHandler () {
      let uploadedFiles = this.$refs.files.files
      const vm = this

      let tempSize = this.sizeFiles

      //Count total file size and last size of the file
      for (let i = 0; i < uploadedFiles.length; i++) {

        if (uploadedFiles[i].size <= this.maxSizeFiles) {
          // check the file type
          if (this.fileTypeValidation(uploadedFiles[i].type)) {
            tempSize = tempSize + uploadedFiles[i].size
          }
        }
      }

      if (uploadedFiles.length + this.files.length > this.maxFiles) {
        // Total files rejected if more than 10
        SwalWarning ({title: 'File Rejected!', text: 'File tidak boleh lebih dari 10'})
        this.$refs.files.value = '' // reset file input

      } else if (vm.sizeFiles > this.maxTotalSizeFiles) {
        // Total file size rejected if more than 20mb
        SwalWarning ({title: 'File Rejected!', text: 'Maksimal keseluruhan files maksimal \'20MB\''})
        this.$refs.files.value = '' // reset file input
      } else {
        let x = Boolean
        //Adds the uploaded file to the files array
        for (let i = 0; i < uploadedFiles.length; i++) {
          // check the file type
          if (this.fileTypeValidation(uploadedFiles[i].type)) {
            if (uploadedFiles[i].size <= this.maxSizeFiles) {
              x = true
            } else {
              SwalWarning ({title: 'File Rejected!', text: 'File size lebih dari \'2MB\''})
              this.$refs.files.value = '' // reset file input
              x = false
              break
            }
          } else {
            // Files rejected if the files formated not equal PNG/JPG/PDF
            SwalWarning ({title: 'File Rejected!', text: 'Semua file harus memiliki extensi \'PDF/JPG/PNG\''})
            this.$refs.files.value = '' // reset file input
            break
          }
        }
        if (x) {
          // finall
          vm.sizeFiles = tempSize
          for (let j = 0; j < uploadedFiles.length; j++) {
            this.files.push(uploadedFiles[j])
          }
        }
      }
    },

    onSubmit () {
      const vm = this
      if (this.files.length > 0) {
        SwalConfirm ('Anda yakin ingin menambahkan file baru ?', async () => {
          await this.$store.dispatch('moduleVessel/UPLOAD_PARTICULARS', vm.files)
          this.clearFiles()
          await this.$store.dispatch('moduleVessel/LOAD_SELECTED', vm.selectedVessel.id)
          this.$emit('toggle')
        } )
      } else {
        SwalWarning ('Mohon tambahkan file terlebih dahulu.')
      }
    },

    removeFile(key, size) {
      this.$refs.files.value = ''
      Array.prototype.slice.call(this.$refs.files, key, 1)
      this.files.splice(key, 1)
      this.sizeFiles = this.sizeFiles - size
    },

    clearFiles() {
      this.$refs.files.value = ''
      this.files = []
      this.sizeFiles = null
    }
  },
}
</script>

<style lang="scss" scoped>
  img {
    max-width: 50px;
    max-height: 50px;
  }
</style>
